import React from 'react';
import 'antd/dist/antd.min.css';
import { Table } from 'antd';

import UserCard from './UserCard';
import { getFormattedDate } from '../utils';

const columns = [
  {
    title: '아이디',
    dataIndex: 'name',
  },
  {
    title: '평가\n점수',
    dataIndex: 'ratingScore',
    sorter: {
      compare: (a, b) => a.ratingScore - b.ratingScore,
      multiple: 11,
    },
  },
  {
    title: '평가\n횟수',
    dataIndex: 'ratingCount',
    sorter: {
      compare: (a, b) => a.ratingCount - b.ratingCount,
      multiple: 10,
    },
  },
  {
    title: '팔로워',
    dataIndex: 'followerCount',
    sorter: {
      compare: (a, b) => a.followerCount - b.followerCount,
      multiple: 9,
    },
  },
  {
    title: '팔로잉',
    dataIndex: 'followingCount',
    sorter: {
      compare: (a, b) => a.followingCount - b.followingCount,
      multiple: 8,
    },
  },
  {
    title: '매출\n기여액',
    dataIndex: 'revenueAmount',
    sorter: {
      compare: (a, b) => a.revenueAmount - b.revenueAmount,
      multiple: 7,
    },
  },
  {
    title: '수익',
    dataIndex: 'profitAmount',
    sorter: {
      compare: (a, b) => a.profitAmount - b.profitAmount,
      multiple: 6,
    },
  },
  {
    title: '잔여금',
    dataIndex: 'balance',
    sorter: {
      compare: (a, b) => a.balance - b.balance,
      multiple: 5,
    },
  },
  {
    title: '업로드\n리뷰',
    dataIndex: 'videoCount',
    sorter: {
      compare: (a, b) => a.videoCount - b.videoCount,
      multiple: 4,
    },
  },
  {
    title: '등록제품',
    dataIndex: 'productCount',
    sorter: {
      compare: (a, b) => a.productCount - b.productCount,
      multiple: 3,
    },
  },
  {
    title: '셀러 여부',
    dataIndex: '',
    render: (record) => (
      <span>
        {record.isSeller || record.sellerStatus === 'APPROVAL_REQUEST' || record.sellerStatus === 'APPROVED'
          ? 'O'
          : 'X'}
      </span>
    ),
  },
  {
    title: '가입시점',
    dataIndex: 'createdAt',
    sorter: {
      compare: (a, b) => {
        const date1 = new Date(a.createdAt);
        const date2 = new Date(b.createdAt);

        return date1 - date2;
      },
      multiple: 1,
    },
    render: (record) => <span>{getFormattedDate(record)}</span>,
  },
  {
    title: '탈퇴여부',
    dataIndex: 'isDeleted',
    render: (record) => <span>{record === 1 ? 'O' : 'X'}</span>,
  },
];

function onChange(pagination, filters, sorter, extra) {
  // console.log('params', pagination, filters, sorter, extra);
}

function UserTable(props) {
  return (
    <Table
      columns={columns}
      dataSource={props.userInfoList}
      rowKey={(record) => record.id}
      expandable={{
        expandedRowRender: (record) => <UserCard userData={record} handleClickRefresh={props.handleClickRefresh} />,
        rowExpandable: (record) => true,
      }}
      onChange={onChange}
    />
  );
}

export default UserTable;
