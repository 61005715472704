import React, { useState, useEffect } from 'react';
import { Input, Radio, Select } from 'antd';
import RevenueTable from '../molecules/RevenueTable';
import AdminRepository from '../repositories/AdminRepository';
import RevenueDownloadButton from '../molecules/RevenueDownloadButton';

// TODO: 매출 리스트 조회 API 로직 보완, 20개 이상의 리스트가 보여지도록
// TODO: 매출 전체 리스트를 받아올 수 있도록 개선되면, api에 맞춰 수정할 것
// TODO: product, review, order id에 따른 검색 기능 추가할 것
// TODO: product, review, order 개별 요소를 모달로 간단히 확인할 수 있도록 기능 추가(옵션)
// TODO: isSeller를 props로 받아서 해당 파라미터에 따라 리뷰리워드조회, 매출조회 선택가능하도록 수정할 것
// TODO: Style 정리, 문자열 constant 정리

function ReviewerRevenueListView() {
  const [revenueListData, setRevenueListData] = useState([]);
  const [searchType, setSearchType] = useState('userId');
  const [isValid, setValid] = useState(true);
  const { Search } = Input;
  const { Option } = Select;

  const handleRadioChange = (e) => {
    setSearchType(e.target.value);
  };
  const handleValidSelectChange = async (value) => {
    setValid(value);
    const params = {};
    // TODO : 추가적으로 item을 부르도록 수정해야 함.
    // 임시 코드
    params.limit = 3000;
    params.isValid = value;
    params.isSeller = false;
    const resultRevenue = await AdminRepository.getRevenueList(params);
    const resultOrder = await AdminRepository.getOrderList(params);
    const { data } = resultRevenue;
    data.map((revenueItem) => {
      const result = revenueItem;
      const orders = resultOrder.data.filter(
        (orderItem) => orderItem._id === revenueItem.order?._id,
      );
      result.order = orders[0];
      return result;
    });
    setRevenueListData(data);
  };
  const handleSearch = async (value) => {
    const params = {};
    if (value !== '') {
      if (searchType === 'userId') params.userId = value;
      // 현재 아래 구현 X
      // else if(searchType==='productId') params['productId'] = value;
      // else if(searchType==='reviewId') params['videoId'] = value;
      // else if(searchType==='orderId') params['orderId'] = value;
    }

    // TODO : 추가적으로 item을 부르도록 수정해야 함.
    // 임시 코드
    params.limit = 3000;
    params.isValid = isValid;
    params.isSeller = false;
    const resultRevenue = await AdminRepository.getRevenueList(params);
    const resultOrder = await AdminRepository.getOrderList(params);
    const { data } = resultRevenue;
    data.map((revenueItem) => {
      const result = revenueItem;
      const orders = resultOrder.data.filter(
        (orderItem) => orderItem._id === revenueItem.order?._id,
      );
      result.order = orders[0];
      return result;
    });
    setRevenueListData(data);
  };

  async function updateRevenueList(params) {
    const resultRevenue = await AdminRepository.getRevenueList(params);
    const resultOrder = await AdminRepository.getOrderList(params);
    const { data } = resultRevenue;
    data.map((revenueItem) => {
      const result = revenueItem;
      const orders = resultOrder.data.filter(
        (orderItem) => orderItem._id === revenueItem.order?._id,
      );
      result.order = orders[0];
      return resultRevenue;
    });
    // shipmentCompletedAt로 필터링 필요
    setRevenueListData(data);
  }

  useEffect(() => {
    const params = { limit: 3000, isValid, isSeller: false };
    updateRevenueList(params);
  }, []);

  return (
    <div>
      <div>
        <Search
          placeholder="input search text"
          allowClear
          onSearch={handleSearch}
          style={{ width: '40%', float: 'left' }}
        />
        <Radio.Group
          onChange={handleRadioChange}
          value={searchType}
          style={{ float: 'left', margin: '0px 10px' }}
        >
          <Radio value="userId">contributorId</Radio>
          <Radio disabled value="productId">
            productId
          </Radio>
          <Radio disabled value="reviewId">
            reviewId
          </Radio>
          <Radio disabled value="orderId">
            orderId
          </Radio>
        </Radio.Group>
        <Select
          defaultValue={isValid}
          style={{ width: '15%', float: 'right' }}
          onChange={handleValidSelectChange}
        >
          <Option value>확정</Option>
          <Option value={false}>미확정</Option>
        </Select>
        <RevenueDownloadButton
          revenueList={revenueListData}
          style={{ width: '15%', float: 'right' }}
        />
      </div>
      <RevenueTable revenueList={revenueListData} />
    </div>
  );
}

export default ReviewerRevenueListView;
