import { useEffect, useState } from 'react';
import removeSubImg from '../assets/icHeaderSearchCancle16W.png';
import removeImg from '../assets/icHeaderSearchDelete12W.png';

export default function InputFiles({
  label = '업로드',
  inputId,
  placeholder,
  value = '',
  setValue,
  required = false,
  isValid,
  setIsValid = () => {},
  isEdit = false,
  setImageCount,
}) {
  const [validText, setValidText] = useState('');
  const [previews, setPreviews] = useState([]);

  useEffect(() => {
    if (required && !value) {
      return setValidText('필수 첨부값입니다.');
    }

    setValidText('');
  }, [required, value]);

  useEffect(() => {
    // console.log(value);
  }, [value]);

  const setImageFromFile = async (file) => {
    const base64Image = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });

    return base64Image;
  };

  const removeImage = (idx) => {
    const tempImages = [...value];
    tempImages.splice(idx, 1);

    const tempPreviews = [...previews];
    tempPreviews.splice(idx, 1);

    setValue(tempImages);
    setImageCount(tempImages.length);
    setPreviews(tempPreviews);
  };

  const removeUploadedImage = (idx) => {
    const tempImages = [...value];
    tempImages[idx].change = 2;

    setValue(tempImages);
  };

  return (
    <>
      <div className="w-full flex justify-between items-center mb-2">
        <label htmlFor="_" className="w-1/5 text-explorer font-semibold text-base">
          {label} <span className="text-giver align-middle">{required ? '*' : ''}</span>
        </label>
        <label
          htmlFor={inputId}
          className="w-3/5 text-center font-bold text-giver rounded-lg border border-pioneer cursor-pointer"
        >
          {placeholder}
        </label>
        <input
          multiple
          id={inputId}
          type="file"
          accept="image/*"
          className="hidden"
          onChange={async (e) => {
            if (inputId === 'productImages') {
              if (e.target.files.length > 10) {
                return alert('상품 대표 이미지는 10장을 초과할 수 없습니다.');
              }
            } else if (inputId === 'productDescribeImages') {
              if (e.target.files.length > 15) {
                return alert('상품 상세 이미지는 15장을 초과할 수 없습니다.');
              }
            }

            if (e.target.files.length > 0) {
              const images = [];
              for (let i = 0; i < e.target.files.length; i++) {
                const image = await setImageFromFile(e.target.files[i]);

                if (isEdit) {
                  e.target.files[i].change = 1;
                }
                images.push(image);
              }

              setPreviews(images);
              setImageCount(images.length);
              setValue([...value, ...e.target.files]);
            }

            setIsValid(true);
          }}
        />
        <div className="ml-4 w-1/5 text-red-400">{isValid ? '' : validText}</div>
      </div>
      <div className="flex flex-row">
        {previews.length > 0
          ? previews.map((preview, idx) => (
              <div key={`${preview.slice(20, 40)}_${idx}`}>
                <button className="absolute" onClick={() => removeImage(idx)}>
                  <img src={removeImg} alt="" />
                </button>
                <img className="w-28 h-28 mr-2 rounded-lg" src={preview} alt="" />
              </div>
            ))
          : null}
        {isEdit && value.length
          ? value.map((attachment, idx) => {
              if (attachment.url && attachment?.change !== 2) {
                return (
                  <div key={`${attachment.url.slice(0, 20)}_${idx}`}>
                    <button className="absolute" onClick={() => removeUploadedImage(idx)}>
                      <img src={removeImg} alt="" />
                    </button>
                    <img className="w-28 h-28 mr-2 rounded-lg" src={attachment.url} alt="" />
                  </div>
                );
              }
            })
          : null}
      </div>
    </>
  );
}
