import { useEffect, useState } from 'react';
import { ORDER_STATUS_CODE } from '../constant';
import AdminRepository from '../repositories/AdminRepository';

function OrderAccept({ order, setShowModal, updateOrderList }) {
  const [reasonText, setReasonText] = useState();

  useEffect(() => {}, []);

  const handleClickAccept = async (orderId) => {
    if (!window.confirm('주문을 수락하고 제품을 준비합니다.')) {
      return;
    }

    const result = await AdminRepository.actionOrder(orderId, ORDER_STATUS_CODE.PREPARING);

    setShowModal(false);
    updateOrderList({ limit: 3000 });
  };

  const handleClickReject = async (orderId) => {
    if (!window.confirm('주문을 거절하시겠습니까?')) {
      return;
    }

    const result = await AdminRepository.actionOrder(
      orderId,
      ORDER_STATUS_CODE.SELLER_CANCEL_REQUEST,
      undefined,
      undefined,
      reasonText
    );

    setShowModal(false);
    updateOrderList({ limit: 3000 });
  };

  return (
    <div className="flex flex-col items-center ">
      <div className="flex flex-col text-pioneer">
        <span>
          주문상품:
          {` ${order.cartItems[0]?.product.title} ${
            order.cartItems.length - 1 > 0 ? `외 ${order.cartItems.length - 1}` : ''
          }`}
        </span>
        <span>가격: {order.totalPrice}원</span>
        <span>상품 갯수: {order.cartItems[0]?.number}</span>
      </div>
      <div className="w-3/5 flex flex-col">
        <button
          className="text-giver font-bold text-lg px-6 py-1 rounded-md border-0 bg-operator mt-6 mb-10"
          onClick={() => handleClickAccept(order.orderId)}
        >
          주문수락
        </button>
      </div>
      <div className="w-3/5 flex flex-col">
        <textarea
          rows={5}
          className="rounded-lg py-1 px-3 resize-none"
          placeholder="거절 사유를 입력해주세요, 구매자에게 전송됩니다."
          value={reasonText}
          onChange={(e) => {
            setReasonText(e.target.value);
          }}
        />
        <button
          className="text-giver font-bold text-lg px-6 py-1 rounded-md border-0 bg-operator mt-4"
          onClick={() => handleClickReject(order.orderId)}
        >
          주문거절
        </button>
      </div>
    </div>
  );
}

export default OrderAccept;
