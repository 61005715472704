import { useEffect, useState } from 'react';
import plusImg from '../assets/icCommonAdd20.png';
import removeImg from '../assets/icHeaderSearchDelete12W.png';

function AddtionalProduct({
  idx, handleClickRemove, title, price, productInputs, setProductInputs,
}) {
  return (
    <div className="bg-dark-grey-1 w-full pb-4 px-4 rounded-lg flex flex-col items-center mb-4">
      <div className="w-full h-5 flex justify-end items-end">
        <button onClick={() => handleClickRemove(idx)}>
          <img src={removeImg} alt="" />
        </button>
      </div>
      <div className="w-full flex flex-row mb-4 justify-center">
        <span className="w-1/6 text-striver">상품 이름</span>
        <input
          type="text"
          value={title}
          onChange={({ target: { value } }) => {
            setProductInputs({
              ...productInputs,
              [idx]: { ...productInputs[idx], name: value },
            });
          }}
          placeholder="상품 이름 입력"
          className="rounded-lg px-2 w-3/5"
        />
      </div>
      <div className="w-full flex flex-row justify-center">
        <span className="w-1/6 text-striver">추가 가격</span>
        <input
          type="number"
          value={price}
          onChange={({ target: { value } }) => {
            setProductInputs({
              ...productInputs,
              [idx]: { ...productInputs[idx], addition: value },
            });
          }}
          placeholder="0"
          className="rounded-lg px-2 w-3/5"
        />
      </div>
    </div>
  );
}

export default function AdditionalProducts({ productInputs, setProductInputs }) {
  const [count, setCount] = useState(0);

  const handleClickAdd = () => {
    setCount(count + 1);
    setProductInputs({
      ...productInputs,
      [count + 1]: { count: count + 1, name: '', addition: 0 },
    });
  };

  const handleClickRemove = (idx) => {
    const tempInputs = { ...productInputs };
    delete tempInputs[idx];

    setProductInputs(tempInputs);
  };

  // useEffect(() => {
  //   setCount(0);
  //   setProductInputs({});
  // }, []);

  return (
    <>
      <div className="flex flex-row justify-between mb-2">
        <span className="text-striver font-semibold text-base">추가 상품</span>
        <div className="rounded-full bg-pioneer w-5 h-5 flex justify-center items-center">
          <button onClick={() => handleClickAdd()}>
            <img src={plusImg} alt="" />
          </button>
        </div>
      </div>
      {productInputs
        ? Object.keys(productInputs).map((key, idx) => (
          <AddtionalProduct
            key={idx}
            idx={productInputs[key].count}
            handleClickRemove={handleClickRemove}
            title={productInputs[key].name}
            price={productInputs[key].addition}
            productInputs={productInputs}
            setProductInputs={setProductInputs}
          />
        ))
        : null}
    </>
  );
}
