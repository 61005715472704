import { HashRouter } from 'react-router-dom';
import './App.css';
import Routes from './pages/Routes';

function App() {
  return (
    <HashRouter>
      <Routes />
      {/* <MainPage /> */}
    </HashRouter>
    // <MainPage />
  );
}

export default App;
