import { useEffect, useState } from 'react';
import { COURIER_LIST, ORDER_STATUS_CODE } from '../constant';
import AdminRepository from '../repositories/AdminRepository';
import InputSelect from './InputSelect';
import InputText from './InputText';

function OrderInvoiceUpdate({ order, setShowModal, updateOrderList }) {
  const [courier, setCourier] = useState('');
  const [courierNumber, setCourierNumber] = useState();
  const [customCourier, setCustomCourier] = useState('');

  useEffect(() => {
    setCourier(order.shipmentCourierCO);
    setCourierNumber(order.shipmentCourierNO);
  }, []);

  const handleClickUpdateCourier = async (orderId) => {
    if (!courier || !courierNumber) {
      alert('택배사, 송장번호가 입력되었는지 확인해주세요');
      return;
    }

    if (!window.confirm('송장번호를 수정합니다.')) {
      return;
    }

    const result = await AdminRepository.updateCourier(orderId, {
      company: courier === 'etc' ? customCourier : courier,
      invoice: courierNumber,
    });

    setShowModal(false);
    updateOrderList();
  };

  const handleClickOrderComplete = async (orderId) => {
    if (!window.confirm('배송 완료 처리합니다.')) {
      return;
    }

    const result = await AdminRepository.actionOrder(
      order.orderId,
      ORDER_STATUS_CODE.SHIPMENT_COMPLETED,
    );

    setShowModal(false);
    updateOrderList();
  };

  return (
    <div className="flex flex-col items-center justify-center h-full ">
      <div className="flex flex-col text-pioneer">
        <span>
          주문상품:
          {` ${order.cartItems[0]?.product.title} ${
            order.cartItems.length - 1 > 0
              ? `외 ${order.cartItems.length - 1}`
              : ''
          }`}
        </span>
        <span>가격: {order.totalPrice}원</span>
        <span>상품 갯수: {order.cartItems[0]?.number}</span>
      </div>
      <div className="w-3/5 flex flex-col mt-6">
        <InputSelect
          label="택배사 선택"
          inputId="courier"
          placeholder="택배사를 선택해주세요"
          required
          value={courier}
          setValue={setCourier}
          list={COURIER_LIST}
        />
        {courier === 'etc' ? (
          <InputText
            value={customCourier}
            setValue={setCustomCourier}
            required
            label="택배사"
            inputId="customCourier"
            placeholder="택배사 이름"
          />
        ) : null}
        <InputText
          value={courierNumber}
          setValue={setCourierNumber}
          required
          label="운송장 번호"
          inputId="courierNumber"
          placeholder="운송장번호 (- 제외)"
        />
        <button
          className="text-giver font-bold text-lg px-6 py-1 rounded-md border-0 bg-operator mt-6 mb-10"
          onClick={() => handleClickUpdateCourier(order.orderId)}
        >
          송장정보 수정
        </button>
      </div>
      <div className="w-3/5 flex flex-col">
        <button
          className="text-giver font-bold text-lg px-6 py-1 rounded-md border-0 bg-operator mt-4"
          onClick={() => handleClickOrderComplete(order.orderId)}
        >
          배송 완료
        </button>
      </div>
    </div>
  );
}

export default OrderInvoiceUpdate;
