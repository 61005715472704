import { ClipLoader } from 'react-spinners';

export default function Spinner() {
  return (
    <>
      <div
        style={{
          content: '',
          display: 'block',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.5)',
        }}
      />
      <div className="fixed z-50 overflow-visible m-auto top-0 left-0 bottom-0 right-0 w-10 h-10">
        <ClipLoader
          color="#3a3a3a"
          loading
          cssOverride={{}}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </>
  );
}
