import { useState } from 'react';

export default function InputTextarea({
  label,
  inputId,
  placeholder,
  value = '',
  setValue = '',
  required = false,
  isValid,
  setIsValid = () => {},
}) {
  const [validText, setValidText] = useState('');

  return (
    <div className="w-full flex justify-between items-center mb-2 mt-4">
      <label htmlFor={inputId} className="w-1/5 text-explorer font-semibold text-base">
        {label} <span className="text-giver align-middle">{required ? '*' : ''}</span>
      </label>
      <textarea
        id={inputId}
        type="text"
        placeholder={placeholder}
        rows={10}
        className="w-3/5 rounded-lg py-1 px-3 resize-none"
        value={value}
        onChange={async (e) => {
          setValue(e.target.value);

          if (e.target.value.trim()) {
            return setIsValid(true);
          }

          setIsValid(false);
        }}
      />
      <span className="ml-4 w-1/5 text-red-400">{validText}</span>
    </div>
  );
}
