import {
  FacebookAuthProvider,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { auth } from '../service/firebase';
import greydImg from '../assets/icGreydSplashLogo30.png';
import greydLogo from '../assets/icGreydSplashSymbol126.png';
import facebookLogo from '../assets/icLoginFacebook30@3x.png';
import googleLogo from '../assets/icLoginGoogle30@3x.png';
import kakaoLogo from '../assets/icLoginKakao30@3x.png';
import AdminRepository, { API_ROOT_URL } from '../repositories/AdminRepository';
import Enroll from './Enroll';

export default function Login({ setAuthorized, setUser }) {
  const [isEnrolled, setEnrolled] = useState(true);
  const [authType, setAuthType] = useState(null);
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {}, []);

  const googleLogin = async () => {
    const googleAuthProvider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, googleAuthProvider);
    const credential = GoogleAuthProvider.credentialFromResult(result);

    const { user } = result;

    const authData = {
      requesterToken: credential.idToken,
      idToken: credential.idToken,
      user: {
        email: user.email,
        name: user.displayName,
        photo: user.photoURL,
        id: user.providerData[0].uid,
      },
    };

    const token = CryptoJS.AES.encrypt(
      credential.idToken.toString(),
      process.env.REACT_APP_ARBAIM_SECRET_KEY,
    ).toString();

    const localToken = CryptoJS.AES.encrypt(
      credential.idToken.toString(),
      process.env.REACT_APP_ARBAIM_SECRET_KEY_2,
    ).toString();

    try {
      AdminRepository.setRequesterToken(token);
      const googleResult = await AdminRepository.request(
        `${API_ROOT_URL}/login`,
        'POST',
        {
          authType: 'google',
          authData: {
            ...authData,
            requesterToken: token,
            idToken: token,
          },
        },
      );

      if (googleResult && googleResult.statusCode === 1) {
        setAuthUser({ ...authData, localToken, token });
        setAuthType('google');
        setEnrolled(false);

        const emailArray = authData.user.email.split('@');
        const domain = emailArray[emailArray.length - 1];

        if (
          domain === 'arbaim.com' ||
          authData.user.email === 'arbaim.guest@gmail.com'
        ) {
          const profile = {
            phone: '',
            email: authData.user.email,
            name: `arbaim_${emailArray[0]}`.slice(0, 15),
            authType: 'google',
            authData,
            profilePicUri: authData.user.photo,
            countryCode: 'KR',
          };

          localStorage.setItem('id', googleResult.userId);
          localStorage.setItem('token', localToken);

          AdminRepository.setRequester(token, googleResult.userId);

          const _user = await AdminRepository.request(
            `${API_ROOT_URL}/users`,
            'POST',
            profile,
          );

          if (_user && _user.statusCode === 0) {
            setAuthorized(true);
            setUser(_user);
          } else {
            alert('구글 로그인 실패, 관리자에게 문의해주세요');
          }
        }
      } else {
        localStorage.setItem('id', googleResult.userId);
        localStorage.setItem('token', localToken);

        AdminRepository.setRequester(token, googleResult.userId);

        const _user = await AdminRepository.request(
          `${API_ROOT_URL}/users/${googleResult.userId}`,
          'GET',
        );

        if (_user && _user.statusCode === 0) {
          setAuthorized(true);
          setUser(_user);
        } else {
          alert('구글 로그인 실패, 관리자에게 문의해주세요');
        }
      }
    } catch (err) {
      console.error('google signin error', err);
      alert('google signin error');
    }
  };

  const facebookLogin = async () => {
    const facebookAuthProvider = new FacebookAuthProvider();
    facebookAuthProvider.addScope('public_profile');
    facebookAuthProvider.addScope('email');

    const result = await signInWithPopup(auth, facebookAuthProvider);
    const credential = FacebookAuthProvider.credentialFromResult(result);

    const token = CryptoJS.AES.encrypt(
      credential.accessToken.toString(),
      process.env.REACT_APP_ARBAIM_SECRET_KEY,
    ).toString();

    const localToken = CryptoJS.AES.encrypt(
      credential.accessToken.toString(),
      process.env.REACT_APP_ARBAIM_SECRET_KEY_2,
    ).toString();

    const userInfo = JSON.parse(result._tokenResponse.rawUserInfo);

    const authData = {
      requesterToken: token,
      accessToken: credential.accessToken,
      accessTokenSource: undefined,
      applicationID: '',
      dataAccessExpirationTime: result.user.stsTokenManager.expirationTime,
      declinedPermissions: [],
      expirationTime: result.user.stsTokenManager.expirationTime,
      expiredPermissions: [],
      lastRefreshTime: '',
      permissions: userInfo.granted_scopes,
      userID: userInfo.id,
      user: {
        email: userInfo.email,
        name: userInfo.name,
        photo: result._tokenResponse.photoUrl,
        id: userInfo.id,
      },
    };

    // console.log('before authData', authData);

    try {
      AdminRepository.setRequesterToken(token);
      const facebookResult = await AdminRepository.request(
        `${API_ROOT_URL}/login`,
        'POST',
        {
          authType: 'facebook',
          authData,
        },
      );

      if (facebookResult && facebookResult.statusCode === 1) {
        setAuthUser({ ...authData, localToken, token });
        setAuthType('facebook');
        setEnrolled(false);
      } else {
        localStorage.setItem('id', facebookResult.userId);
        localStorage.setItem('token', localToken);

        AdminRepository.setRequester(token, facebookResult.userId);

        const _user = await AdminRepository.request(
          `${API_ROOT_URL}/users/${facebookResult.userId}`,
          'GET',
        );

        if (_user && _user.statusCode === 0) {
          setAuthorized(true);
          setUser(_user);
        } else {
          alert('페이스북 로그인 실패, 관리자에게 문의해주세요');
        }
      }
    } catch (err) {
      console.error('facebook signin error', err);
      alert('facebook signin error');
    }
  };

  const kakaoLogin = async () => {
    const { Kakao } = window;

    if (!Kakao.isInitialized()) {
      Kakao.init(process.env.REACT_APP_KAKAO_API_KEY);
    }

    // Kakao.Auth.authorize({ redirectUri: 'http://localhost:3000/login' });
    Kakao.Auth.login({
      success(loginResult) {
        Kakao.API.request({
          url: '/v2/user/me',
          success: async (profile) => {
            const token = CryptoJS.AES.encrypt(
              loginResult.access_token.toString(),
              process.env.REACT_APP_ARBAIM_SECRET_KEY,
            ).toString();

            const localToken = CryptoJS.AES.encrypt(
              loginResult.access_token.toString(),
              process.env.REACT_APP_ARBAIM_SECRET_KEY_2,
            ).toString();

            const authData = {
              ...loginResult,
              ...profile,
              requesterToken: token,
              accessToken: token,
            };

            AdminRepository.setRequesterToken(token);
            const kakaoResult = await AdminRepository.request(
              `${API_ROOT_URL}/login`,
              'POST',
              {
                authType: 'kakao',
                authData,
              },
            );

            if (kakaoResult && kakaoResult.statusCode === 1) {
              setAuthUser({ ...authData, localToken, token });
              setAuthType('kakao');
              setEnrolled(false);
            } else {
              localStorage.setItem('id', kakaoResult.userId);
              localStorage.setItem('token', localToken);

              AdminRepository.setRequester(token, kakaoResult.userId);

              const _user = await AdminRepository.request(
                `${API_ROOT_URL}/users/${kakaoResult.userId}`,
                'GET',
              );

              if (_user && _user.statusCode === 0) {
                setAuthorized(true);
                setUser(_user);
              } else {
                alert('카카오 로그인 실패, 관리자에게 문의해주세요');
              }
            }
          },
          fail: () => {},
        });
      },
    });
  };

  return (
    <>
      {!isEnrolled ? (
        <Enroll
          authType={authType}
          authUser={authUser}
          setAuthorized={setAuthorized}
          setUser={setUser}
        />
      ) : (
        <div className="bg-greyd-grey flex flex-col justify-evenly items-center h-screen">
          <div className="flex flex-col items-center">
            <img src={greydLogo} height={126} width={126} alt="" />
            <img
              className="mt-4"
              src={greydImg}
              width={80}
              height={30}
              alt=""
            />
          </div>

          <div className="flex flex-col items-center w-screen">
            <h2 className="text-gray-400 text-lg">SNS로 로그인하기</h2>
            <div className="mt-4 flex flex-row justify-evenly w-3/12">
              <button onClick={() => kakaoLogin()}>
                <div className="border-solid rounded-full bg-gray-800 p-2">
                  <img src={kakaoLogo} alt="" width={32} height={32} />
                </div>
              </button>
              <button onClick={() => facebookLogin()}>
                <div className="border-solid rounded-full bg-gray-800 p-2">
                  <img src={facebookLogo} alt="" width={32} height={32} />
                </div>
              </button>
              <button onClick={() => googleLogin()}>
                <div className="border-solid rounded-full bg-gray-800 p-2">
                  <img src={googleLogo} alt="" width={32} height={32} />
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
