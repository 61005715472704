import React, { useEffect, useRef, useState } from 'react';
import { Layout, Menu } from 'antd';
import {
  UserOutlined,
  SolutionOutlined,
  InboxOutlined,
  ShoppingCartOutlined,
  DollarOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.min.css';
import { HashRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Strings from '../strings/kor';

import UserListView from '../organisms/UserListView';
import ReviewListView from '../organisms/ReviewListView';
import ProductListView from '../organisms/ProductListView';
import OrderListView from '../organisms/OrderListView';
import RevenueListView from '../organisms/RevenueListView';
import ReviewerRevenueListView from '../organisms/ReviewerRevenueListView';
import Modal from '../molecules/Modal';
import UserUpdate from '../organisms/UserUpdateView';
import AdminRepository from '../repositories/AdminRepository';
import PushNotificationView from '../organisms/PushNotificationView';
import UserRewardListView from '../organisms/UserRewardListView';
import UserRewardInputView from '../organisms/UserRewardInputView';
import UserRewardInputCheckListView from '../organisms/UserRewardInputCheckListView';

function MainPage({ user, setUser, setAuthorized }) {
  const { SubMenu } = Menu;
  const { Header, Content, Sider } = Layout;

  const [isAdmin, setIsAdmin] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  const [KRWPerUSD, setKRWPerUSD] = useState(0);

  const mainPageRef = useRef({});
  const mainPageRef2 = useRef({});

  useEffect(() => {
    AdminRepository.getCurrencyRate()
      .then((result) => setKRWPerUSD(result.currencyRate))
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    // console.log(isAdmin, user);

    if (user) {
      const emailArray = user.email.split('@');
      const domain = emailArray[emailArray.length - 1];

      if (domain === 'arbaim.com' || user.email === 'arbaim.guest@gmail.com') {
        setIsAdmin(true);
        setIsSeller(true);
      } else {
        setIsAdmin(false);
        setIsSeller(user?.sellerStatus);

        if (!isAdmin && !user?.sellerStatus) {
          mainPageRef.current.setShowModal(true);
        }
      }
    }
  }, [isAdmin, user]);

  return (
    <Router>
      <Layout>
        <Header className="header">
          <div
            className="logo"
            style={{ fontSize: '30px', fontWeight: 'bold', color: 'white' }}
          >
            {user ? (
              <div style={{ float: 'right' }}>
                <span className="text-base mr-4 font-normal">
                  {user.name}님 환영합니다
                </span>
                <Modal
                  title="정보수정"
                  tailwindcss="text-base font-extrabold mr-4"
                  handleSubmit={() => mainPageRef2.current.handleSubmit()}
                  ref={mainPageRef}
                  isSeller={isSeller}
                  message="셀러정보가 등록되어있지 않습니다. 필수정보를 입력해주세요 (정보가 없으면 제품 및 배송 관리에 어려움이 있습니다)"
                >
                  <UserUpdate
                    ref={mainPageRef2}
                    authUser={user}
                    setUser={setUser}
                  />
                </Modal>
                <button
                  className="text-base font-extrabold"
                  onClick={() => {
                    localStorage.clear();
                    setIsAdmin(false);
                    setAuthorized(false);
                  }}
                >
                  로그아웃
                </button>
              </div>
            ) : null}
            <div style={{ float: 'left' }}>
              <Link className="text-pioneer" to="/">
                greyd Admin Page
              </Link>
            </div>
          </div>
        </Header>
        <Layout>
          <Sider width={200} className="site-layout-background">
            <Menu mode="inline" style={{ height: '100%', borderRight: 0 }}>
              {isAdmin ? (
                <SubMenu
                  key="user"
                  icon={<UserOutlined />}
                  title={Strings.MENU_USER_MANAGEMENT}
                >
                  <Menu.Item key="1">
                    <Link to="/user">{Strings.SUB_USER_MANAGEMENT}</Link>
                  </Menu.Item>
                  <Menu.Item key="9">
                    <Link to="/user-reward/input">
                      {Strings.SUB_USER_REWARD_INPUT}
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="11">
                    <Link to="/user-reward/input-check">
                      {Strings.SUB_USER_REWARD_INPUT_CHECK}
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="10">
                    <Link to="/user-reward/withdrawal">
                      {Strings.SUB_USER_REWARD_WITHDRAWAL}
                    </Link>
                  </Menu.Item>
                </SubMenu>
              ) : null}
              {isAdmin ? (
                <SubMenu
                  key="review"
                  icon={<SolutionOutlined />}
                  title={Strings.MENU_REVIEW_MANAGEMENT}
                >
                  <Menu.Item key="2">
                    <Link to="/review">{Strings.SUB_REVIEW_MANAGEMENT}</Link>
                  </Menu.Item>
                </SubMenu>
              ) : null}
              <SubMenu
                key="product"
                icon={<InboxOutlined />}
                title={Strings.MENU_PRODUCT_MANAGEMENT}
              >
                <Menu.Item key="3">
                  <Link to="/product">{Strings.SUB_PRODUCT_MANAGEMENT}</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="order"
                icon={<ShoppingCartOutlined />}
                title={Strings.MENU_ORDER_MANAGEMENT}
              >
                <Menu.Item key="4">
                  <Link to="/order">{Strings.SUB_ORDER_MANAGEMENT}</Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu
                key="sales"
                icon={<DollarOutlined />}
                title={Strings.MENU_SALES_MANAGEMENT}
              >
                <Menu.Item key="5">
                  <Link to="/sales">{Strings.SUB_SALES_MANAGEMENT}</Link>
                </Menu.Item>
                {/* <Menu.Item key='6'>
                  <Link to='/review-rewards'>{Strings.SUB_REVIEW_REWARDS_MANAGEMENT}</Link>
                </Menu.Item> */}
              </SubMenu>
              {isAdmin ? (
                <SubMenu
                  key="billing"
                  icon={<CreditCardOutlined />}
                  title={Strings.MENU_BILLING_MANAGEMENT}
                >
                  <Menu.Item key="7">
                    <Link to="/billing">{Strings.SUB_BILLING_MANAGEMENT}</Link>
                  </Menu.Item>
                </SubMenu>
              ) : null}
              {isAdmin ? (
                <SubMenu
                  key="push-notification"
                  icon={<UserOutlined />}
                  title={Strings.MENU_PUSH_NOTIFICATION_MANAGEMENT}
                >
                  <Menu.Item key="8">
                    <Link to="/push-notification">
                      {Strings.SUB_PUSH_NOTIFICATION_MANAGEMENT}
                    </Link>
                  </Menu.Item>
                </SubMenu>
              ) : null}
            </Menu>
          </Sider>
          <Layout style={{ padding: '0 24px 24px' }}>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Switch>
                <Route exact path="/">
                  <div>Dash Board</div>
                </Route>
                <Route path="/user">
                  <UserListView />
                </Route>
                <Route path="/review">
                  <ReviewListView isAdmin={isAdmin} />
                </Route>
                <Route path="/product">
                  <ProductListView
                    isAdmin={isAdmin}
                    user={user}
                    KRWPerUSD={KRWPerUSD}
                  />
                </Route>
                <Route path="/order">
                  <OrderListView isAdmin={isAdmin} />
                </Route>
                <Route path="/sales">
                  <RevenueListView isSeller isAdmin={isAdmin} />
                </Route>
                <Route path="/review-rewards">
                  <ReviewerRevenueListView isSeller={false} isAdmin={isAdmin} />
                </Route>
                <Route path="/billing" />
                <Route path="/push-notification">
                  <PushNotificationView />
                </Route>
                <Route path="/user-reward/input">
                  <UserRewardInputView isAdmin={isAdmin} />
                </Route>
                <Route path="/user-reward/input-check">
                  <UserRewardInputCheckListView isAdmin={isAdmin} />
                </Route>
                <Route path="/user-reward/withdrawal">
                  <UserRewardListView isAdmin={isAdmin} />
                </Route>
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Router>
  );
}

export default MainPage;
