import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
import { Table } from 'antd';

import RevenueCard from './RevenueCard';
import { getFormattedDate } from '../utils';

function onChange(pagination, filters, sorter, extra) {
  // console.log('params', pagination, filters, sorter, extra);
}

function RevenueTable(props) {
  const [revenueList, setRevenueList] = useState(props.revenueList);
  useEffect(() => {
    setRevenueList(props.revenueList);
  }, [props.revenueList]);
  const columns = [
    {
      title: '제품명',
      dataIndex: '',
      render: (record) => (
        <span>
          {`${record.order.cartItems[0]?.product.title} ${
            record.order.cartItems.length - 1 > 0 ? `외 ${record.order.cartItems.length - 1}` : ''
          }`}
        </span>
      ),
      width: '200px',
      ellipsis: true,
    },
    {
      title: '셀러',
      dataIndex: '',
      width: '120px',
      ellipsis: true,
      render: (record) => <span>{record.user.name}</span>,
    },
    // {
    //   title: 'Order Id',
    //   dataIndex: '',
    //   width: '120px',
    //   ellipsis: true,
    //   render: (record) => <span>{record.order.id}</span>,
    // },
    {
      title: '결제금액',
      width: '80px',
      render: (record) => <span>{record.order.totalPrice}</span>,
    },
    {
      title: '배송비',
      width: '80px',
      render: (record) => <span>{record.order.shipmentCost}</span>,
    },
    {
      title: '수익',
      width: '80px',
      dataIndex: 'profitAmount',
    },
    {
      title: '판매확정',
      width: '170px',
      ellipsis: true,
      render: (record) => (
        <span>{record.order.purchaseCompletedAt ? getFormattedDate(record.order.purchaseCompletedAt) : '미확정'}</span>
      ),
      sorter: {
        compare: (a, b) => {
          const date1 = new Date(a.order.purchaseCompletedAt);
          const date2 = new Date(b.order.purchaseCompletedAt);

          return date1 - date2;
        },
        multiple: 1,
      },
    },
    {
      title: '주문자',
      width: '80px',
      ellipsis: true,
      render: (record) => <span>{record.order.buyerName}</span>,
    },
    // {
    //   title: '구매수',
    //   width: '80px',
    //   render: (record) => <span>{record.order.number}</span>,
    // },
    {
      title: '주문날짜',
      dataIndex: '',
      width: '170px',
      ellipsis: true,
      render: (record) => <span>{getFormattedDate(record.order.paidAt)}</span>,
      sorter: {
        compare: (a, b) => {
          const date1 = new Date(a.createdAt);
          const date2 = new Date(b.createdAt);

          return date1 - date2;
        },
        multiple: 1,
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={revenueList}
      rowKey={(record) => record._id}
      expandable={{
        expandedRowRender: (record) => <RevenueCard revenueData={record} />,
        rowExpandable: (record) => true,
      }}
      onChange={onChange}
    />
  );
}

export default RevenueTable;
