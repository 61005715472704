import { DatePicker } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CATEGORY_LIST } from '../constant/index';
import AddOptions from '../molecules/AddOptions';
import AdditionalProducts from '../molecules/AddtionalProducts';
import InputFiles from '../molecules/InputFiles';
import InputSelect from '../molecules/InputSelect';
import InputText from '../molecules/InputText';
import InputTextarea from '../molecules/InputTextarea';
import { KRNationalities } from '../nationalities';
import AdminRepository, { API_ROOT_URL } from '../repositories/AdminRepository';

const ProductModify = forwardRef((props, ref) => {
  useEffect(() => {
    AdminRepository.getProduct(props.product.id).then((res) => {
      setProductId(res.data.id);
      setTitle(res.data.title);
      setCategoryCode(res.data.categoryCode);
      setCountryCode(res.data.countryCode);
      setAttachmentList(res.data.attachmentList);
      setDescriptionImageList(res.data.descriptionImageList);
      setDescription(res.data.description);
      setPrice(res.data.price);
      setDiscountPrice(res.data.discountPrice);
      setShipmentCost(res.data.shipmentCost);
      setShipmentCostUS(res.data.shipmentCostUS || 20 * props.KRWPerUSD);
      setIsAvailableFreeShippingKR(res.data.isAvailableFreeShippingKR);
      setLowestOrderPriceForFreeDeliveryKR(
        res.data.lowestOrderPriceForFreeDeliveryKR,
      );
      setIsAvailableFreeShippingUS(res.data.isAvailableFreeShippingUS);
      setLowestOrderPriceForFreeDeliveryUS(
        res.data.lowestOrderPriceForFreeDeliveryUS,
      );
      setLowestPriceLink(res.data.lowestPriceLink);

      const convertedChecks = {};
      res.data.options.checks.forEach((check, idx) => {
        convertedChecks[idx] = check;
        convertedChecks[idx].count = idx;
      });
      setAdditionalProducts(convertedChecks);

      const convertedList = {};
      res.data.options.lists.forEach((list, idx) => {
        convertedList[idx] = list;
        convertedList[idx].count = idx;
      });
      setAdditionalOptions(convertedList);

      setIsDiscountEnabled(res.data.discountPrice > 0);
      setIsNoLimitNumberToSale(res.data.availableNumberToSale !== -1);
      setIsAvailableToSale(res.data.isAvailableToSale);
      setAvailableNumberToSale(res.data.availableNumberToSale);

      setIsPromotion(res.data.isPromotion);
      setPromotionStartDate(new Date(res.data.promotionStartDate));
      setPromotionEndDate(new Date(res.data.promotionEndDate));
      setIsRefundable(res.data.eventType === 'refund');

      setProductImageCount(res.data.attachmentList.length);
      setProductDetailImageCount(res.data.descriptionImageList.length);

      setIsValidTitle(!!res.data.title);
      setIsValidCategory(!!res.data.categoryCode);
      setIsValidCountry(!!res.data.countryCode);
      setIsValidAttachments(!!res.data.attachmentList);
      setIsValidDescription(!!res.data.description);
      setIsValidPrice(!!res.data.price);
      setIsValidShipmentCostKR(!!res.data.shipmentCost);
    });
  }, []);

  const [productId, setProductId] = useState('');

  const [title, setTitle] = useState(props.title);
  const [categoryCode, setCategoryCode] = useState('');
  const [countryCode, setCountryCode] = useState('KR');
  const [attachmentList, setAttachmentList] = useState([]);
  const [descriptionImageList, setDescriptionImageList] = useState([]);
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [shipmentCost, setShipmentCost] = useState(0);
  const [shipmentCostUS, setShipmentCostUS] = useState(0);
  const [isAvailableFreeShippingKR, setIsAvailableFreeShippingKR] =
    useState(false);
  const [
    lowestOrderPriceForFreeDeliveryKR,
    setLowestOrderPriceForFreeDeliveryKR,
  ] = useState(0);
  const [isAvailableFreeShippingUS, setIsAvailableFreeShippingUS] =
    useState(false);
  const [
    lowestOrderPriceForFreeDeliveryUS,
    setLowestOrderPriceForFreeDeliveryUS,
  ] = useState(0);

  const [isNoLimitNumberToSale, setIsNoLimitNumberToSale] = useState(false);
  const [availableNumberToSale, setAvailableNumberToSale] = useState(0);

  const [lowestPriceLink, setLowestPriceLink] = useState('');

  // additional product
  const [additionalProducts, setAdditionalProducts] = useState({});
  const [additionalOptions, setAdditionalOptions] = useState({});

  const [isAvailableToSale, setIsAvailableToSale] = useState(true);

  // validation
  const [isValidTitle, setIsValidTitle] = useState(false);
  const [isValidCategory, setIsValidCategory] = useState(false);
  const [isValidCountry, setIsValidCountry] = useState(true);
  const [isValidAttachments, setIsValidAttachments] = useState(false);
  const [isValidDescription, setIsValidDescription] = useState(false);
  const [isValidPrice, setIsValidPrice] = useState(false);
  const [isValidCurrentLowestPrice, setIsValidCurrentLowestPrice] =
    useState('');
  const [isValidShipmentCostKR, setIsValidShipmentCostKR] = useState(false);
  const [isValidShipmentCostUS, setIsValidShipmentCostUS] = useState('');

  const [isDiscountEnabled, setIsDiscountEnabled] = useState(false);
  const [isPromotion, setIsPromotion] = useState(false);
  const [promotionStartDate, setPromotionStartDate] = useState(new Date());
  const [promotionEndDate, setPromotionEndDate] = useState(new Date());
  const [isRefundable, setIsRefundable] = useState(false);

  const [productImageCount, setProductImageCount] = useState(0);
  const [productDetailImageCount, setProductDetailImageCount] = useState(0);

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  const handleSubmit = () => {
    if (
      !(
        (
          isValidTitle &&
          isValidCategory &&
          isValidCountry &&
          isValidAttachments &&
          isValidDescription &&
          isValidPrice &&
          isValidShipmentCostKR
        )
        // isValidCurrentLowestPrice &&
        // && isValidShipmentCostUS
      )
    ) {
      let inValidMesage = '필수 입력 값을 채워주세요.';

      if (!isValidTitle) {
        inValidMesage += ' (상품 이름)';
      } else if (!isValidCategory) {
        inValidMesage += ' (카테고리)';
      } else if (!isValidCountry) {
        inValidMesage += ' (상품 판매국)';
      } else if (!isValidAttachments) {
        inValidMesage += ' (상품 대표 이미지)';
      } else if (!isValidDescription) {
        inValidMesage += ' (상품 상세 설명)';
      } else if (!isValidPrice) {
        inValidMesage += ' (상품 가격)';
      } else if (!isValidShipmentCostKR) {
        inValidMesage += ' (배송비)';
      }

      return alert(inValidMesage);
    }

    const formData = new FormData();

    const options = {
      checks: Object.keys(additionalProducts).map((key) => ({
        name: additionalProducts[key].name,
        addition: additionalProducts[key].addition,
      })),
      lists: Object.keys(additionalOptions).map((key) => ({
        name: additionalOptions[key].name,
        items: additionalOptions[key].items,
      })),
    };

    formData.append('title', title);
    formData.append('description', description);
    formData.append('countryCode', countryCode);
    formData.append('price', price);
    formData.append('discountPrice', discountPrice);
    formData.append('shipmentCost', shipmentCost);
    formData.append('shipmentCostUS', shipmentCostUS);
    formData.append('isAvailableFreeShippingKR', isAvailableFreeShippingKR);
    formData.append(
      'lowestOrderPriceForFreeDeliveryKR',
      lowestOrderPriceForFreeDeliveryKR,
    );
    formData.append('isAvailableFreeShippingUS', isAvailableFreeShippingUS);
    formData.append(
      'lowestOrderPriceForFreeDeliveryUS',
      lowestOrderPriceForFreeDeliveryUS,
    );

    for (let i = 0; i < attachmentList.length; i++) {
      formData.append('productImage', attachmentList[i]);
    }

    for (let i = 0; i < descriptionImageList.length; i++) {
      formData.append('descriptionImage', descriptionImageList[i]);
    }

    formData.append('categoryCode', categoryCode);
    formData.append(
      'availableNumberToSale',
      isNoLimitNumberToSale ? availableNumberToSale : -1,
    );
    formData.append('options', JSON.stringify(options));
    formData.append('lowestPriceLink', lowestPriceLink);
    formData.append('requesterId', AdminRepository.requesterId);
    formData.append('requesterToken', AdminRepository.requesterToken);
    formData.append('isAvailableToSale', isAvailableToSale);

    formData.append('productId', productId);
    formData.append(
      'attachmentList',
      JSON.stringify(
        attachmentList.filter((attach) => !(attach instanceof File)),
      ),
    );
    formData.append(
      'descriptionImageList',
      JSON.stringify(
        descriptionImageList.filter(
          (descriptionImage) => !(descriptionImage instanceof File),
        ),
      ),
    );
    formData.append('isAdminWeb', true);

    formData.append('isPromotion', isPromotion);

    if (isPromotion) {
      formData.append('promotionStartDate', promotionStartDate);
      formData.append('promotionEndDate', promotionEndDate);
      formData.append('eventType', isRefundable ? 'refund' : '');
    }

    // AdminRepository.request(`${API_ROOT_URL}/products/${productId}`, 'PUT', formData);

    axios
      .put(`${API_ROOT_URL}/products/${productId}`, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          version: 20221210,
          os: 'webadmin',
          token: AdminRepository.requesterToken,
        },
      })
      .then((res) => {
        console.log('res instanceof Error', res instanceof Error);
        if (res instanceof Error) {
          props.setShowModal(false);
          alert('상품 수정에 실패했습니다, 제품 셀러만 수정 가능합니다. ');
          return;
        }

        props.refreshProduct();
        alert('상품 수정이 완료되었습니다.');
        props.setShowModal(false);
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { errors } = error.response.data;

          const errorObject = errors[Object.keys(errors)[0]];

          alert(
            `상품 수정에 실패했습니다. 관리자에게 에러내용을 첨부하여 문의해주세요.\n[에러내용: ${errorObject.name} - ${errorObject.kind} (${errorObject.path}) ]`,
          );
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  };

  return (
    <div className="">
      <InputText
        label="상품 이름"
        inputId="title"
        placeholder="상품 이름을 입력해주세요"
        value={title}
        setValue={setTitle}
        required
        isValid={isValidTitle}
        setIsValid={setIsValidTitle}
      />
      <InputSelect
        inputId="category"
        label="카테고리"
        placeholder="카테고리를 선택해주세요"
        list={CATEGORY_LIST}
        required
        value={categoryCode}
        setValue={setCategoryCode}
        isValid={isValidCategory}
        setIsValid={setIsValidCategory}
      />
      <InputSelect
        inputId="countryCode"
        label="상품 판매국"
        placeholder="상품 판매국을 선택해주세요"
        list={KRNationalities}
        required
        value={countryCode}
        setValue={setCountryCode}
        isValid={isValidCountry}
        setIsValid={setIsValidCountry}
      />
      <InputFiles
        label="상품 이미지"
        inputId="productImages"
        placeholder={`상품 대표 이미지를 첨부해주세요 (${productImageCount}/10)`}
        required
        value={attachmentList}
        setValue={setAttachmentList}
        isValid={isValidAttachments}
        setIsValid={setIsValidAttachments}
        isEdit
        setImageCount={setProductImageCount}
      />
      <InputFiles
        label="상품 설명 이미지"
        inputId="productDescribeImages"
        placeholder={`상품 설명 이미지를 첨부해주세요 (${productDetailImageCount}/15)`}
        value={descriptionImageList}
        setValue={setDescriptionImageList}
        isEdit
        setImageCount={setProductDetailImageCount}
      />
      <InputTextarea
        label="상품 상세 설명"
        inputId="title"
        placeholder="상품에 대해 추가적으로 설명할 내용을 입력해주세요"
        value={description}
        setValue={setDescription}
        required
        isValid={isValidDescription}
        setIsValid={setIsValidDescription}
      />
      <InputText
        label="가격"
        inputId="price"
        placeholder="상품 가격을 입력해주세요"
        value={price}
        setValue={setPrice}
        required
        isValid={isValidPrice}
        setIsValid={setIsValidPrice}
        inputMode="numeric"
        customType="price"
      />
      <div className="flex items-center justify-center mb-2">
        <span className="text-striver font-semibold mr-2">할인여부</span>
        <input
          type="checkbox"
          className="cursor-pointer accent-giver w-4 h-4 relative rounded-full"
          checked={isDiscountEnabled}
          onChange={(e) => {
            setIsDiscountEnabled(e.target.checked);
          }}
        />
      </div>
      {isDiscountEnabled ? (
        <InputText
          label="할인가격"
          inputId="discountPrice"
          placeholder="상품 할인 가격을 입력해주세요"
          value={discountPrice}
          setValue={setDiscountPrice}
          inputMode="numeric"
          customType="price"
          required
        />
      ) : null}
      {/* <InputText
        label='최저가 사이트'
        inputId='currentLowestPrice'
        placeholder='최저가 사이트 URL을 입력해주세요'
        value={lowestPriceLink}
        setValue={setLowestPriceLink}
        required
        isValid={isValidCurrentLowestPrice}
        setIsValid={setIsValidCurrentLowestPrice}
      /> */}
      <InputText
        label="배송비(국내)"
        inputId="shipmentCost"
        placeholder="상품 할인 가격을 입력해주세요"
        value={shipmentCost}
        setValue={setShipmentCost}
        inputMode="numeric"
        customType="price"
        required
        isValid={isValidShipmentCostKR}
        setIsValid={setIsValidShipmentCostKR}
      />
      <div className="flex items-center justify-center mb-2">
        <span className="text-striver font-semibold mr-2">
          무료배송 가능여부
        </span>
        <input
          type="checkbox"
          className="cursor-pointer accent-giver w-4 h-4 relative rounded-full"
          checked={isAvailableFreeShippingKR}
          onChange={(e) => {
            setIsAvailableFreeShippingKR(e.target.checked);
          }}
        />
      </div>
      {isAvailableFreeShippingKR ? (
        <InputText
          label="무료배송 가능금액(국내)"
          inputId="shipmentCostUS"
          placeholder="무료배송 최소금액을 입력해주세요"
          value={lowestOrderPriceForFreeDeliveryKR}
          setValue={setLowestOrderPriceForFreeDeliveryKR}
          required
          inputMode="numeric"
          customType="price"
        />
      ) : null}
      <InputText
        label="배송비(해외)"
        inputId="shipmentCostUS"
        placeholder="상품 할인 가격을 입력해주세요"
        value={shipmentCostUS}
        setValue={setShipmentCostUS}
        inputMode="numeric"
        customType="price"
        required
        isValid={isValidShipmentCostUS}
        setIsValid={setIsValidShipmentCostUS}
      />
      <div className="flex items-center justify-center mb-2">
        <span className="text-striver font-semibold mr-2">
          무료배송 가능여부
        </span>
        <input
          type="checkbox"
          className="cursor-pointer accent-giver w-4 h-4 relative rounded-full"
          checked={isAvailableFreeShippingUS}
          onChange={(e) => {
            setIsAvailableFreeShippingUS(e.target.checked);
          }}
        />
      </div>
      {isAvailableFreeShippingUS ? (
        <InputText
          label="무료배송 가능금액(해외)"
          inputId="shipmentCostUS"
          placeholder="무료배송 최소금액을 입력해주세요"
          value={lowestOrderPriceForFreeDeliveryUS}
          setValue={setLowestOrderPriceForFreeDeliveryUS}
          required
          inputMode="numeric"
          customType="price"
        />
      ) : null}
      <div className="flex items-center justify-center mb-2">
        <span className="text-striver font-semibold mr-2">상품 재고</span>
        <input
          type="checkbox"
          className="cursor-pointer accent-giver w-4 h-4 relative rounded-full"
          checked={isNoLimitNumberToSale}
          onChange={(e) => {
            setIsNoLimitNumberToSale(e.target.checked);
          }}
        />
      </div>

      {isNoLimitNumberToSale ? (
        <InputText
          label="상품 재고 개수"
          inputId="isAvailableFreeShippingUS"
          placeholder="판매할 상품의 재고를 입력하세요"
          value={availableNumberToSale}
          setValue={setAvailableNumberToSale}
          required
          isValid
          inputMode="numeric"
          customType="price"
        />
      ) : null}
      <span className="text-explorer font-semibold text-base">
        선택항목 추가
      </span>
      <div className="flex flex-col mt-2">
        <AdditionalProducts
          productInputs={additionalProducts}
          setProductInputs={setAdditionalProducts}
        />
        <AddOptions
          optionInputs={additionalOptions}
          setOptionInputs={setAdditionalOptions}
        />
      </div>

      {props.isAdmin ? (
        <>
          <div className="flex items-center justify-center mb-2">
            <span className="text-striver font-semibold mr-2">
              프로모션 상품
            </span>
            <input
              type="checkbox"
              className="cursor-pointer accent-giver w-4 h-4 relative rounded-full"
              checked={isPromotion}
              onChange={(e) => {
                setIsPromotion(e.target.checked);
              }}
            />
          </div>

          <div className="flex items-center justify-center mb-2">
            <span className="text-striver font-semibold mr-2">100% 환불</span>
            <input
              type="checkbox"
              className="cursor-pointer accent-giver w-4 h-4 relative rounded-full"
              checked={isRefundable}
              onChange={(e) => {
                setIsRefundable(e.target.checked);
              }}
            />
          </div>

          {isPromotion ? (
            <div className="flex flex-row justify-evenly">
              <div className="flex flex-col items-center">
                <span className="text-pioneer">시작일</span>
                <input
                  className="mt-1 px-2 py-1 rounded-md"
                  type="date"
                  onChange={(e) => setPromotionStartDate(e.target.value)}
                  value={dayjs(promotionStartDate).format('YYYY-MM-DD')}
                />
              </div>

              <div className="flex flex-col items-center">
                <span className="text-pioneer">종료일</span>
                <input
                  className="mt-1 px-2 py-1 rounded-md"
                  type="date"
                  onChange={(e) => setPromotionEndDate(e.target.value)}
                  value={dayjs(promotionEndDate).format('YYYY-MM-DD')}
                />
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
});

ProductModify.displayName = 'ProductModify';
export default ProductModify;
