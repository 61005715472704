import React, { useEffect } from 'react';
import { Descriptions, Row, Col, Statistic } from 'antd';
import { getDateWithYYYYMMDDFormat, getFormattedDate } from '../utils';

function ProductCard(props) {
  const { productData } = props;

  return (
    <>
      <div style={{ display: 'table', width: '100%' }}>
        <div style={{ display: 'table-row', verticalAlign: 'middle' }}>
          <div
            style={{
              display: 'table-cell',
              textAlign: 'center',
              width: '200px',
            }}
          >
            {productData.thumbnailUrl ? (
              <img
                style={{
                  // borderRadius: "50%",
                  width: 150,
                  height: 150,
                }}
                src={productData.thumbnailUrl}
                alt=""
              />
            ) : (
              <img
                alt=""
                style={{
                  // borderRadius: "50%",
                  width: 150,
                  height: 150,
                }}
              />
            )}
          </div>
          <div
            style={{
              display: 'table-cell',
              textAlign: 'center',
              width: 'auto',
            }}
          >
            <Row gutter={16}>
              <Col span={6}>
                <Statistic title="조회수" value={productData.viewCount} />
              </Col>
              <Col span={6}>
                <Statistic
                  title="RatingScore/Count"
                  value={`${productData.ratingScore}/${productData.ratingCount}`}
                />
              </Col>
              <Col span={6}>
                <Statistic title="리뷰수" value={productData.commentCount} />
              </Col>
              <Col span={6}>
                <Statistic
                  title="같은 제품 리뷰"
                  value={productData.relayedVideoCount}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Descriptions bordered>
        {/* <Descriptions.Item label='ProductId'>{productData.productId}</Descriptions.Item> */}
        <Descriptions.Item label="제품명" span={2}>
          {productData.title}
        </Descriptions.Item>
        <Descriptions.Item label="정가">{productData.price}</Descriptions.Item>
        <Descriptions.Item label="할인가">
          {productData.discountPrice}
        </Descriptions.Item>
        <Descriptions.Item label="할인률">
          {productData.discountRate * 100}%
        </Descriptions.Item>
        <Descriptions.Item label="등록일">
          {getFormattedDate(productData.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="최근수정일">
          {getFormattedDate(productData.updatedAt)}
        </Descriptions.Item>

        {/* <Descriptions.Item label='vtype'>{productData.vtype}</Descriptions.Item> */}
        {/* <Descriptions.Item label='제품 대표 이미지' span={3}>
          <a target='_blank' href={productData.thumbnailUrl} rel='noreferrer'>
            새 창에서 보기
          </a>
        </Descriptions.Item> */}

        {productData.attachmentList && productData.attachmentList.length > 0 ? (
          <Descriptions.Item label="제품 대표 이미지" span={3}>
            {productData.attachmentList.map((attachment, idx) => (
              <a
                key={idx + 1}
                href={attachment.url}
                target="_blank"
                rel="noreferrer"
                className="rounded-lg p-2 bg-greyd-grey text-pioneer mr-1"
              >
                {idx + 1}
              </a>
            ))}
          </Descriptions.Item>
        ) : null}
        {productData.descriptionImageList &&
        productData.descriptionImageList.length > 0 ? (
          <Descriptions.Item label="제품 상세 이미지" span={3}>
            {productData.descriptionImageList.map((attachment, idx) => (
              <a
                key={idx + 1}
                href={attachment.url}
                target="_blank"
                rel="noreferrer"
                className="rounded-lg p-2 bg-greyd-grey text-pioneer mr-1"
              >
                {idx + 1}
              </a>
            ))}
          </Descriptions.Item>
        ) : null}
        <Descriptions.Item label="프로모션 여부">
          {productData.isPromotion ? 'O' : 'X'}
        </Descriptions.Item>
        <Descriptions.Item label="프로모션 시작일">
          {getDateWithYYYYMMDDFormat(productData.promotionStartDate)}
        </Descriptions.Item>
        <Descriptions.Item label="프로모션 종료일">
          {getDateWithYYYYMMDDFormat(productData.promotionEndDate)}
        </Descriptions.Item>
        <Descriptions.Item label="셀러 정보">
          <p>업체명: {productData.seller.sellerBusinessName}</p>
          <p>이메일: {productData.seller.email}</p>
          <p>아이디: {productData.seller.name}</p>
          <p>
            로그인 타입: {productData.seller.authType} (
            {productData.seller.authType === 'google'
              ? productData.seller.googleAuth.user.email
              : null}
            )
          </p>
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

export default ProductCard;

/*
{
    "ratingCountList": {
        "0": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0
    },
    "ratingScore": 0,
    "commentCount": 0,
    "linkedVideoCount": 0,
    "thumbnailUrl": "https://resource.greyd.app/productImage/1630827191999_dbdc71f0ce29ae4cbda44c2de65b8b8d.jpg",
    "discountPrice": 44900,
    "discountRate": 0.48,
    "vtype": 0,
    "_id": "613472b9597e6958ea8a91d0",
    "title": "리노베라 칼슘파우더 친환경 과일세정제 150g 3개 세트",
    "price": 87000,
    "createdAt": "2021-09-05T07:33:13.665Z",
    "updatedAt": "2021-09-05T07:34:33.600Z",
    "productId": "613472b9597e6958ea8a91d0",
    "ratingCount": 0,
    "id": "613472b9597e6958ea8a91d0"
},
*/
