import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import React, { useEffect, useState, useRef } from 'react';
import { orderStatus } from '../constant';
import AdminRepository from '../repositories/AdminRepository';

const columns = [
  {
    header: '주문일시',
    key: 'createdAt',
    width: 27,
  },
  {
    header: '주문번호',
    key: 'id',
    width: 28,
  },
  {
    header: '주문상품',
    key: 'productTitle',
    width: 76,
    // render: record=><span>{record.product.title}</span>,
  },
  {
    header: '주문자',
    key: 'buyerName',
    width: 13,
    // render: record=><span>{record.buyer.name}</span>,
  },
  {
    header: '받는분',
    key: 'receiverName',
    width: 13,
  },
  {
    header: '결제',
    key: 'totalPrice',
    width: 9,
  },
  {
    header: '배송비',
    key: 'shipmentCost',
    width: 9,
  },
  {
    header: '주문상태',
    key: 'orderStatus',
    width: 20,
    // render: record=><span>{orderStatus.get(record.statusCode)}</span>,
  },
  { header: '택배사', key: 'shipmentCourierCO', width: 20 },
  { header: '송장번호', key: 'shipmentCourierNO', width: 20 },
];

function OrderUploadButton(props) {
  const [xlsx, setXlsx] = useState(null);
  const fileInput = useRef();

  useEffect(() => {
    if (!xlsx) return;

    handleReadXlsxFile(xlsx);
  }, [xlsx]);

  const handleUploadSheet = async () => {
    // productListData
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('주문 목록'); // sheet 이름이 My Sheet // sheet 데이터 설정
    worksheet.columns = columns;
    props.orderList.map((record, index) => {
      const result = worksheet.addRow({
        createdAt: record.createdAt.replace('T', ' ').replace('Z', ''),
        id: record.id,
        productTitle: `${record.cartItems[0]?.product.title} ${
          record.cartItems.length - 1 > 0
            ? `외 ${record.cartItems.length - 1}`
            : ''
        }`,
        buyerName: record.buyer?.name,
        receiverName: record.receiverName,
        totalPrice: record.totalPrice,
        shipmentCost: record.shipmentCost,
        orderStatus: orderStatus.get(record.statusCode),
        shipmentCourierCO: record.shipmentCourierCO,
        shipmentCourierNO: record.shipmentCourierNO,
      });
      return result;
    });

    const mimeType = {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);

    const today = new Date();
    const year = today.getFullYear();
    const month = `0${today.getMonth() + 1}`.slice(-2);
    const day = `0${today.getDate()}`.slice(-2);

    const dateString = year + month + day;
    saveAs(blob, `주문목록-${dateString}.xlsx`);
  };

  const handleReadXlsxFile = (file) => {
    const wb = new ExcelJS.Workbook();
    const reader = new FileReader(file);

    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      const buffer = reader.result;
      const workbook = await wb.xlsx.load(buffer);

      const filteredCouriers = [];

      workbook.eachSheet((sheet, id) => {
        sheet.eachRow((row, rowIdx) => {
          if (rowIdx > 1) {
            if (
              row.values[8] === orderStatus.get(2) &&
              row.values[9] &&
              JSON.stringify(row.values[9]) !== '{}' &&
              row.values[10] &&
              JSON.stringify(row.values[10]) !== '{}'
            ) {
              filteredCouriers.push({
                orderId: row.values[2],
                shipmentCourierCO: row.values[9],
                shipmentCourierNO: row.values[10],
                statusCode: 3,
              });
            } else if (
              row.values[8] === orderStatus.get(3) &&
              row.values[9] &&
              JSON.stringify(row.values[9]) !== '{}' &&
              row.values[10] &&
              JSON.stringify(row.values[10]) !== '{}'
            ) {
              filteredCouriers.push({
                orderId: row.values[2],
                shipmentCourierCO: row.values[9],
                shipmentCourierNO: row.values[10],
              });
            }
          }
        });
      });

      const result = await AdminRepository.updateCouriers({ filteredCouriers });
      props.handleClickRefresh();

      fileInput.current.value = '';
    };
  };

  return (
    <>
      <label
        htmlFor="upload"
        className="cursor-pointer px-4 py-1 border w-20 bg-pioneer border-gray-300 text-center hover:text-blue-400 hover:border-blue-400"
      >
        업로드
      </label>
      <input
        key={xlsx}
        className="hidden"
        id="upload"
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={(e) => {
          setXlsx(e.target.files[0]);
        }}
        ref={fileInput}
      />
    </>
  );
}

export default OrderUploadButton;
