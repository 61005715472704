export default {
  MENU_USER_MANAGEMENT: '사용자 관리',
  MENU_REVIEW_MANAGEMENT: '리뷰 영상 관리',
  MENU_PRODUCT_MANAGEMENT: '제품 관리',
  MENU_ORDER_MANAGEMENT: '주문 관리',
  MENU_SALES_MANAGEMENT: '매출 내역 관리',
  MENU_BILLING_MANAGEMENT: '정산 관리',
  MENU_PUSH_NOTIFICATION_MANAGEMENT: '푸시 메시지 관리',
  SUB_USER_MANAGEMENT: '사용자 조회 및 관리',
  SUB_REVIEW_MANAGEMENT: '영상 조회 및 관리',
  SUB_PRODUCT_MANAGEMENT: '제품 조회 및 관리',
  SUB_ORDER_MANAGEMENT: '주문 조회 및 관리',
  SUB_SALES_MANAGEMENT: '매출 내역 조회',
  SUB_REVIEW_REWARDS_MANAGEMENT: '리뷰 리워드 조회 및 관리',
  SUB_BILLING_MANAGEMENT: '정산 관리',
  SUB_PUSH_NOTIFICATION_MANAGEMENT: '푸시 메시지 전송',
  SUB_USER_REWARD_INPUT: '리워드 입금 관리',
  SUB_USER_REWARD_WITHDRAWAL: '리워드 출금 관리',
  SUB_USER_REWARD_INPUT_CHECK: '리워드 입금 확인',
};
