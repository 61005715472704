import { Descriptions } from 'antd';
import React, { useEffect, useState } from 'react';
import defaultMan from '../assets/defaultMan.png';
import AdminRepository from '../repositories/AdminRepository';
import { getFormattedDate } from '../utils';

function UserRewardCard(props) {
  const [userData, setUserData] = useState(props.userData);
  const [userAvailableReward, setUserAvailableReward] = useState(0);

  useEffect(() => {
    console.log('userRewardData', props);

    AdminRepository.getUserAvailableReward({ userId: userData.userId._id }).then((res) => {
      if (res && res.success) {
        setUserAvailableReward(res.availableReward);
      }
    });
  }, []);

  return (
    <>
      <div style={{ display: 'table', width: '100%' }}>
        <div style={{ display: 'table-row', verticalAlign: 'middle' }}>
          <div
            style={{
              display: 'table-cell',
              textAlign: 'center',
              width: '200px',
            }}
          >
            {userData.userId.profilePicUrl ? (
              <img
                style={{
                  // borderRadius: "50%",
                  width: 150,
                  height: 150,
                }}
                src={userData.userId.profilePicUrl}
              />
            ) : (
              <img
                style={{
                  width: 150,
                  height: 150,
                }}
                src={defaultMan}
              />
            )}
          </div>
        </div>
      </div>
      <Descriptions bordered>
        <Descriptions.Item label="인스타그램">
          {userData.userId.instagramId ? (
            <a
              href={`https://instagram.com/${userData.userId.instagramId}`}
              target="_blank"
              rel="noreferrer"
              className="mr-4"
            >
              {userData.userId.instagramId}
            </a>
          ) : null}
        </Descriptions.Item>
        <Descriptions.Item label="출금가능 리워드 잔액">
          {userAvailableReward}
        </Descriptions.Item>
        <Descriptions.Item label="마지막 로그인">
          {getFormattedDate(userData.userId.lastLogonDate)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

export default UserRewardCard;
