import { useState, useEffect } from 'react';
import Pagination from './DualScrollPagination';

function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

function DualScrollView({
  userList,
  usersPerPage,
  pageCountPerScreen,
  selectedUsers,
  setSelectedUsers,
}) {
  const [users, setUsers] = useState([]);
  // const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setUsers(userList.sort(compare));
  }, [userList]);

  const handleSelectUser = (user) => {
    setUsers(users.filter((u) => u !== user));
    setSelectedUsers([...selectedUsers, user]);
  };

  const handleDeselectUser = (user) => {
    setSelectedUsers(selectedUsers.filter((u) => u !== user));
    setUsers([...users, user].sort(compare));
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter((user) =>
    user?.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <div className="flex flex-col bg-white p-4 mt-10 rounded-lg">
      <h2 className="text-lg font-bold mb-2">유저 목록</h2>
      <div className="flex flex-row items-center mb-4">
        <input
          type="text"
          placeholder="아이디로만 검색가능"
          value={searchTerm}
          onChange={handleSearchChange}
          className="border rounded-full px-3 py-1 w-3/4 mr-2 text-xl font-semibold"
        />
        <button
          onClick={() => setSearchTerm('')}
          className="bg-red-500 text-white rounded-full px-3 py-1"
        >
          Clear
        </button>
      </div>
      <div className="flex flex-row flex-wrap">
        {currentUsers.map((user, idx) => (
          <div
            key={`${user.name}_${idx}`}
            onClick={() => handleSelectUser(user)}
            className="bg-gray-200 text-gray-800 px-3 py-1 m-2 cursor-pointer"
            // rounded-full
          >
            <div className="text-xl">
              <p className="my-0">
                아이디: <span className="font-bold">{user.name}</span>
              </p>
              {user.email && (
                <p className="my-0">
                  이메일: <span className="font-bold">{user.email}</span>
                </p>
              )}
              {user.instagramId && (
                <p className="my-0">
                  인스타그램:{' '}
                  <span className="font-bold">{user.instagramId}</span>
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
      <h2 className="text-lg font-bold my-2">선택된 유저들</h2>
      <div className="flex flex-row flex-wrap">
        {selectedUsers.map((user, idx) => (
          <div
            key={`${user.name}_${idx}`}
            onClick={() => handleDeselectUser(user)}
            className="bg-blue-500 text-white rounded-full px-3 py-1 m-2 cursor-pointer"
          >
            {user.name}
          </div>
        ))}
      </div>
      <Pagination
        itemsPerPage={usersPerPage}
        // totalItems={userList.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        displayPage={pageCountPerScreen}
        totalPage={Math.ceil(users.length / usersPerPage)}
      />
    </div>
  );
}

export default DualScrollView;
