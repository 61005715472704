import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
import { Table } from 'antd';

import OrderCard from './OrderCard';
import ItemManageButton from './ItemManageButton';
import { orderStatus } from '../constant';
import { getFormattedDate } from '../utils';

function onChange(pagination, filters, sorter, extra) {
  // console.log('params', pagination, filters, sorter, extra);
}

function OrderTable(props) {
  const [orderList, setOrderList] = useState(props.orderList);
  useEffect(() => {
    setOrderList(props.orderList);
  }, [props.orderList]);

  const handleDeleteSuccess = (record) => {
    setOrderList(orderList.filter((item) => item !== record));
  };

  const columns = [
    {
      title: '주문일시',
      dataIndex: 'createdAt',
      width: '170px',
      ellipsis: true,
      sorter: {
        compare: (a, b) => {
          const date1 = new Date(a.createdAt);
          const date2 = new Date(b.createdAt);

          return date1 - date2;
        },
        multiple: 1,
      },
      render: (record) => <span>{getFormattedDate(record)}</span>,
    },
    // {
    //   title: '주문번호',
    //   width: '100px',
    //   ellipsis: true,
    //   dataIndex: 'id',
    // },
    {
      title: '주문상품',
      width: '210px',
      ellipsis: true,
      render: (record) => (
        <span>
          {`${record.cartItems[0]?.product.title} ${
            record.cartItems.length - 1 > 0
              ? `외 ${record.cartItems.length - 1}`
              : ''
          }`}
        </span>
      ),
    },
    {
      title: '주문자',
      render: (record) => <span>{record.buyer?.name}</span>,
      ellipsis: true,
    },
    {
      title: '받는분',
      dataIndex: 'receiverName',
      ellipsis: true,
    },
    {
      title: '상품금액',
      render: (record) => (
        <span>{record.totalPrice - record.shipmentCost}</span>
      ),
    },
    {
      title: '배송비',
      dataIndex: 'shipmentCost',
    },
    {
      title: '결제금액',
      // dataIndex: 'totalPrice',
      render: (record) => (
        <span>
          {record.totalPrice - record.promotionDiscount - record.rewardUse}
        </span>
      ),
      ellipsis: true,
    },
    {
      // TODO: status 대응 문자열로 변경 필요.
      title: '주문상태',
      render: (record) => <span>{orderStatus.get(record.statusCode)}</span>,
      // dataIndex: 'statusCode',
      width: '170px',
      ellipsis: true,
    },
    {
      title: '관리',
      dataIndex: '',
      key: 'x',
      render: (record) => (
        <ItemManageButton
          type="order"
          record={record}
          onSuccess={handleDeleteSuccess}
          handleClickRefresh={props.handleClickRefresh}
        />
      ),
    },
  ];

  function getRowClassName(record, index) {
    return record.cartItems[0].product.eventType === 'refund'
      ? 'event-product'
      : 'normal-product'; // Define custom row class based on index
  }

  return (
    <Table
      columns={columns}
      dataSource={orderList}
      rowKey={(record) => record.orderId}
      expandable={{
        expandedRowRender: (record) => <OrderCard orderData={record} />,
        rowExpandable: (record) => true,
      }}
      onChange={onChange}
      rowClassName={getRowClassName}
    />
  );
}

export default OrderTable;
