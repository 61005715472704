import { useEffect, useState } from 'react';
import { COURIER_LIST, ORDER_STATUS_CODE } from '../constant';
import AdminRepository from '../repositories/AdminRepository';
import InputSelect from './InputSelect';
import InputText from './InputText';

function OrderInvoiceInput({ order, setShowModal, updateOrderList }) {
  const [reasonText, setReasonText] = useState();
  const [courier, setCourier] = useState('');
  const [courierNumber, setCourierNumber] = useState();
  const [customCourier, setCustomCourier] = useState('');

  useEffect(() => {}, []);

  const handleClickAccept = async (orderId) => {
    if (!courier || !courierNumber) {
      alert('택배사, 송장번호가 입력되었는지 확인해주세요');
      return;
    }

    if (
      !window.confirm('송장번호를 입력하고 구매자에게 배송 시작을 알립니다.')
    ) {
      return;
    }

    const result = await AdminRepository.actionOrder(
      orderId,
      ORDER_STATUS_CODE.SHIPPING,
      {
        company: courier === 'etc' ? customCourier : courier,
        invoice: courierNumber,
      },
    );

    setShowModal(false);
    updateOrderList();
  };

  const handleClickReject = async (orderId) => {
    if (!window.confirm('주문을 거절하시겠습니까?')) {
      return;
    }

    const result = await AdminRepository.actionOrder(
      orderId,
      ORDER_STATUS_CODE.SELLER_CANCEL_REQUEST,
      undefined,
      undefined,
      reasonText,
    );

    setShowModal(false);
    updateOrderList();
  };

  return (
    <div className="flex flex-col items-center ">
      <div className="flex flex-col text-pioneer">
        <span>
          주문상품:
          {` ${order.cartItems[0]?.product.title} ${
            order.cartItems.length - 1 > 0
              ? `외 ${order.cartItems.length - 1}`
              : ''
          }`}
        </span>
        <span>가격: {order.totalPrice}원</span>
        <span>상품 갯수: {order.cartItems[0]?.number}</span>
      </div>
      <div className="w-3/5 flex flex-col mt-6">
        <InputSelect
          label="택배사 선택"
          inputId="courier"
          placeholder="택배사를 선택해주세요"
          required
          value={courier}
          setValue={setCourier}
          list={COURIER_LIST}
        />
        {courier === 'etc' ? (
          <InputText
            value={customCourier}
            setValue={setCustomCourier}
            required
            label="택배사"
            inputId="customCourier"
            placeholder="택배사 이름"
          />
        ) : null}
        <InputText
          value={courierNumber}
          setValue={setCourierNumber}
          required
          label="운송장 번호"
          inputId="courierNumber"
          placeholder="운송장번호 (- 제외)"
        />
        <button
          className="text-giver font-bold text-lg px-6 py-1 rounded-md border-0 bg-operator mt-6 mb-10"
          onClick={() => handleClickAccept(order.orderId)}
        >
          배송시작
        </button>
      </div>
      <div className="w-3/5 flex flex-col">
        <textarea
          rows={5}
          className="rounded-lg py-1 px-3 resize-none"
          placeholder="거절 사유를 입력해주세요, 구매자에게 전송됩니다."
          value={reasonText}
          onChange={(e) => {
            setReasonText(e.target.value);
          }}
        />
        <button
          className="text-giver font-bold text-lg px-6 py-1 rounded-md border-0 bg-operator mt-4"
          onClick={() => handleClickReject(order.orderId)}
        >
          주문거절
        </button>
      </div>
    </div>
  );
}

export default OrderInvoiceInput;
