import React, { useState, useEffect } from 'react';
import { Input, Select, Radio, Button } from 'antd';
import OrderTable from '../molecules/OrderTable';
import OrderDownloadButton from '../molecules/OrderDownloadButton';
import AdminRepository from '../repositories/AdminRepository';
import { orderStatus as ORDER_STATUS } from '../constant';

import OrderUploadButton from '../molecules/OrderUploadButton';
import Spinner from './Spinner';

// TODO: 주문 리스트 조회 API 로직 보완, 20개 이상의 리스트가 보여지도록
// TODO: Style 정리, 문자열 constant 정리
const orderStatusObj = Object.fromEntries(ORDER_STATUS.entries());

function OrderListView({ isAdmin }) {
  const [orderListData, setOrderListData] = useState([]);
  const [searchType, setSearchType] = useState('');
  const [orderStatus, setOrderStatus] = useState(-1);
  const [isLoading, setLoading] = useState(false);

  const { Search } = Input;
  const { Option } = Select;

  useEffect(() => {}, []);

  const handleRadioChange = (e) => {
    setSearchType(e.target.value);
  };

  const handleOrderStatusSelectChange = async (value) => {
    setOrderStatus(value);
    const params = {};
    if (value !== -1) params.statusCode = value;

    // TODO : 추가적으로 item을 부르도록 수정해야 함.
    // 임시 코드
    params.limit = 3000;
    params.isAdmin = isAdmin;

    setLoading(true);

    const result = await AdminRepository.getOrderList(params);

    if (result) {
      setOrderListData(result.data);
    }

    setLoading(false);
  };

  const handleSearch = async (value) => {
    // 셀러 기반, 바이어 기반, 제품 기반, 카테고리 기반, 주문 상태 기반
    const params = {};
    if (orderStatus !== -1) params.statusCode = orderStatus;
    if (value !== '') {
      if (searchType === 'sellerId') params.sellerId = value;
      else if (searchType === 'buyerId') params.buyerId = value;
      else if (searchType === 'productId') params.productId = value;
    }

    // TODO : 추가적으로 item을 부르도록 수정해야 함.
    // 임시 코드
    params.limit = 3000;
    params.isAdmin = isAdmin;

    setLoading(true);

    const result = await AdminRepository.getOrderList(params);

    if (result) {
      setOrderListData(result.data);
    }

    setLoading(false);
  };

  async function updateOrderList(params) {
    setLoading(true);

    const result = await AdminRepository.getOrderList(params);

    if (result) {
      setOrderListData(result.data.filter((order) => order.statusCode !== 0));
    }

    setLoading(false);
  }

  const handleClickRefresh = () => {
    updateOrderList({ limit: 3000, isAdmin });
  };

  useEffect(() => {
    const params = { limit: 3000, isAdmin };
    updateOrderList(params);
  }, []);

  return (
    <div>
      <div className="flex flex-row justify-between items-center mb-2">
        <Search
          placeholder="input search text"
          allowClear
          onSearch={handleSearch}
          style={{ width: '40%', float: 'left' }}
        />
        <Radio.Group
          onChange={handleRadioChange}
          value={searchType}
          style={{ float: 'left', margin: '0px 10px' }}
        >
          <Radio value="sellerId">sellerId</Radio>
          <Radio value="buyerId">buyerId</Radio>
          <Radio value="productId">productId</Radio>
        </Radio.Group>
        <Select
          defaultValue={orderStatus}
          style={{ width: '15%', float: 'right' }}
          onChange={handleOrderStatusSelectChange}
        >
          <Option value={-1}>모두</Option>
          {Object.keys(orderStatusObj).map((status) => (
            <Option key={status} value={status}>
              {orderStatusObj[status]}
            </Option>
          ))}
        </Select>
        <Button onClick={handleClickRefresh}>새로고침</Button>
        <OrderUploadButton
          style={{ width: '15%', float: 'right' }}
          handleClickRefresh={handleClickRefresh}
        />
        <OrderDownloadButton
          orderList={orderListData}
          style={{ width: '15%', float: 'right' }}
        />
      </div>
      {!isLoading ? (
        <OrderTable
          orderList={orderListData}
          handleClickRefresh={handleClickRefresh}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default OrderListView;
