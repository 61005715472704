import React, { useEffect } from 'react';
import { Descriptions, Row, Col, Statistic, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import ReactHlsPlayer from 'react-hls-player';
import Spinner from '../organisms/Spinner';

function ReviewCard({ reviewData, isAdmin }) {
  const [modalVisible, setModalVisible] = React.useState(false);

  const [isDownloadLoading, setIsDownloadLoading] = React.useState(false);

  const showModal = () => {
    setModalVisible(true);
  };
  const handleModalOk = () => {
    setModalVisible(false);
  };
  const handleModalCancel = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    // console.log('reviewData', reviewData);
  }, []);

  return (
    <>
      <div style={{ display: 'table', width: '100%' }}>
        <div style={{ display: 'table-row', verticalAlign: 'middle' }}>
          <div
            style={{
              display: 'table-cell',
              textAlign: 'center',
              width: '200px',
            }}
          >
            {reviewData.thumbnailUrl ? (
              <img
                alt=""
                style={{
                  // borderRadius: "50%",
                  width: 150,
                  height: 150,
                }}
                src={reviewData.thumbnailUrl}
              />
            ) : (
              <img
                alt=""
                style={{
                  // borderRadius: "50%",
                  width: 150,
                  height: 150,
                }}
              />
            )}
            <div style={{ width: 150 }} className="font-semibold text-lg">
              {reviewData.author.name}
            </div>
          </div>
          <div
            style={{
              display: 'table-cell',
              textAlign: 'center',
              width: 'auto',
            }}
          >
            <Row gutter={16}>
              <Col span={6}>
                <Statistic title="조회수" value={reviewData.viewCount} />
              </Col>
              <Col span={6}>
                <Statistic
                  title="평가 점수 / 평가 횟수"
                  value={`${reviewData.g6AvgRatingScore} / ${reviewData.g6RatingList.length}`}
                />
              </Col>
              <Col span={6}>
                <Statistic
                  title="매출기여액"
                  value={reviewData.revenueAmount}
                />
              </Col>
              <Col span={6}>
                <Statistic
                  title="릴레이 리뷰"
                  value={reviewData.relayedVideoCount}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Descriptions bordered>
        {/* <Descriptions.Item label='ReviewId'>{reviewData.videoId}</Descriptions.Item> */}
        <Descriptions.Item label="제품" span={2}>
          {reviewData.linkedProduct.title}
        </Descriptions.Item>
        <Descriptions.Item label="리뷰 제목" span={3}>
          {reviewData.title}
        </Descriptions.Item>
        <Descriptions.Item label="리뷰 설명" span={3}>
          {reviewData.description}
        </Descriptions.Item>
        <Descriptions.Item label="업로드일" span={2}>
          {reviewData.createdAt}
        </Descriptions.Item>
        {/* <Descriptions.Item label='vtype'>{`${reviewData.vtype}`}</Descriptions.Item> */}
        <Descriptions.Item label="리뷰 영상" span={3}>
          <span
            style={{ cursor: 'pointer' }}
            onClick={showModal}
            className="text-blue-400 mr-4"
          >
            재생
          </span>
          {isAdmin ? (
            <>
              <a
                href={
                  window.location.origin.includes('localhost')
                    ? reviewData.videoPath
                    : `https://resource.greyd.app/${reviewData.videoPath}`
                }
                target="_blank"
                rel="noreferrer"
                className="mr-4"
                download={`${reviewData.author.name}-${reviewData.title}.mp4`}
              >
                다운로드
              </a>
              {/* <button
                className='text-giver'
                onClick={async () => {
                  setIsDownloadLoading(true);

                  try {
                    // const res = await fetch(`https://resource.greyd.app/${reviewData.videoPath}`);
                    const res = await fetch(reviewData.videoPath);
                    const blob = await res.blob();
                    const url = URL.createObjectURL(blob);

                    const anchor = document.createElement('a');
                    anchor.href = url;
                    anchor.download = `${reviewData.author.name}-${reviewData.title}.mp4`;
                    anchor.target = '_blank';
                    anchor.click();
                    anchor.remove();
                  } catch (err) {
                    console.error('review download error', err);
                    alert('리뷰 다운로드에 실패하였습니다.');
                  }

                  setIsDownloadLoading(false);
                }}
              >
                다운로드
              </button> */}
            </>
          ) : null}
        </Descriptions.Item>
        <Descriptions.Item label="리뷰 대표사진" span={3}>
          <a
            href={reviewData.thumbnailUrl}
            target="_blank"
            rel="noreferrer"
            className="mr-4"
          >
            새 창에서 보기
          </a>
        </Descriptions.Item>
        {reviewData.attachmentList && reviewData.attachmentList.length > 0 ? (
          <Descriptions.Item label="리뷰 추가 이미지" span={3}>
            {reviewData.attachmentList.map((attachment, idx) => (
              <a
                key={idx + 1}
                href={attachment.url}
                target="_blank"
                rel="noreferrer"
                className="rounded-lg p-2 bg-greyd-grey text-pioneer mr-1"
              >
                {idx + 1}
              </a>
            ))}
          </Descriptions.Item>
        ) : null}
      </Descriptions>
      <Modal
        title={reviewData.title}
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <ReactHlsPlayer
          src={
            window.location.origin.includes('localhost')
              ? reviewData.videoUrl.replace('https://resource.greyd.app', '')
              : reviewData.videoUrl
          }
          autoPlay
          controls
          width="100%"
          height="300px"
        />
      </Modal>
      {isDownloadLoading && <Spinner />}
    </>
  );
}

export default ReviewCard;
/*
{
    "linkedProduct": {
        "thumbnailUrl": "https://resource.greyd.app/productImage/1628128555718_7a619e4be15eeaee714bca6d075270ee.JPEG",
        "discountPrice": 19600,
        "discountRate": 0.32,
        "_id": "610a9ea6c48fa72335c536a0",
        "title": "리노베라 칼슘파우더 친환경 과일세정제 150g",
        "price": 29000,
        "productId": "610a9ea6c48fa72335c536a0",
        "ratingCount": 0,
        "id": "610a9ea6c48fa72335c536a0"
    },
    "ratingCountList": {
        "0": 0,
        "1": 0,
        "2": 0,
        "3": 0,
        "4": 0,
        "5": 0,
        "6": 0,
        "7": 0,
        "8": 0,
        "9": 0,
        "10": 2
    },
    "title": "Must have item for your kitchen💖",
    "description": "This fruit and veggies wash is great!!\n\nI soaked fruits and vegetables in water with the powder and moments later I could see pesticides and preservatives dissolved in the solution floating in the water. This is awesome.\n\nAnd I like it so much cause it's made of 100% natural ingredients. Easy to use, and reliable, No regret.\n\nI should have bought it earlier. I really had no idea that baking soda and vinegar are similar to water in effect..\n\nNow I can't eat any veggies and fruits without washing with this! I'm so glad I found this product. Higly recommended for every kitchen!",
    "viewCount": 89,
    "ratingScore": 10,
    "relayedVideoCount": 0,
    "_id": "6126f313065b3e7253b7e3c4",
    "author": {
        "profilePicUrl": "",
        "class": 0,
        "_id": "611c9faf7c30992bb5d10a4a",
        "name": "Joyce",
        "userId": "611c9faf7c30992bb5d10a4a",
        "ratingCount": 0,
        "id": "611c9faf7c30992bb5d10a4a"
    },
    "thumbnailUrl": "https://resource.greyd.app/videoThumbnail/1629942545941_3416f288ad7ff46a6982da002f4db8dc.jpg",
    "videoUrl": "https://resource.greyd.app/videos/6126f313065b3e7253b7e3c4/manifest.m3u8",
    "createdAt": "2021-08-26T01:49:07.823Z",
    "bookmarkList": [],
    "videoId": "6126f313065b3e7253b7e3c4",
    "ratingCount": 2,
    "id": "6126f313065b3e7253b7e3c4"
},
*/
