import { useEffect, useState } from 'react';

export default function InputText({
  label,
  inputId,
  placeholder,
  value = '',
  setValue = '',
  required = false,
  isValid,
  setIsValid = () => {},
  inputMode = 'text',
  customType,
}) {
  const [validText, setValidText] = useState('');

  useEffect(() => {
    if (required && !value && inputId !== 'shipmentCost') {
      setValidText('필수 입력 값입니다.');
      return;
    }

    setValidText('');
  }, [required, value]);

  return (
    <div className="w-full flex justify-between items-center mb-2">
      <label htmlFor={inputId} className="w-1/5 text-explorer font-semibold text-base">
        {label} <span className="text-giver align-middle">{required ? '*' : ''}</span>
      </label>
      <input
        id={inputId}
        type="text"
        inputMode={inputMode}
        placeholder={placeholder}
        className="w-3/5 rounded-lg py-1 px-3"
        value={value}
        onChange={async (e) => {
          const textValue = customType === 'price' ? e.target.value.trim() : e.target.value;

          if (customType === 'price' && Number.isNaN(+textValue)) {
            return alert('가격은 숫자만 입력 가능합니다. 숫자만 입력해주세요.');
          }

          setValue(textValue);

          if (textValue.trim()) {
            return setIsValid(true);
          }

          setIsValid(false);
        }}
      />
      <span className="ml-4 w-1/5 text-red-400">{validText}</span>
    </div>
  );
}
