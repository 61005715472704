import React, { useState } from 'react';
import { Radio } from 'antd';
import DualScrollView from '../molecules/DualScrollView';
import AdminRepository from '../repositories/AdminRepository';

const localeObject = {
  '국내 유저에게 전송': 'ko',
  '해외 유저에게 전송': 'en',
};

export default function PushNotificationView() {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [locale, setLocale] = useState('ko');

  React.useEffect(() => {
    // name
    // email
    // profilePicUrl
    // userfbToken
    AdminRepository.getSimplifiedUsers().then((result) => {
      if (result && result.success) {
        setUserList(result.users);
      }
    });
  }, []);

  const handleClickSend = async () => {
    if (!title) {
      return alert('제목을 입력해주세요!');
    }

    if (!body) {
      return alert('내용을 입력해주세요!');
    }

    if (
      !selectedUsers.length &&
      !window.confirm(
        '선택하지 않으면 전체유저에게 메시지를 보냅니다\n정말 전체유저에게 메시지를 보내시겠습니까?',
      )
    ) {
      return;
    }

    if (
      selectedUsers.length &&
      !window.confirm(
        `[${selectedUsers
          .map((selectedUser) => selectedUser.name)
          .join(', ')}]님에게 메시지를 보내시겠습니까?`,
      )
    ) {
      return;
    }

    const result = await AdminRepository.sendPushNotifications({
      message: { title, body },
      users: selectedUsers.map(({ _id }) => _id),
      locale,
    });

    if (result && result.success) {
      const { totalCount, successCount, failureCount } = result;

      alert(
        `전체 메시지: ${totalCount}개\n성공: ${successCount}개\n실패: ${failureCount}개`,
      );
    }
  };

  return (
    <div className="bg-greyd-grey p-10 rounded-lg">
      <h1 className="text-pioneer font-bold text-lg mb-4">푸시 메시지 전송</h1>
      <div className="flex flex-col w-1/2">
        <label
          htmlFor="title"
          className="text-explorer font-semibold text-base "
        >
          제목
        </label>
        <input
          id="title"
          type="text"
          placeholder="제목을 입력해주세요"
          className="mb-4 px-4 py-2 rounded-sm"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <label
          htmlFor="body"
          className="text-explorer font-semibold text-base "
        >
          내용
        </label>
        <textarea
          id="body"
          type="text"
          placeholder="내용을 입력해주세요"
          className="px-4 py-2 rounded-sm"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          rows={3}
        />
      </div>

      <DualScrollView
        userList={userList}
        usersPerPage={20}
        pageCountPerScreen={8}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />

      {!selectedUsers.length ? (
        <div className="bg-white mt-4 flex justify-center py-4 rounded-lg">
          <Radio.Group
            defaultValue="국내 유저에게 전송"
            options={['국내 유저에게 전송', '해외 유저에게 전송']}
            onChange={(e) => {
              setLocale(localeObject[e.target.value]);
            }}
          />
        </div>
      ) : null}

      <button
        type="button"
        onClick={() => handleClickSend()}
        className="mt-10 bg-giver rounded-sm px-4 py-2 font-semibold"
      >
        전송
      </button>
    </div>
  );
}
