import React from 'react';
import { Button } from 'antd';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { orderStatus } from '../constant';

const columns = [
  {
    header: '주문일시',
    key: 'createdAt',
    width: 27,
  },
  {
    header: '주문번호',
    key: 'id',
    width: 28,
  },
  {
    header: '주문상품',
    key: 'productTitle',
    width: 76,
    // render: record=><span>{record.product.title}</span>,
  },
  {
    header: '주문자',
    key: 'buyerName',
    width: 13,
    // render: record=><span>{record.buyer.name}</span>,
  },
  {
    header: '받는분',
    key: 'receiverName',
    width: 13,
  },
  {
    header: '주소',
    key: 'address',
    width: 76,
  },
  {
    header: '받는분 번호',
    key: 'receiverPhone',
    width: 20,
  },
  {
    header: '상품금액',
    key: 'totalPrice',
    width: 9,
  },
  {
    header: '배송비',
    key: 'shipmentCost',
    width: 9,
  },
  {
    header: '할인코드',
    key: 'discountCode',
    width: 16,
  },
  {
    header: '할인코드 할인율',
    key: 'percentage',
    width: 16,
  },
  {
    header: '할인코드 할인액',
    key: 'promotionDiscount',
    width: 16,
  },
  {
    header: '리워드 사용',
    key: 'rewardUse',
    width: 16,
  },
  {
    header: '최종결제 금액',
    key: 'finalPrice',
    width: 16,
  },
  {
    header: '주문상태',
    key: 'orderStatus',
    width: 20,
    // render: record=><span>{orderStatus.get(record.statusCode)}</span>,
  },
  { header: '택배사', key: 'shipmentCourierCO', width: 20 },
  { header: '송장번호', key: 'shipmentCourierNO', width: 20 },
];

function OrderDownloadButton(props) {
  const handleDownloadSheet = async () => {
    // productListData
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('주문 목록'); // sheet 이름이 My Sheet // sheet 데이터 설정
    worksheet.columns = columns;
    props.orderList.map((record, index) => {
      let productNames = '';
      record.cartItems.forEach(({ product }, idx, arr) => {
        if (arr.length - 1 > idx) {
          productNames += `${product.title}, `;
        } else {
          productNames += product.title;
        }
      });

      if (record.rewardUse > 0 || record.promotionDiscount) {
        console.log(record);
      }

      if (record.shipmentCost > 0) {
        worksheet.addRow({
          createdAt: record.createdAt.replace('T', ' ').replace('Z', ''),
          id: record.id,
          // productTitle: `${record.cartItems[0]?.product.title} ${
          //   record.cartItems.length - 1 > 0 ? `외 ${record.cartItems.length - 1}` : ''
          // }`,
          productTitle: productNames,
          buyerName: `${record.buyerName}(${record.buyer?.name})`,
          receiverName: record.receiverName,
          totalPrice: record.totalPrice - record.shipmentCost,
          shipmentCost: record.shipmentCost,
          orderStatus: orderStatus.get(record.statusCode),
          shipmentCourierCO: record.shipmentCourierCO,
          shipmentCourierNO: record.shipmentCourierNO,
          address: record.address,
          receiverPhone: record.receiverPhone,
          discountCode: record.discountCode?.code,
          percentage: record.discountCode?.percentage,
          rewardUse: record.rewardUse,
          promotionDiscount: record.promotionDiscount,
          finalPrice:
            record.totalPrice - record.rewardUse - record.promotionDiscount,
          // + record.shipmentCost,
        });
      }
    });

    const mimeType = {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);

    const today = new Date();
    const year = today.getFullYear();
    const month = `0${today.getMonth() + 1}`.slice(-2);
    const day = `0${today.getDate()}`.slice(-2);

    const dateString = year + month + day;
    saveAs(blob, `주문목록-${dateString}.xlsx`);
  };
  return <Button onClick={handleDownloadSheet}>다운로드</Button>;
}

export default OrderDownloadButton;
