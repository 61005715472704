import { ClearOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useRef } from 'react';
import CustomModal from './Modal';

import { orderStatus, WITHDRAWAL_REWARD_STATUS_CODE } from '../constant';
import ProductModify from '../organisms/ProductModifyView';
import AdminRepository from '../repositories/AdminRepository';
import OrderAccept from './OrderAccept';
import OrderInvoiceInput from './OrderInvoiceInput';
import OrderInvoiceUpdate from './OrderInvoiceUpdate';

function ItemManageButton(props) {
  const { confirm } = Modal;

  function showDeleteConfirm() {
    confirm({
      title: '정말 삭제하시겠습니까?', // Are you sure delete this record?
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK');

        const { record } = props;
        switch (props.type) {
          case 'review':
            AdminRepository.deleteReview(record.id);
            props.onSuccess(record);
            break;
          case 'product':
            AdminRepository.getProduct(record.id).then((result) => {
              const params = {
                requesterId: props.isAdmin
                  ? AdminRepository.requesterId
                  : result.data.seller.id,
              };
              AdminRepository.deleteProduct(record.id, params).then(() => {
                props.onSuccess(record);
              });
            });
            break;
          case 'order':
            AdminRepository.deleteOrder(record.id);
            props.onSuccess(record);
            break;
          default:
            console.log('예외 케이스');
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  function updateWithdrawalStatus({ status: { key, value } }) {
    confirm({
      title: `${value} 상태로 변경하시겠습니까?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        console.log('OK');

        const { record } = props;
        console.log('oonok record', record);

        const result = await AdminRepository.updateWithdrawalStatus({
          userId: record.userId._id,
          status: key,
          requestId: record._id,
        });
        console.log('result', result);

        props.onSuccess(record);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const ItemManageButtonRef = useRef({});

  if (props.type === 'product') {
    return (
      <div className="flex flex-col">
        <CustomModal
          title="수정"
          tailwindcss="rounded-lg bg-orange-400 text-pioneer font-semibold px-4 py-2 mb-2 disabled:bg-operator disabled:text-pioneer"
          handleSubmit={() => ItemManageButtonRef.current.handleSubmit()}
        >
          <ProductModify
            ref={ItemManageButtonRef}
            product={props.record}
            refreshProduct={props.refreshProduct}
            KRWPerUSD={props.KRWPerUSD}
            isAdmin={props.isAdmin}
          />
        </CustomModal>
        <button
          onClick={() => showDeleteConfirm()}
          className="rounded-lg bg-orange-400 text-pioneer font-semibold px-4 py-2 disabled:bg-operator disabled:text-pioneer"
        >
          삭제
        </button>
      </div>
    );
  }

  if (props.type === 'order') {
    if (orderStatus.get(props.record.statusCode) === '결제 완료 및 미접수') {
      return (
        <div className="flex flex-col">
          <CustomModal
            title="주문 수락"
            tailwindcss="rounded-lg bg-orange-400 text-pioneer font-semibold px-4 py-2 mb-2 disabled:bg-operator disabled:text-pioneer"
            pageType={props.type}
          >
            <OrderAccept
              order={props.record}
              updateOrderList={props.handleClickRefresh}
            />
          </CustomModal>
        </div>
      );
    }
    if (
      orderStatus.get(props.record.statusCode) === '접수 완료 및 발송 준비중'
    ) {
      return (
        <div className="flex flex-col">
          <CustomModal
            title="송장 입력"
            tailwindcss="rounded-lg bg-orange-400 text-pioneer font-semibold px-4 py-2 mb-2 disabled:bg-operator disabled:text-pioneer"
            pageType={props.type}
          >
            <OrderInvoiceInput
              order={props.record}
              updateOrderList={props.handleClickRefresh}
            />
          </CustomModal>
        </div>
      );
    }
    if (orderStatus.get(props.record.statusCode) === '발송중') {
      return (
        <div className="flex flex-col">
          <CustomModal
            title="배송 수정"
            tailwindcss="rounded-lg bg-orange-400 text-pioneer font-semibold px-4 py-2 mb-2 disabled:bg-operator disabled:text-pioneer"
            pageType={props.type}
          >
            <OrderInvoiceUpdate
              order={props.record}
              updateOrderList={props.handleClickRefresh}
            />
          </CustomModal>
        </div>
      );
    }
    if (orderStatus.get(props.record.statusCode) === '배송완료') {
      return <></>;
    }
    if (orderStatus.get(props.record.statusCode) === '구매완료') {
      return <></>;
    }

    return <div />;
  }

  if (props.type === 'review' && props.isAdmin) {
    return (
      <button
        onClick={() => showDeleteConfirm()}
        className="rounded-lg bg-orange-400 text-pioneer font-semibold px-4 py-2 disabled:bg-operator disabled:text-pioneer"
      >
        삭제
      </button>
    );
  }

  if (props.type === 'withdrawalReward') {
    if (props.record.status === WITHDRAWAL_REWARD_STATUS_CODE.REQUESTED.key) {
      return (
        <div className="flex flex-col">
          <button
            onClick={() =>
              updateWithdrawalStatus({
                status: WITHDRAWAL_REWARD_STATUS_CODE.ACCEPTED,
              })}
            className="rounded-lg bg-orange-400 text-pioneer font-semibold px-4 py-2 disabled:bg-operator disabled:text-pioneer"
          >
            승인
          </button>
          <button
            onClick={() =>
              updateWithdrawalStatus({
                status: WITHDRAWAL_REWARD_STATUS_CODE.REJECTED,
              })}
            className="rounded-lg bg-orange-400 text-pioneer font-semibold px-4 py-2 disabled:bg-operator disabled:text-pioneer"
          >
            반려
          </button>
        </div>
      );
    }
    if (props.record.status === WITHDRAWAL_REWARD_STATUS_CODE.ACCEPTED.key) {
      return (
        <button
          onClick={() =>
            updateWithdrawalStatus({
              status: WITHDRAWAL_REWARD_STATUS_CODE.FINISHED,
            })}
          className="rounded-lg bg-orange-400 text-pioneer font-semibold px-4 py-2 disabled:bg-operator disabled:text-pioneer"
        >
          출금완료 확정
        </button>
      );
    }
    if (props.record.status === WITHDRAWAL_REWARD_STATUS_CODE.REJECTED.key) {
      return <></>;
    }
    if (props.record.status === WITHDRAWAL_REWARD_STATUS_CODE.FINISHED.key) {
      return <></>;
    }
  }

  return (
    <Button
      disabled={props.disable ? props.disable : true}
      onClick={showDeleteConfirm}
    >
      <ClearOutlined />
      Delete
    </Button>
  );
}

export default ItemManageButton;
