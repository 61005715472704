import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import AdminRepository, { API_ROOT_URL } from '../repositories/AdminRepository';
import { debounce } from '../utils';

// DIGITAL_FINANCE
// SERVICE
// PRIVACY_POLICY
// PRIVACY_POLICY_FOR_SELLER

const placeholders = {
  INPUT_BUSINESS_CERTIFICATION_NO: '사업자 등록번호를 입력해주세요',
  ATTACH_BUSINESS_CERTIFICATION_IMAGE: '사업자 등록증을 첨부해주세요',
  INPUT_BUSINESS_NAME: '법인명 또는 상호명을 등록해주세요.',
  INPUT_BUSINESS_ADDRESS: '업체주소를 등록해주세요.',
  INPUT_BUSINESS_MANAGER_NAME: '담당자 이름을 등록해주세요.',
  INPUT_BRAND_NAME: '브랜드 이름을 등록해주세요.',
  INPUT_BUSINESS_HOMEPAGE: '홈페이지 주소를 등록해주세요.',
  INPUT_INSTAGRAM_ID: '인스타그램 아이디를 입력해주세요',
  SELECT_BANK: '은행명을 선택해주세요.',
  SELLER_BANK_ACCOUNT_GUIDE: '계좌번호를 정확하게 입력해주세요.',
  SELLER_BANK_ACCOUNT_NAME_GUIDE: '예금주를 정확하게 입력해주세요.',
  SELLER_EMAIL_GUIDE: '구매 알림을 받을 이메일을 입력해주세요.',
  SELLER_PHONE_GUIDE: '구매 관련하여 문의받을 연락처를 입력해주세요.',
  SELLER_BANKBOOK_COPY: '통장사본을 첨부해주세요',
  SELLER_TELEMARKETING_BUSINESS: '통신판매업신고증을 첨부해주세요',
};

const termsOfServiceString = {
  serviceCheck: '회원 약관 동의',
  digitalFinanceCheck: '전자 금융서비스 이용약관 동의',
  privacyPolicyCheck: '개인정보수집 및 이용 동의',
  sellerCheck: '판매회원 약관 동의',
  privacyPolicyForSellerCheck: '판매회원의 개인정보준수사항 동의',
};

const termsOfServiceUrls = {
  serviceCheck: 'https://greyd.app/static/service_terms_kor.txt', // 회원약관
  digitalFinanceCheck: 'https://greyd.app/static/terms_digital_finance_kor.txt', // 전자 금융서비스
  privacyPolicyCheck: 'https://greyd.app/static/terms_privacy_policy_kor.txt', // 개인정보수집 및 이용 동의
  sellerCheck: 'https://greyd.app/static/terms_for_seller_kor.txt', // 판매회원 약관 동의
  privacyPolicyForSellerCheck:
    'https://greyd.app/static/privacy_policy_of_seller_kor.txt', // 판매회원의 개인정보준수사항 동의
};

const bankList = {
  keb: 'KEB하나은행', // BANK_KEB
  sc: 'SC제일은행', // BANK_SC
  kb: '국민은행', // BANK_KB
  shinhan: '신한은행', // BANK_SHINHAN
  woori: '우리은행', // BANK_WOORI
  citi: '시티은행', // BANK_CITI
  ibk: '기업은행', // BANK_IBK
  nh: '농협은행', // BANK_NH
  suhyup: '수협은행', // BANK_SUHYUP
  etc: '기타은행', // BANK_ETC
};

const debouncedCheckId = debounce(async (value, setIsValid) => {
  const result = await axios.get(
    `${API_ROOT_URL}/users/duplicate?name=${value}`,
  );

  if (result && !result.data.success) {
    setIsValid(false);
  } else {
    setIsValid(true);
  }
}, 300);

const debouncedCheckEmail = debounce(async (value, setIsValid) => {
  if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) {
    setIsValid(true);
  } else {
    setIsValid(false);
  }
}, 300);

const debounedCheckValid = debounce((value, setValidText) => {
  if (!value.trim()) {
    setValidText('필수 입력값입니다.');
  } else {
    setValidText('');
  }
}, 100);

const debounedCheckValidId = debounce((value, isValid, setValidText) => {
  if (!isValid) {
    return setValidText(value ? '중복된 아이디입니다.' : '필수 입력값입니다.');
  }

  setValidText('');
}, 100);

const debounedCheckValidEmail = debounce((isValid, setValidText) => {
  if (!isValid) {
    return setValidText('올바른 이메일 형식을 입력해주세요.');
  }

  setValidText('');
}, 100);

function InputText({
  label,
  inputId,
  placeholder,
  value = '',
  setValue = '',
  required = false,
  isValid,
  setIsValid = () => {},
}) {
  const [validText, setValidText] = useState('');

  useEffect(() => {
    if (required && !value.trim()) {
      return setValidText('필수 입력값입니다.');
    }
  }, []);

  useEffect(() => {
    if (!required) {
      return;
    }

    if (inputId === 'name' && !/^[0-9a-zA-Z_.]*$/.test(value)) {
      return setValidText('특수문자는 불가능합니다.');
    }

    if (inputId === 'name' && !isValid) {
      return debounedCheckValidId(value, isValid, setValidText);
    }

    if ((inputId === 'email' || inputId === 'sellerEmail') && !isValid) {
      return debounedCheckValidEmail(isValid, setValidText);
    }

    debounedCheckValid(value, setValidText);
  }, [inputId, isValid, required, setIsValid, value]);

  return (
    <div className="w-4/5 flex justify-between items-center mb-2">
      <label
        htmlFor={inputId}
        className="w-1/5 text-explorer font-semibold text-base"
      >
        {label}{' '}
        <span className="text-giver align-middle">{required ? '*' : ''}</span>
      </label>
      <input
        id={inputId}
        type="text"
        placeholder={placeholder}
        className="w-3/5 rounded-lg py-1 px-3"
        value={value}
        onChange={async (e) => {
          setValue(e.target.value);

          if (inputId === 'name') {
            return debouncedCheckId(e.target.value, setIsValid, setValidText);
          }

          if (inputId === 'email' || inputId === 'sellerEmail') {
            return debouncedCheckEmail(e.target.value, setIsValid);
          }

          if (e.target.value.trim()) {
            return setIsValid(true);
          }

          setIsValid(false);
        }}
      />
      <span className="ml-4 w-1/5 text-red-400">{validText}</span>
    </div>
  );
}

function InputFile({
  label = '업로드',
  inputId,
  placeholder,
  value = '',
  setValue,
  required = false,
  isValid,
  setIsValid = () => {},
}) {
  const [fileName, setFileName] = useState('');
  const [validText, setValidText] = useState('');

  useEffect(() => {
    if (required && !value) {
      return setValidText('필수 첨부값입니다.');
    }

    setValidText('');
  }, [required, value]);

  return (
    <div className="w-4/5 flex justify-between items-center mb-2">
      <label htmlFor="" className="w-1/5 text-explorer font-semibold text-base">
        {label}{' '}
        <span className="text-giver align-middle">{required ? '*' : ''}</span>
      </label>
      <label
        htmlFor={inputId}
        className="w-3/5 text-center font-bold text-giver rounded-lg border border-pioneer cursor-pointer"
      >
        {fileName || placeholder}
      </label>
      <input
        id={inputId}
        type="file"
        accept="image/*"
        className="hidden"
        onChange={(e) => {
          setValue(e.target.files[0]);
          setFileName(e.target.files[0].name);
          setIsValid(true);
        }}
      />
      <div className="ml-4 w-1/5 text-red-400">{isValid ? '' : validText}</div>
    </div>
  );
}

function InputSelect({
  label,
  inputId,
  placeholder,
  value = '',
  setValue,
  required = false,
  list,
  isValid,
  setIsValid,
}) {
  const [validText, setValidText] = useState('');

  useEffect(() => {
    if (required && !isValid) {
      return setValidText('필수 선택값입니다.');
    }

    setValidText('');
  }, [required, isValid]);

  return (
    <div className="w-4/5 flex justify-between items-center mb-2">
      <label
        htmlFor={inputId}
        className="w-1/5 text-explorer font-semibold text-base"
      >
        {label}{' '}
        <span className="text-giver align-middle">{required ? '*' : ''}</span>
      </label>
      <select
        id={inputId}
        className="w-3/5 text-center rounded-lg bg-operator text-giver font-semibold"
        onChange={(e) => {
          if (e.target.value) {
            setValue(e.target.value);
            setIsValid(true);
            return;
          }

          setIsValid(false);
        }}
      >
        <option key="default" value="">
          은행을 선택해주세요
        </option>
        {Object.keys(list).map((bankCode) => (
          <option key={bankCode} value={list[bankCode]}>
            {list[bankCode]}
          </option>
        ))}
      </select>
      <div className="ml-4 w-1/5 text-red-400">{isValid ? '' : validText}</div>
    </div>
  );
}

export default function Enroll({ authType, authUser, setAuthorized, setUser }) {
  const [termsOfServices, setTermsOfServices] = useState([]);
  const [checkInfo, setCheckInfo] = useState({
    digitalFinanceCheck: false,
    serviceCheck: false,
    privacyPolicyCheck: false,
    privacyPolicyForSellerCheck: false,
    sellerCheck: false,
  });
  const [allChecked, setAllChecked] = useState(false);
  const [allAgreed, setAllAgreed] = useState(false);

  const [name, setName] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [email, setEmail] = useState(
    authType === 'kakao' ? authUser?.kakao_account?.email : '',
  );
  const [certificationNo, setCertificationNo] = useState('');
  const [certificationUrl, setCertificationUrl] = useState('');
  const [bankName, setBankName] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [bankAccountHolder, setBankAccountHolder] = useState('');
  const [sellerEmail, setSellerEmail] = useState('');
  const [sellerPhone, setSellerPhone] = useState('');
  const [sellerManagerName, setSellerManagerName] = useState('');
  const [sellerBusinessName, setSellerBusinessName] = useState('');
  const [sellerBrandName, setSellerBrandName] = useState('');
  const [sellerHomepage, setSellerHomepage] = useState('');
  const [sellerOfficeAddress, setSellerOfficeAddress] = useState('');
  const [sellerBankbookCopyUrl, setSellerBankbookCopyUrl] = useState('');
  const [sellerTelemarketingBusinessUrl, setSellerTelemarketingBusinessUrl] =
    useState('');
  const [instagramId, setInstagramId] = useState('');

  const [isValidName, setIsValidName] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(!!email);
  const [isValidCertificateNo, setIsValidCertificateNo] = useState(false);
  const [isValidCertificateUrl, setIsValidCertificateUrl] = useState(false);
  const [isValidBusinessName, setIsValidBusinessName] = useState(false);
  const [isValidBankName, setIsValidBankName] = useState(false);
  const [isValidBankAccount, setIsValidBankAccount] = useState(false);
  const [isValidBankAccountHolder, setIsValidBankAccountHolder] =
    useState(false);
  const [isValidManagerName, setIsValidManagerName] = useState(false);
  const [isValidSellerEmail, setIsValidSellerEmail] = useState(false);
  const [isValidSellerPhone, setIsValidSellerPhone] = useState(false);
  const [isValidsellerBankbookCopyUrl, setIsValidsellerBankbookCopyUrl] =
    useState(false);

  useEffect(() => {
    Promise.all(
      Object.keys(termsOfServiceUrls).map((key) =>
        axios.get(termsOfServiceUrls[key]),
      ),
    ).then((result) => {
      setTermsOfServices(result.map((res) => res.data));
    });
  }, []);

  useEffect(() => {
    if (
      checkInfo.digitalFinanceCheck &&
      checkInfo.serviceCheck &&
      checkInfo.privacyPolicyCheck &&
      checkInfo.privacyPolicyForSellerCheck &&
      checkInfo.sellerCheck
    ) {
      setAllChecked(true);
      return setAllAgreed(true);
    }

    setAllChecked(false);
    return setAllAgreed(false);
  }, [allChecked, checkInfo]);

  const handleClick = (idx) => {
    setCheckInfo({
      ...checkInfo,
      [Object.keys(termsOfServiceUrls)[idx]]:
        !checkInfo[Object.keys(termsOfServiceUrls)[idx]],
    });
  };

  const handleSubmit = () => {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('introduction', introduction);
    formData.append('email', email);
    formData.append('sellerCertificationNo', certificationNo);
    formData.append('sellerCertificationUrl', certificationUrl);
    formData.append('sellerBankName', bankName);
    formData.append('sellerBankAccount', bankAccount);
    formData.append('sellerBankAccountHolder', bankAccountHolder);
    formData.append('sellerManagerEmail', sellerEmail);
    formData.append('sellerManagerContact', sellerPhone);
    formData.append('sellerManagerName', sellerManagerName);
    formData.append('sellerBusinessName', sellerBusinessName);
    formData.append('sellerBrandName', sellerBrandName);
    formData.append('sellerHomepage', sellerHomepage);
    formData.append('instagramId', instagramId);
    formData.append('sellerOfficeAddress', sellerOfficeAddress);
    formData.append('sellerBankbookCopyUrl', sellerBankbookCopyUrl);
    formData.append(
      'sellerTelemarketingBusinessUrl',
      sellerTelemarketingBusinessUrl,
    );
    formData.append('statusCode', 0);
    formData.append('authType', authType);
    formData.append('sellerStatus', 'APPROVAL_REQUEST');
    formData.append('isSeller', true);

    // console.log(authType, authUser);

    if (authType === 'kakao') {
      formData.append(
        'authData',
        JSON.stringify({ id: authUser.id, accessToken: authUser.access_token }),
      );
    } else {
      formData.append(
        'authData',
        JSON.stringify({
          ...authUser,
          requesterToken: null,
          localToken: null,
          token: null,
        }),
      );
    }

    // 240203 TODO: sendRequestApprovalSellerAfterEnroll
    axios
      .post(`${API_ROOT_URL}/users/seller`, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res && res.data.success) {
          localStorage.setItem('id', res.data.seller.userId);
          localStorage.setItem('token', authUser.localToken);
          AdminRepository.setRequester(authUser.token, res.data.seller.userId);

          //

          axios.post(
            `${API_ROOT_URL}/users/seller`,
            {
              name,
              email,
              bankAccount,
              bankAccountHolder,
              bankName,
              sellerBrandName,
              sellerBusinessName,
              certificationNo,
              sellerHomepage,
              sellerEmail,
              sellerManagerName,
              sellerPhone,
              sellerOfficeAddress,
              certificationUrl,
            },
            {
              headers: {
                Accept: 'application/json',
              },
            },
          );

          //

          setUser(res.data.seller);
          setAuthorized(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          const { errors } = error.response.data;

          const errorObject = errors[Object.keys(errors)[0]];

          alert(
            `셀러 회원 등록에 실패했습니다. 관리자에게 에러내용을 첨부하여 문의해주세요.\n[에러내용: ${errorObject.name} - ${errorObject.kind} (${errorObject.path}) ]`,
          );
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  };

  return (
    <div className="bg-greyd-grey h-full w-full flex flex-col items-center">
      <section className="bg-greyd-grey h-full w-full flex flex-col items-center">
        <h1 className="text-white text-2xl font-bold mt-3">회원가입 약관</h1>
        <div className="w-full h-screen flex flex-col items-center">
          {termsOfServices &&
            termsOfServices.map((termsOfServices, idx) => (
              <Fragment key={Object.keys(termsOfServiceUrls)[idx]}>
                <div
                  className="flex flex-row w-8/12 items-center justify-between cursor-pointer mb-2"
                  onClick={() => handleClick(idx)}
                >
                  <span className="text-gray-300 text-base font-semibold">
                    {termsOfServiceString[Object.keys(termsOfServiceUrls)[idx]]}
                  </span>
                  <input
                    readOnly
                    type="checkbox"
                    checked={checkInfo[Object.keys(termsOfServiceUrls)[idx]]}
                    className="cursor-pointer accent-giver w-4 h-4 relative rounded-full"
                  />
                </div>
                <textarea
                  disabled
                  className="w-8/12 justify-center items-center mb-4 p-2 rounded-lg text-gray-200 resize-none overflow-auto"
                  rows={4}
                  value={termsOfServices}
                />
              </Fragment>
            ))}
          <div
            className="flex flex-row w-8/12 items-center justify-between cursor-pointer"
            onClick={() => {
              setAllChecked(!allChecked);
              setCheckInfo({
                digitalFinanceCheck: !allChecked,
                serviceCheck: !allChecked,
                privacyPolicyCheck: !allChecked,
                privacyPolicyForSellerCheck: !allChecked,
                sellerCheck: !allChecked,
              });
            }}
          >
            <span className="text-gray-300 items-center text-lg font-bold">
              이용약관, 개인정보 수집 및 이용에 모두 동의합니다.
            </span>
            <input
              readOnly
              type="checkbox"
              checked={allChecked}
              className="accent-giver w-4 h-4"
            />
          </div>
        </div>
      </section>

      <section className="bg-greyd-grey h-full w-full flex flex-col items-center justify-evenly pt-16">
        <hr className="bg-slate-100 w-8/12" />
        <h1 className="text-white text-2xl font-bold pt-3">정보입력</h1>

        <div className="mb-10">
          <span className="text-giver font-semibold text-base">
            추후 관리를 위해 회사 대표계정으로 계정 생성을 권장합니다.
          </span>
        </div>

        <InputText
          label="사용자 아이디"
          placeholder="3~16자의 영문 또는 숫자, 특수기호(_, .)로 입력해주세요"
          inputId="name"
          required
          value={name}
          setValue={setName}
          isValid={isValidName}
          setIsValid={setIsValidName}
        />

        <InputText
          label="프로필 설명"
          placeholder="회사 / 브랜드에 대한 설명을 간략히 입력해주세요."
          inputId="introduction"
          value={introduction}
          setValue={setIntroduction}
        />

        <InputText
          label="이메일"
          placeholder="이메일을 입력해주세요"
          inputId="email"
          required
          value={email}
          setValue={setEmail}
          isValid={isValidEmail}
          setIsValid={setIsValidEmail}
        />

        <InputText
          label="사업자 등록번호"
          placeholder="ex) 123-45-67890"
          inputId="certificationNo"
          required
          value={certificationNo}
          setValue={setCertificationNo}
          isValid={isValidCertificateNo}
          setIsValid={setIsValidCertificateNo}
        />

        <InputFile
          label="사업자 등록증"
          className="text-artisan"
          placeholder={placeholders.ATTACH_BUSINESS_CERTIFICATION_IMAGE}
          inputId="certificationUrl"
          required
          value={certificationUrl}
          setValue={setCertificationUrl}
          isValid={isValidCertificateUrl}
          setIsValid={setIsValidCertificateUrl}
        />

        <InputText
          label="상호명"
          placeholder={placeholders.INPUT_BUSINESS_NAME}
          inputId="sellerBusinessName"
          required
          value={sellerBusinessName}
          setValue={setSellerBusinessName}
          isValid={isValidBusinessName}
          setIsValid={setIsValidBusinessName}
        />

        <InputText
          label="브랜드명"
          placeholder={placeholders.INPUT_BRAND_NAME}
          inputId="sellerBrandName"
          value={sellerBrandName}
          setValue={setSellerBrandName}
        />

        <InputSelect
          label="은행명"
          placeholder={placeholders.SELECT_BANK}
          inputId="bankName"
          list={bankList}
          required
          value={bankName}
          setValue={setBankName}
          isValid={isValidBankName}
          setIsValid={setIsValidBankName}
        />

        <InputText
          label="계좌번호"
          placeholder={placeholders.SELLER_BANK_ACCOUNT_GUIDE}
          inputId="bankAccount"
          required
          value={bankAccount}
          setValue={setBankAccount}
          isValid={isValidBankAccount}
          setIsValid={setIsValidBankAccount}
        />

        <InputText
          label="예금주"
          placeholder={placeholders.SELLER_BANK_ACCOUNT_NAME_GUIDE}
          inputId="bankAccountHolder"
          required
          value={bankAccountHolder}
          setValue={setBankAccountHolder}
          isValid={isValidBankAccountHolder}
          setIsValid={setIsValidBankAccountHolder}
        />

        <InputText
          label="담당자명"
          placeholder={placeholders.INPUT_BUSINESS_MANAGER_NAME}
          inputId="sellerManagerName"
          required
          value={sellerManagerName}
          setValue={setSellerManagerName}
          isValid={isValidManagerName}
          setIsValid={setIsValidManagerName}
        />

        <InputText
          label="판매자 이메일"
          placeholder={placeholders.SELLER_EMAIL_GUIDE}
          inputId="sellerEmail"
          required
          value={sellerEmail}
          setValue={setSellerEmail}
          isValid={isValidSellerEmail}
          setIsValid={setIsValidSellerEmail}
        />

        <InputText
          label="판매자 연락처"
          placeholder={placeholders.SELLER_PHONE_GUIDE}
          inputId="sellerPhone"
          required
          value={sellerPhone}
          setValue={setSellerPhone}
          isValid={isValidSellerPhone}
          setIsValid={setIsValidSellerPhone}
        />

        <InputText
          label="공식 홈페이지 주소"
          placeholder={placeholders.INPUT_BUSINESS_HOMEPAGE}
          inputId="sellerHomepage"
          value={sellerHomepage}
          setValue={setSellerHomepage}
        />

        <InputText
          label="업체 주소"
          placeholder={placeholders.INPUT_BUSINESS_ADDRESS}
          inputId="sellerOfficeAddress"
          value={sellerOfficeAddress}
          setValue={setSellerOfficeAddress}
        />

        <InputText
          label="인스타그램 아이디"
          placeholder={placeholders.INPUT_INSTAGRAM_ID}
          inputId="instagramId"
          value={instagramId}
          setValue={setInstagramId}
        />

        <InputFile
          label="통장사본"
          placeholder={placeholders.SELLER_BANKBOOK_COPY}
          inputId="sellerBankbookCopyUrl"
          required
          value={sellerBankbookCopyUrl}
          setValue={setSellerBankbookCopyUrl}
          isValid={isValidsellerBankbookCopyUrl}
          setIsValid={setIsValidsellerBankbookCopyUrl}
        />

        <InputFile
          label="통신판매업신고증"
          placeholder={placeholders.SELLER_TELEMARKETING_BUSINESS}
          inputId="sellerTelemarketingBusinessUrl"
          value={sellerTelemarketingBusinessUrl}
          setValue={setSellerTelemarketingBusinessUrl}
        />

        <button
          className="text-giver font-bold text-lg px-6 py-1 rounded-md border-0 bg-operator mt-12 mb-10"
          onClick={() => {
            if (!allAgreed) {
              return alert('약관동의는 필수입니다.');
            }

            if (
              !(
                isValidName &&
                isValidEmail &&
                isValidCertificateNo &&
                isValidCertificateUrl &&
                isValidBusinessName &&
                isValidBankName &&
                isValidBankAccount &&
                isValidBankAccountHolder &&
                isValidManagerName &&
                isValidSellerEmail &&
                isValidSellerPhone &&
                isValidsellerBankbookCopyUrl
              )
            ) {
              // console.log('isValidName', isValidName);
              // console.log('isValidEmail', isValidEmail);
              // console.log('isValidCertificateNo', isValidCertificateNo);
              // console.log('isValidCertificateUrl', isValidCertificateUrl);
              // console.log('isValidBusinessName', isValidBusinessName);
              // console.log('isValidBankName', isValidBankName);
              // console.log('isValidBankAccount', isValidBankAccount);
              // console.log('isValidBankAccountHolder', isValidBankAccountHolder);
              // console.log('isValidManagerName', isValidManagerName);
              // console.log('isValidSellerEmail', isValidSellerEmail);
              // console.log('isValidSellerPhone', isValidSellerPhone);
              // console.log('isValidsellerBankbookCopyUrl', isValidsellerBankbookCopyUrl);
              return alert('필수 항목이 누락되었습니다.');
            }
            handleSubmit();
          }}
        >
          계정 만들기
        </button>
      </section>
    </div>
  );
}
