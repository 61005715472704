import React from 'react';
import { Descriptions } from 'antd';
import { getFormattedDate } from '../utils';
import { orderStatus } from '../constant';

function OrderCard(props) {
  const { orderData } = props;

  return (
    <Descriptions bordered>
      <Descriptions.Item label="주문번호">
        {orderData.orderId}
      </Descriptions.Item>
      <Descriptions.Item label="주문상품" span={2}>
        {/* {`${orderData.cartItems[0]?.product.title} ${
          orderData.cartItems.length - 1 > 0 ? `외 ${orderData.cartItems.length - 1}` : ''
        }`} */}
        <ol>
          {orderData.cartItems &&
            orderData.cartItems.map(({ product, options, idx }) => (
              <li key={`${product.title}_${idx}`}>
                {' '}
                - {product.title}
                {options.checks.length
                  ? options.checks.map((check) => <p>({check.name})</p>)
                  : ''}
                {options.lists.length
                  ? options.lists.map((list, idx) => (
                      <p key={idx}>
                        ({list.name}:{' '}
                        {list.items
                          ? list.items.map(
                              (item) => `${item.name} - ${item.addition}`,
                            )
                          : list.selectedItemName}
                        )
                      </p>
                    ))
                  : ''}
              </li>
            ))}
        </ol>
      </Descriptions.Item>
      {orderData.rewardUse ? (
        <Descriptions.Item label="리워드 사용">
          {orderData.rewardUse}
        </Descriptions.Item>
      ) : null}

      {orderData.discountCode ? (
        <Descriptions.Item label="할인코드 사용" span={3}>
          {orderData.discountCode.code} - {orderData.discountCode.percentage}% ({' '}
          {orderData.promotionDiscount}원 할인 )
        </Descriptions.Item>
      ) : null}
      <Descriptions.Item label="상품금액">
        {orderData.totalPrice - orderData.shipmentCost}원
      </Descriptions.Item>

      <Descriptions.Item label="배송비">
        {orderData.shipmentCost}
      </Descriptions.Item>

      <Descriptions.Item label="최종결제금액" span={3}>
        {orderData.totalPrice -
          orderData.promotionDiscount -
          orderData.rewardUse}
        원
      </Descriptions.Item>

      {/* <Descriptions.Item label='주문개수'>{orderData.number}</Descriptions.Item> */}
      <Descriptions.Item label="주문상태">
        {orderStatus.get(orderData.statusCode)}
      </Descriptions.Item>
      <Descriptions.Item label="주소" span={2}>
        {orderData.address}
      </Descriptions.Item>
      <Descriptions.Item label="주문자">
        {orderData.buyerName}({orderData.buyer.name})
      </Descriptions.Item>
      <Descriptions.Item label="주문자 번호" span={2}>
        {orderData.buyerPhone}
      </Descriptions.Item>
      <Descriptions.Item label="주문자 이메일" span={3}>
        {orderData.buyerEmail}
      </Descriptions.Item>
      <Descriptions.Item label="받는분">
        {orderData.receiverName}
      </Descriptions.Item>
      <Descriptions.Item label="받는분 번호" span={2}>
        {orderData.receiverPhone}
      </Descriptions.Item>
      <Descriptions.Item label="주문일자">
        {getFormattedDate(orderData.createdAt)}
      </Descriptions.Item>
      {/* <Descriptions.Item label='updatedAt'>{orderData.updatedAt}</Descriptions.Item> */}
      {/* <Descriptions.Item label='vtype'>{`${orderData.vtype}`}</Descriptions.Item> */}
      {/* <Descriptions.Item label="옵션" span={3}>{optionList}</Descriptions.Item> */}
      <Descriptions.Item label="주문 메모" span={3}>
        {orderData.memo}
      </Descriptions.Item>
      <Descriptions.Item label="택배사" span={3}>
        {orderData.shipmentCourierCO}
      </Descriptions.Item>
      <Descriptions.Item label="운송장 번호" span={3}>
        {orderData.shipmentCourierNO}
      </Descriptions.Item>
      <Descriptions.Item label="판매자 - 판매거절 사유" span={3}>
        {orderData.actionMemoSeller}
      </Descriptions.Item>
      <Descriptions.Item label="고객 - 주문취소 사유" span={3}>
        {orderData.actionMemoBuyer}
      </Descriptions.Item>
    </Descriptions>
  );
}

export default OrderCard;

/*
    {
        "options": {
            "checks": [],
            "lists": [
                {
                    "name": "맛 선택",
                    "selectedItemName": "순한맛",
                    "addition": 0,
                    "_id": "613461e8597e6958ea8a9189",
                    "id": "613461e8597e6958ea8a9189"
                }
            ]
        },
        "statusCode": 0,
        "number": 1,
        "shipmentCost": 5000,
        "priceToPay": 10000,
        "address": "달맞이로35",
        "buyerName": "정광희",
        "buyerEmail": "",
        "buyerPhone": "01077731104",
        "receiverName": "정광희 ",
        "receiverPhone": "01077731104",
        "memo": "",
        "actionMemoBuyer": "",
        "actionMemoSeller": "",
        "_id": "613461e8597e6958ea8a9188",
        "product": {
            "thumbnailUrl": "https://storage.googleapis.com/greyd/productImage/1609302655596_56974f2adc295e6a30d43ded675fd224.jpg",
            "_id": "5fec028182b8b333eb54a156",
            "title": "BBQ 비비큐 순살바삭치킨 200g",
            "productId": "5fec028182b8b333eb54a156",
            "ratingCount": 0,
            "id": "5fec028182b8b333eb54a156"
        },
        "buyer": {
            "profilePicUrl": "https://resource.greyd.app/userProfilePic/1630414635729_f1f4c97b41c30a82fca39aca63e8cd7d.JPEG",
            "class": 0,
            "_id": "610cfc8d7c30992bb5d10950",
            "name": "Jactor",
            "userId": "610cfc8d7c30992bb5d10950",
            "ratingCount": 0,
            "id": "610cfc8d7c30992bb5d10950"
        },
        "seller": {
            "profilePicUrl": "https://resource.greyd.app/userProfilePic/1629194382185_808e973783355558209f460cbb2e7bbd.jpg",
            "class": 0,
            "_id": "5fe95b6bed8c2083033e8e21",
            "name": "Renov",
            "userId": "5fe95b6bed8c2083033e8e21",
            "ratingCount": 0,
            "id": "5fe95b6bed8c2083033e8e21"
        },
        "createdAt": "2021-09-05T06:21:28.764Z",
        "updatedAt": "2021-09-05T06:21:50.420Z",
        "__v": 0,
        "orderId": "613461e8597e6958ea8a9188",
        "id": "613461e8597e6958ea8a9188"
    },
    */
