import * as dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');

export function debounce(callback, delay = 100) {
  let timer;

  return function () {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(this, arguments);
    }, delay);
  };
}

export function getFormattedDate(date) {
  const formattedDate = dayjs(date).format('YYYY-MM-DD HH:mm:ss');
  return formattedDate;
}

export function getDateWithYYYYMMDDFormat(date) {
  const formattedDate = dayjs(date).format('YYYY-MM-DD');
  return formattedDate;
}
