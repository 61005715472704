import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
import { Table } from 'antd';

import ReviewCard from './ReviewCard';
import ItemManageButton from './ItemManageButton';
import { getFormattedDate } from '../utils';
import { CATEGORY_LIST } from '../constant';

function onChange(pagination, filters, sorter, extra) {
  // console.log('params', pagination, filters, sorter, extra);
}

function ReviewTable(props) {
  const [reviewList, setReviewList] = useState(props.reviewList);
  useEffect(() => {
    setReviewList(props.reviewList);
  }, [props.reviewList]);
  const handleDeleteSuccess = (record) => {
    setReviewList(reviewList.filter((item) => item !== record));
  };
  const columns = [
    {
      title: '제품',
      dataIndex: '',
      render: (record) => <span>{record.linkedProduct.title}</span>,
      width: '250px',
      ellipsis: true,
    },
    {
      title: '카테고리',
      dataIndex: '',
      render: (record) => (
        <span>{CATEGORY_LIST[record.linkedProduct.categoryCode]}</span>
      ),
    },
    {
      title: '작성자',
      dataIndex: '',
      render: (record) => <span>{record.author.name}</span>,
      ellipsis: true,
    },
    {
      title: '조회수',
      dataIndex: 'viewCount',
      sorter: {
        compare: (a, b) => a.viewCount - b.viewCount,
        multiple: 4,
      },
    },
    {
      title: '레이팅 점수',
      dataIndex: 'g6AvgRatingScore',
      sorter: {
        compare: (a, b) => a.g6AvgRatingScore - b.g6AvgRatingScore,
        multiple: 3,
      },
    },
    {
      title: '레이팅 횟수',
      dataIndex: 'g6RatingList',
      sorter: {
        compare: (a, b) => a.g6RatingList.length - b.g6RatingList.length,
        multiple: 2,
      },
      render: (record) => <span>{record.length}</span>,
    },
    {
      title: '등록시점',
      dataIndex: 'createdAt',
      sorter: {
        compare: (a, b) => {
          const date1 = new Date(a.createdAt);
          const date2 = new Date(b.createdAt);

          return date1 - date2;
        },
        multiple: 1,
      },
      render: (record) => <span>{getFormattedDate(record)}</span>,
      width: '200px',
      ellipsis: true,
    },
    {
      title: '관리',
      dataIndex: '',
      key: 'x',
      render: (record) => (
        <ItemManageButton
          type="review"
          record={record}
          onSuccess={handleDeleteSuccess}
          isAdmin={props.isAdmin}
        />
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={reviewList}
      rowKey={(record) => record.videoId}
      expandable={{
        expandedRowRender: (record) => (
          <ReviewCard reviewData={record} isAdmin={props.isAdmin} />
        ),
        rowExpandable: (record) => true,
      }}
      onChange={onChange}
    />
  );
}

export default ReviewTable;
