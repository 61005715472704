import { useEffect, useState } from 'react';
import plusImg from '../assets/icCommonAdd20.png';
import removeImg from '../assets/icHeaderSearchDelete12W.png';
import indentImg from '../assets/icCommonOptionRe12W.png';
import removeSubImg from '../assets/icHeaderSearchCancle16W.png';

function SubItem({
  item, parentId, id, removeSubItem, optionInputs, setOptionInputs,
}) {
  return (
    <>
      <div className="w-full flex flex-row justify-center">
        <span className="w-1/6 text-striver flex flex-row items-center">
          <img src={indentImg} className="w-3 h-3 mr-1" alt="" /> 세부항목
        </span>
        <input
          type="text"
          value={item.name}
          onChange={({ target: { value } }) => {
            const tempInput = [...optionInputs[parentId].items];
            tempInput[id] = { ...tempInput[id], name: value };

            setOptionInputs({
              ...optionInputs,
              [parentId]: {
                ...optionInputs[parentId],
                items: tempInput,
              },
            });
          }}
          placeholder="옵션 세부 항목 (검정, L/M/S)"
          className="rounded-lg px-2 mb-1 w-3/5"
        />
        <button className="absolute right-14" onClick={() => removeSubItem({ parentId, id })}>
          <img src={removeSubImg} className="w-4 h-4" alt="" />
        </button>
      </div>
      <div className="w-full flex flex-row justify-center">
        <span className="w-1/6 text-striver flex flex-row items-center">
          <div className="w-3 h-3 mr-1" />
          추가가격
        </span>
        <input
          type="number"
          value={item.addition}
          onChange={({ target: { value } }) => {
            const tempInput = [...optionInputs[parentId].items];
            tempInput[id] = { ...tempInput[id], addition: value };

            setOptionInputs({
              ...optionInputs,
              [parentId]: {
                ...optionInputs[parentId],
                items: tempInput,
              },
            });
          }}
          placeholder="0"
          className="rounded-lg px-2 mb-1 w-3/5"
        />
      </div>
      <div className="w-full bg-striver h-0.5 my-2" />
    </>
  );
}

function AddOption({
  idx, handleClickRemove, optionInput, optionInputs, setOptionInputs, addSubItem, removeSubItem,
}) {
  return (
    <div className="bg-dark-grey-1 w-full pb-4 px-4 rounded-lg flex flex-col items-center mb-4">
      <div className="w-full h-5 flex justify-end items-end">
        <button onClick={() => handleClickRemove(idx)}>
          <img src={removeImg} alt="" />
        </button>
      </div>
      <div className="w-full flex flex-row mb-4 justify-center">
        <span className="w-1/6 text-striver">옵션 이름</span>
        <input
          type="text"
          value={optionInput.name}
          onChange={({ target: { value } }) => {
            setOptionInputs({
              ...optionInputs,
              [idx]: { ...optionInputs[idx], name: value },
            });
          }}
          placeholder="상품 옵션 입력 (색상, 사이즈)"
          className="rounded-lg px-2 w-3/5"
        />
      </div>
      <div className="w-full bg-striver h-0.5 mb-2" />
      {optionInput && optionInput.items.length > 0
        ? optionInput.items.map((item, id) => (
          <SubItem
            key={id}
            item={item}
            parentId={idx}
            id={id}
            removeSubItem={removeSubItem}
            optionInputs={optionInputs}
            setOptionInputs={setOptionInputs}
          />
        ))
        : null}
      <button className="text-pioneer font-semibold text-base" onClick={() => addSubItem(idx)}>
        + 세부항목 추가
      </button>
    </div>
  );
}
export default function AddOptions({ optionInputs, setOptionInputs }) {
  const [count, setCount] = useState(0);

  const handleClickAdd = () => {
    setCount(count + 1);
    setOptionInputs({
      ...optionInputs,
      [count + 1]: {
        count: count + 1,
        name: '',
        items: [{ name: '', addition: 0 }],
      },
    });
  };

  const handleClickRemove = (idx) => {
    const tempInputs = { ...optionInputs };
    delete tempInputs[idx];

    setOptionInputs(tempInputs);
  };

  const handleClickAddSubItem = (idx) => {
    const tempInputs = { ...optionInputs };
    tempInputs[idx].items.push({ name: '', addition: 0 });

    setOptionInputs(tempInputs);
  };

  const handleClickRemoveSubItem = ({ parentId, id }) => {
    const tempInputs = { ...optionInputs };
    tempInputs[parentId].items.splice(id, 1);

    setOptionInputs(tempInputs);
  };

  // useEffect(() => {
  //   setCount(0);
  //   setOptionInputs({});
  // }, []);

  return (
    <>
      <div className="flex flex-row justify-between mb-2">
        <span className="text-striver font-semibold text-base">옵션 추가</span>
        <div className="rounded-full bg-pioneer w-5 h-5 flex justify-center items-center">
          <button onClick={() => handleClickAdd()}>
            <img src={plusImg} alt="" />
          </button>
        </div>
      </div>
      {optionInputs
        ? Object.keys(optionInputs).map((key, idx) => (
          <AddOption
            key={idx}
            idx={optionInputs[key].count}
            handleClickRemove={handleClickRemove}
            optionInput={optionInputs[key]}
            optionInputs={optionInputs}
            setOptionInputs={setOptionInputs}
            addSubItem={handleClickAddSubItem}
            removeSubItem={handleClickRemoveSubItem}
          />
        ))
        : null}
    </>
  );
}
