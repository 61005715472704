import axios from 'axios';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import AdminRepository, { API_ROOT_URL } from '../repositories/AdminRepository';
import { debounce } from '../utils';

const placeholders = {
  INPUT_BUSINESS_CERTIFICATION_NO: '사업자 등록번호를 입력해주세요',
  ATTACH_BUSINESS_CERTIFICATION_IMAGE: '사업자 등록증을 첨부해주세요',
  INPUT_BUSINESS_NAME: '법인명 또는 상호명을 등록해주세요.',
  INPUT_BUSINESS_ADDRESS: '업체주소를 등록해주세요.',
  INPUT_BUSINESS_MANAGER_NAME: '담당자 이름을 등록해주세요.',
  INPUT_BRAND_NAME: '브랜드 이름을 등록해주세요.',
  INPUT_BUSINESS_HOMEPAGE: '홈페이지 주소를 등록해주세요.',
  INPUT_INSTAGRAM_ID: '인스타그램 아이디를 입력해주세요',
  SELECT_BANK: '은행명을 선택해주세요.',
  SELLER_BANK_ACCOUNT_GUIDE: '계좌번호를 정확하게 입력해주세요.',
  SELLER_BANK_ACCOUNT_NAME_GUIDE: '예금주를 정확하게 입력해주세요.',
  SELLER_EMAIL_GUIDE: '구매 알림을 받을 이메일을 입력해주세요.',
  SELLER_PHONE_GUIDE: '구매 관련하여 문의받을 연락처를 입력해주세요.',
  SELLER_BANKBOOK_COPY: '통장사본을 첨부해주세요',
  SELLER_TELEMARKETING_BUSINESS: '통신판매업신고증을 첨부해주세요',
};

const bankList = {
  keb: 'KEB하나은행', // BANK_KEB
  sc: 'SC제일은행', // BANK_SC
  kb: '국민은행', // BANK_KB
  shinhan: '신한은행', // BANK_SHINHAN
  woori: '우리은행', // BANK_WOORI
  citi: '시티은행', // BANK_CITI
  ibk: '기업은행', // BANK_IBK
  nh: '농협은행', // BANK_NH
  suhyup: '수협은행', // BANK_SUHYUP
  etc: '기타은행', // BANK_ETC
};

const debouncedCheckId = debounce(async (value, setIsValid) => {
  const result = await axios.get(
    `${API_ROOT_URL}/users/duplicate?name=${value}`,
  );

  if (result && !result.data.success) {
    setIsValid(false);
  } else {
    setIsValid(true);
  }
}, 300);

const debouncedCheckEmail = debounce(
  async (value, setIsValid, setValidText) => {
    if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
      setValidText('올바른 이메일 형식을 입력해주세요.');
    }
  },
  300,
);

const debounedCheckValid = debounce((value, setValidText) => {
  if (!value.trim()) {
    setValidText('필수 입력값입니다.');
  } else {
    setValidText('');
  }
}, 100);

const debounedCheckValidId = debounce(
  (value, isValid, setValidText, originalName) => {
    if (originalName && originalName.toLowerCase() === value.toLowerCase()) {
      return setValidText('');
    }

    if (!isValid) {
      return setValidText(
        value ? '중복된 아이디입니다.' : '필수 입력값입니다.',
      );
    }

    setValidText('');
  },
  100,
);

const debounedCheckValidEmail = debounce((isValid, setValidText) => {
  if (!isValid) {
    return setValidText('올바른 이메일 형식을 입력해주세요.');
  }

  setValidText('');
}, 100);

function InputText({
  label,
  inputId,
  placeholder,
  value = '',
  setValue = '',
  required = false,
  isValid,
  setIsValid = () => {},
  originalName = '',
}) {
  const [validText, setValidText] = useState('');

  useEffect(() => {
    if (required && !value.trim()) {
      setIsValid(false);
      return setValidText('필수 입력값입니다.');
    }

    setIsValid(true);
    return setValidText('');
  }, [required, value]);

  useEffect(() => {
    if (!required) {
      return;
    }

    if (inputId === 'name' && !/^[0-9a-zA-Z_.]*$/.test(value)) {
      return setValidText('특수문자는 불가능합니다.');
    }

    // if (inputId === 'name' && !isValid) {
    if (inputId === 'name') {
      return debounedCheckValidId(value, isValid, setValidText, originalName);
    }

    if ((inputId === 'email' || inputId === 'sellerManagerEmail') && !isValid) {
      return debouncedCheckEmail(value, setIsValid, setValidText);
      // return debounedCheckValidEmail(isValid, setValidText);
    }

    debounedCheckValid(value, setValidText);
  }, [inputId, isValid, required, setIsValid, value, originalName]);

  return (
    <div className="w-4/5 flex justify-between items-center mb-2">
      <label
        htmlFor={inputId}
        className="w-1/5 text-explorer font-semibold text-base"
      >
        {label}{' '}
        <span className="text-giver align-middle">{required ? '*' : ''}</span>
      </label>
      <input
        id={inputId}
        type="text"
        placeholder={placeholder}
        className="w-3/5 rounded-lg py-1 px-3 text-sm font-semibold text-artisan"
        value={value}
        onChange={async (e) => {
          setValue(e.target.value);

          if (inputId === 'name') {
            return debouncedCheckId(e.target.value, setIsValid, setValidText);
          }

          if (inputId === 'email' || inputId === 'sellerManagerEmail') {
            return debouncedCheckEmail(e.target.value, setIsValid);
          }

          if (e.target.value.trim()) {
            return setIsValid(true);
          }

          setIsValid(false);
        }}
      />
      <span className="ml-4 w-1/5 text-red-400 text-sm">{validText}</span>
    </div>
  );
}

function InputFile({
  label = '업로드',
  inputId,
  placeholder,
  value = '',
  setValue,
  required = false,
  isValid,
  setIsValid = () => {},
}) {
  const [fileName, setFileName] = useState('');
  const [validText, setValidText] = useState('');

  useEffect(() => {
    if (required && !value) {
      return setValidText('필수 첨부값입니다.');
    }

    setIsValid(true);
    setValidText('');
  }, [required, setIsValid, value]);

  return (
    <div className="w-4/5 flex justify-between items-center mb-2">
      <label htmlFor="" className="w-1/5 text-explorer font-semibold text-base">
        {label}{' '}
        <span className="text-giver align-middle">{required ? '*' : ''}</span>
      </label>
      <label
        htmlFor={inputId}
        className="w-3/5 text-center font-bold text-giver rounded-lg border border-pioneer cursor-pointer text-sm"
      >
        {value && typeof value === 'string'
          ? `...${value.slice(-20)}`
          : fileName || placeholder}
      </label>
      <input
        id={inputId}
        type="file"
        accept="image/*"
        className="hidden"
        onChange={(e) => {
          setValue(e.target.files[0]);
          setFileName(e.target.files[0].name);
          setIsValid(true);
        }}
      />
      <div className="ml-4 w-1/5 text-red-400 text-sm">
        {isValid ? '' : validText}
      </div>
    </div>
  );
}

function InputSelect({
  label,
  inputId,
  placeholder,
  value = '',
  setValue,
  required = false,
  list,
  isValid,
  setIsValid,
}) {
  const [validText, setValidText] = useState('');

  useEffect(() => {
    if (required && !value.trim()) {
      return setValidText('필수 선택값입니다.');
    }

    setIsValid(true);
    setValidText('');
  }, [required, setIsValid, value]);

  return (
    <div className="w-4/5 flex justify-between items-center mb-2">
      <label
        htmlFor={inputId}
        className="w-1/5 text-explorer font-semibold text-base"
      >
        {label}{' '}
        <span className="text-giver align-middle">{required ? '*' : ''}</span>
      </label>
      <select
        id={inputId}
        className="w-3/5 text-center rounded-lg bg-operator text-giver font-semibold text-sm"
        value={value}
        onChange={(e) => {
          if (e.target.value) {
            setValue(e.target.value);
            setIsValid(true);
            return;
          }

          setIsValid(false);
        }}
      >
        <option key="default" value="">
          은행을 선택해주세요
        </option>
        {Object.keys(list).map((bankCode) => (
          <option key={bankCode} value={list[bankCode]}>
            {list[bankCode]}
          </option>
        ))}
      </select>
      <div className="ml-4 w-1/5 text-red-400 text-sm">
        {isValid ? '' : validText}
      </div>
    </div>
  );
}

const UserUpdate = forwardRef(
  ({ authType, authUser, setAuthorized, setUser, setShowModal }, ref) => {
    const [originalName, setOriginalName] = useState('');
    const [name, setName] = useState('');
    const [introduction, setIntroduction] = useState('');
    const [email, setEmail] = useState('');
    const [sellerCertificationNo, setSellerCertificationNo] = useState('');
    const [sellerCertificationUrl, setSellerCertificationUrl] = useState('');
    const [sellerBankName, setSellerBankName] = useState('');
    const [sellerBankAccount, setSellerBankAccount] = useState('');
    const [sellerBankAccountHolder, setSellerBankAccountHolder] = useState('');
    const [sellerManagerEmail, setSellerManagerEmail] = useState('');
    const [sellerManagerContact, setSellerManagerContact] = useState('');
    const [sellerManagerName, setSellerManagerName] = useState('');
    const [sellerBusinessName, setSellerBusinessName] = useState('');
    const [sellerBrandName, setSellerBrandName] = useState('');
    const [sellerHomepage, setSellerHomepage] = useState('');
    const [sellerOfficeAddress, setSellerOfficeAddress] = useState('');
    const [sellerBankbookCopyUrl, setSellerBankbookCopyUrl] = useState('');
    const [sellerTelemarketingBusinessUrl, setSellerTelemarketingBusinessUrl] =
      useState('');
    const [instagramId, setInstagramId] = useState('');

    const [isValidName, setIsValidName] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(!!email);
    const [isValidCertificateNo, setIsValidCertificateNo] = useState(false);
    const [isValidCertificateUrl, setIsValidCertificateUrl] = useState(false);
    const [isValidBusinessName, setIsValidBusinessName] = useState(false);
    const [isValidBankName, setIsValidBankName] = useState(false);
    const [isValidBankAccount, setIsValidBankAccount] = useState(false);
    const [isValidBankAccountHolder, setIsValidBankAccountHolder] =
      useState(false);
    const [isValidManagerName, setIsValidManagerName] = useState(false);
    const [isValidSellerManagerEmail, setIsValidSellerManagerEmail] =
      useState(false);
    const [isValidSellerManagerContact, setIsValidSellerManagerContact] =
      useState(false);
    const [isValidsellerBankbookCopyUrl, setIsValidsellerBankbookCopyUrl] =
      useState(false);

    const [sellerStatus, setSellerStatus] = useState(false);

    useEffect(() => {
      AdminRepository.getUser(authUser.id).then((res) => {
        setSellerStatus(res.data.sellerStatus);

        setName(res.data.name);
        setOriginalName(res.data.name);
        setIntroduction(res.data.introduction);
        setEmail(res.data.email);
        setSellerCertificationNo(res.data.sellerCertificationNo);
        setSellerCertificationUrl(res.data.sellerCertificationUrl);

        const bankKey = Object.keys(bankList).find(
          (key) => bankList[key] === res.data.sellerBankName.trim(),
        );
        setSellerBankName(bankList[bankKey]);

        setSellerBankAccount(res.data.sellerBankAccount);
        setSellerBankAccountHolder(res.data.sellerBankAccountHolder);
        setSellerManagerEmail(res.data.sellerManagerEmail);
        setSellerManagerContact(res.data.sellerManagerContact);
        setSellerManagerName(res.data.sellerManagerName);
        setSellerBusinessName(res.data.sellerBusinessName);
        setSellerBrandName(res.data.sellerBrandName);
        setSellerHomepage(res.data.sellerHomepage);
        setSellerOfficeAddress(res.data.sellerOfficeAddress);
        setSellerBankbookCopyUrl(res.data.sellerBankbookCopyUrl);
        setSellerTelemarketingBusinessUrl(
          res.data.sellerTelemarketingBusinessUrl,
        );
        setInstagramId(res.data.instagramId);
      });
    }, [authUser.id]);

    const handleSubmit = () => {
      if (
        !(
          isValidName &&
          isValidEmail &&
          isValidCertificateNo &&
          isValidCertificateUrl &&
          isValidBusinessName &&
          isValidBankName &&
          isValidBankAccount &&
          isValidBankAccountHolder &&
          isValidManagerName &&
          isValidSellerManagerEmail &&
          isValidSellerManagerContact &&
          isValidsellerBankbookCopyUrl
        )
      ) {
        let inValidMesage = '필수 항목이 누락되거나 잘못입력되었습니다.';

        if (!isValidName) {
          inValidMesage += ' (사용자 아이디)';
        } else if (!isValidEmail) {
          inValidMesage += ' (이메일)';
        } else if (!isValidCertificateNo) {
          inValidMesage += ' (사업자 등록번호)';
        } else if (!isValidCertificateUrl) {
          inValidMesage += ' (사업자 등록증)';
        } else if (!isValidBusinessName) {
          inValidMesage += ' (상호명)';
        } else if (!isValidBankName) {
          inValidMesage += ' (은행명)';
        } else if (!isValidBankAccount) {
          inValidMesage += ' (계좌번호)';
        } else if (!isValidBankAccountHolder) {
          inValidMesage += ' (예금주)';
        } else if (!isValidManagerName) {
          inValidMesage += ' (담당자명)';
        } else if (!isValidSellerManagerEmail) {
          inValidMesage += ' (판매자 이메일)';
        } else if (!isValidSellerManagerContact) {
          inValidMesage += ' (판매자 연락처)';
        } else if (!isValidsellerBankbookCopyUrl) {
          inValidMesage += ' (통장 사본)';
        }

        return alert(inValidMesage);
      }

      const formData = new FormData();

      formData.append('name', name);
      formData.append('introduction', introduction);
      formData.append('email', email);
      formData.append('sellerCertificationNo', sellerCertificationNo);
      formData.append('sellerCertificationUrl', sellerCertificationUrl);
      formData.append('sellerBankName', sellerBankName);
      formData.append('sellerBankAccount', sellerBankAccount);
      formData.append('sellerBankAccountHolder', sellerBankAccountHolder);
      formData.append('sellerManagerEmail', sellerManagerEmail);
      formData.append('sellerManagerContact', sellerManagerContact);
      formData.append('sellerManagerName', sellerManagerName);
      formData.append('sellerBusinessName', sellerBusinessName);
      formData.append('sellerBrandName', sellerBrandName);
      formData.append('sellerHomepage', sellerHomepage);
      formData.append('instagramId', instagramId || '');
      formData.append('sellerOfficeAddress', sellerOfficeAddress);
      formData.append('sellerBankbookCopyUrl', sellerBankbookCopyUrl);
      formData.append(
        'sellerTelemarketingBusinessUrl',
        sellerTelemarketingBusinessUrl,
      );
      formData.append('id', authUser.id);

      if (!sellerStatus) {
        formData.append('sellerStatus', 'APPROVAL_REQUEST');
      }

      axios
        .put(`${API_ROOT_URL}/users/seller`, formData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(async (res) => {
          if (res && res.data.success) {
            const user = await AdminRepository.request(
              `${API_ROOT_URL}/users/${authUser.id}`,
              'GET',
            );
            setUser(user);
            // setAuthorized(true);

            alert('정보 수정이 완료되었습니다.');
            setShowModal(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const { errors } = error.response.data;

            const errorObject = errors[Object.keys(errors)[0]];

            alert(
              `정보 수정에 실패했습니다. 관리자에게 에러내용을 첨부하여 문의해주세요.\n[에러내용: ${errorObject.name} - ${errorObject.kind} (${errorObject.path}) ]`,
            );
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
    };

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    return (
      <div className="bg-greyd-grey h-full w-full flex flex-col items-center mb-4">
        <InputText
          label="사용자 아이디"
          placeholder="3~16자의 영문 또는 숫자, 특수기호(_, .)로 입력해주세요"
          inputId="name"
          required
          value={name}
          setValue={setName}
          isValid={isValidName}
          setIsValid={setIsValidName}
          originalName={originalName}
        />

        <InputText
          label="프로필 설명"
          placeholder="회사 / 브랜드에 대한 설명을 간략히 입력해주세요."
          inputId="introduction"
          value={introduction}
          setValue={setIntroduction}
        />

        <InputText
          label="이메일"
          placeholder="이메일을 입력해주세요"
          inputId="email"
          required
          value={email}
          setValue={setEmail}
          isValid={isValidEmail}
          setIsValid={setIsValidEmail}
        />

        <InputText
          label="사업자 등록번호"
          placeholder="ex) 123-45-67890"
          inputId="sellerCertificationNo"
          required
          value={sellerCertificationNo}
          setValue={setSellerCertificationNo}
          isValid={isValidCertificateNo}
          setIsValid={setIsValidCertificateNo}
        />

        <InputFile
          label="사업자 등록증"
          className="text-artisan"
          placeholder={placeholders.ATTACH_BUSINESS_CERTIFICATION_IMAGE}
          inputId="sellerCertificationUrl"
          required
          value={sellerCertificationUrl}
          setValue={setSellerCertificationUrl}
          isValid={isValidCertificateUrl}
          setIsValid={setIsValidCertificateUrl}
        />

        <InputText
          label="상호명"
          placeholder={placeholders.INPUT_BUSINESS_NAME}
          inputId="sellerBusinessName"
          required
          value={sellerBusinessName}
          setValue={setSellerBusinessName}
          isValid={isValidBusinessName}
          setIsValid={setIsValidBusinessName}
        />

        <InputText
          label="브랜드명"
          placeholder={placeholders.INPUT_BRAND_NAME}
          inputId="sellerBrandName"
          value={sellerBrandName}
          setValue={setSellerBrandName}
        />

        <InputSelect
          label="은행명"
          placeholder={placeholders.SELECT_BANK}
          inputId="sellerBankName"
          list={bankList}
          required
          value={sellerBankName}
          setValue={setSellerBankName}
          isValid={isValidBankName}
          setIsValid={setIsValidBankName}
        />

        <InputText
          label="계좌번호"
          placeholder={placeholders.SELLER_BANK_ACCOUNT_GUIDE}
          inputId="sellerBankAccount"
          required
          value={sellerBankAccount}
          setValue={setSellerBankAccount}
          isValid={isValidBankAccount}
          setIsValid={setIsValidBankAccount}
        />

        <InputText
          label="예금주"
          placeholder={placeholders.SELLER_BANK_ACCOUNT_NAME_GUIDE}
          inputId="sellerBankAccountHolder"
          required
          value={sellerBankAccountHolder}
          setValue={setSellerBankAccountHolder}
          isValid={isValidBankAccountHolder}
          setIsValid={setIsValidBankAccountHolder}
        />

        <InputText
          label="담당자명"
          placeholder={placeholders.INPUT_BUSINESS_MANAGER_NAME}
          inputId="sellerManagerName"
          required
          value={sellerManagerName}
          setValue={setSellerManagerName}
          isValid={isValidManagerName}
          setIsValid={setIsValidManagerName}
        />

        <InputText
          label="판매자 이메일"
          placeholder={placeholders.SELLER_EMAIL_GUIDE}
          inputId="sellerManagerEmail"
          required
          value={sellerManagerEmail}
          setValue={setSellerManagerEmail}
          isValid={isValidSellerManagerEmail}
          setIsValid={setIsValidSellerManagerEmail}
        />

        <InputText
          label="판매자 연락처"
          placeholder={placeholders.SELLER_PHONE_GUIDE}
          inputId="sellerManagerContact"
          required
          value={sellerManagerContact}
          setValue={setSellerManagerContact}
          isValid={isValidSellerManagerContact}
          setIsValid={setIsValidSellerManagerContact}
        />

        <InputText
          label="공식 홈페이지 주소"
          placeholder={placeholders.INPUT_BUSINESS_HOMEPAGE}
          inputId="sellerHomepage"
          value={sellerHomepage}
          setValue={setSellerHomepage}
        />

        <InputText
          label="업체 주소"
          placeholder={placeholders.INPUT_BUSINESS_ADDRESS}
          inputId="sellerOfficeAddress"
          value={sellerOfficeAddress}
          setValue={setSellerOfficeAddress}
        />

        <InputText
          label="인스타그램 아이디"
          placeholder={placeholders.INPUT_INSTAGRAM_ID}
          inputId="instagramId"
          value={instagramId}
          setValue={setInstagramId}
        />

        <InputFile
          label="통장사본"
          placeholder={placeholders.SELLER_BANKBOOK_COPY}
          inputId="sellerBankbookCopyUrl"
          required
          value={sellerBankbookCopyUrl}
          setValue={setSellerBankbookCopyUrl}
          isValid={isValidsellerBankbookCopyUrl}
          setIsValid={setIsValidsellerBankbookCopyUrl}
        />

        <InputFile
          label="통신판매업신고증"
          placeholder={placeholders.SELLER_TELEMARKETING_BUSINESS}
          inputId="sellerTelemarketingBusinessUrl"
          value={sellerTelemarketingBusinessUrl}
          setValue={setSellerTelemarketingBusinessUrl}
        />
      </div>
    );
  },
);

UserUpdate.displayName = 'UserUpdate';
export default UserUpdate;
