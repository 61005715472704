import React, { useState, useEffect } from 'react';
import { Button, Input, Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import UserTable from '../molecules/UserTable';
import AdminRepository from '../repositories/AdminRepository';
import UserDownloadButton from '../molecules/UserDownloadButton';
import Spinner from './Spinner';

// TODO: 유저 리스트 조회 API 로직 보완, 20개 이상의 리스트가 보여지도록
// TODO: Style 정리, 문자열 constant 정리

function UserListView() {
  const [userListData, setUserListData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectSeller, setSelectSeller] = useState(false);

  const { Search } = Input;

  const onSearch = async (value) => {
    const params = { searchKeyword: value };
    // TODO : 추가적으로 item을 부르도록 수정해야 함.
    // 임시 코드
    params.limit = 3000;

    setLoading(true);

    console.log('params search', params);
    const result = await AdminRepository.getUserList(params);
    if (result) {
      setUserListData(result.data);
      // console.log('search : ', result.data);
    }

    setLoading(false);
  };
  const updateUserList = async (params) => {
    setLoading(true);

    const result = await AdminRepository.getUserList(params);
    if (result) {
      setUserListData(result.data);
    }

    setLoading(false);
  };

  const handleClickRefresh = () => {
    updateUserList({ limit: 3000, isSeller: selectSeller });
  };

  useEffect(() => {
    const params = { limit: 3000 };
    updateUserList(params);
  }, []);

  const handleUserSelectChange = async (value) => {
    const params = { limit: 3000 };
    if (value !== -1) {
      params.isSeller = value;
      setSelectSeller(value);
    }

    setLoading(true);

    const result = await AdminRepository.getUserList(params);
    if (result) {
      setUserListData(result.data);
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="flex flex-row justify-between">
        <Search
          placeholder="input search text"
          allowClear
          onSearch={onSearch}
          style={{ width: 300 }}
          className="mb-2"
        />
        <Select
          defaultValue={-1}
          style={{ width: '15%', float: 'right' }}
          onChange={handleUserSelectChange}
        >
          <Option value={-1}>모두</Option>
          <Option value>셀러</Option>
          <Option value={false}>리뷰어</Option>
        </Select>
        <div>
          <Button className="mr-4" onClick={handleClickRefresh}>
            새로고침
          </Button>
          <UserDownloadButton
            userList={userListData}
            style={{ width: '15%', float: 'right' }}
          />
        </div>
      </div>
      {!isLoading ? (
        <UserTable
          userInfoList={userListData}
          handleClickRefresh={handleClickRefresh}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default UserListView;
