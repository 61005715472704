import React from 'react';
import { Button } from 'antd';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { getFormattedDate } from '../utils';

const orderStatus = new Map([
  [0, '결제 대기중'],
  [1, '결제 완료'],
  [2, '발송 준비중'],
  [3, '배송중'],
  [4, '배송완료'],
  [5, '구매완료'],
  [6, '결제 취소'],
  [7, '판매 거절'],
  [8, '구매 취소'],
  [20, '배송되기 전'],
  [21, '결제 이후의 주문 내역'],
]);

const columns = [
  {
    header: '주문상품',
    key: 'productTitle',
    width: 70,
  },
  {
    header: '판매자',
    key: 'seller',
    width: 18,
  },
  {
    header: '구매수',
    key: 'number',
    width: 9,
  },
  {
    header: '결제',
    key: 'totalPrice',
    width: 9,
  },
  {
    header: '수익',
    key: 'profitAmount',
    width: 9,
  },
  {
    header: '주문상태',
    key: 'orderStatus',
    width: 15,
    // render: record=><span>{record.buyer.name}</span>,
  },
  {
    header: '배송완료',
    key: 'shipmentCompletedAt',
    width: 23,
  },
  {
    header: '구매확정',
    key: 'purchaseCompletedAt',
    width: 23,
  },
  {
    header: '주문자',
    key: 'buyerName',
    width: 13,
    // render: record=><span>{record.buyer.name}</span>,
  },
  // {
  //   header: '주문번호',
  //   key: 'orderId',
  //   width: 28,
  // },
  {
    header: '주문일시',
    key: 'paidAt',
    width: 23,
  },
];

function RevenueDownloadButton(props) {
  const handleDownloadSheet = async () => {
    // productListData
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('매출내역'); // sheet 이름이 My Sheet // sheet 데이터 설정
    worksheet.columns = columns;
    props.revenueList.map((record, index) => {
      const result = worksheet.addRow({
        productTitle: `${record.order.cartItems[0]?.product.title} ${
          record.order.cartItems.length - 1 > 0 ? `외 ${record.order.cartItems.length - 1}` : ''
        }`,
        seller: record.user.name,
        // orderId: record.order.id,
        totalPrice: record.order.totalPrice,
        profitAmount: record.profitAmount,
        purchaseCompletedAt: getFormattedDate(record.order.purchaseCompletedAt),
        shipmentCompletedAt: getFormattedDate(record.order.shipmentCompletedAt),
        buyerName: record.order.buyerName,
        number: record.order.number,
        paidAt: getFormattedDate(record.order.paidAt),
        orderStatus: orderStatus.get(record.order.statusCode),
      });
      return result;
    });

    const mimeType = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);

    const today = new Date();
    const year = today.getFullYear();
    const month = `0${today.getMonth() + 1}`.slice(-2);
    const day = `0${today.getDate()}`.slice(-2);

    const dateString = year + month + day;
    saveAs(blob, `매출내역-${dateString}.xlsx`);
  };
  return <Button onClick={handleDownloadSheet}>다운로드</Button>;
}

export default RevenueDownloadButton;
