import React from 'react';
import { Descriptions } from 'antd';
import { getFormattedDate } from '../utils';

function RevenueCard(props) {
  const { revenueData } = props;

  return (
    <Descriptions bordered>
      {/* <Descriptions.Item label='RevenueId'>{revenueData._id}</Descriptions.Item> */}
      <Descriptions.Item label="제품명" span={2}>
        {`${revenueData.order.cartItems[0]?.product.title} ${
          revenueData.order.cartItems.length - 1 > 0
            ? `외 ${revenueData.order.cartItems.length - 1}`
            : ''
        }`}
      </Descriptions.Item>
      {/* <Descriptions.Item label='기여도'>{revenueData.contributionRate}</Descriptions.Item> */}
      <Descriptions.Item label="매출">
        {revenueData.revenueAmount}
      </Descriptions.Item>
      <Descriptions.Item label="수익">
        {revenueData.profitAmount}
      </Descriptions.Item>
      <Descriptions.Item label="셀러">
        {revenueData.user.name}
      </Descriptions.Item>
      {/* <Descriptions.Item label='orderId'>{revenueData.order.id}</Descriptions.Item> */}
      <Descriptions.Item label="리뷰">
        {revenueData.video ? (
          <a href={`https://resource.greyd.app/${revenueData.video.videoPath}`}>
            새 창에서 보기
          </a>
        ) : null}
      </Descriptions.Item>
      <Descriptions.Item label="매출발생일">
        {getFormattedDate(revenueData.createdAt)}
      </Descriptions.Item>
      {/* <Descriptions.Item label='updatedAt'>{revenueData.updatedAt}</Descriptions.Item> */}
      <Descriptions.Item label="구매확정일">
        {getFormattedDate(revenueData.order.purchaseCompletedAt)}
      </Descriptions.Item>
      {/* <Descriptions.Item label='isSeller'>{`${revenueData.isSeller}`}</Descriptions.Item> */}
      {/* <Descriptions.Item label='isValid'>{`${revenueData.isValid}`}</Descriptions.Item> */}
    </Descriptions>
  );
}

export default RevenueCard;

/*
    {
        "contributionRate": 100,
        "isSeller": true,
        "isValid": true,
        "_id": "60e7d9b62d02c605ef02deba",
        "user": {
            "_id": "5fe95b6bed8c2083033e8e21",
            "name": "Renov",
            "userId": "5fe95b6bed8c2083033e8e21",
            "ratingCount": 0,
            "id": "5fe95b6bed8c2083033e8e21"
        },
        "order": {
            "options": {
                "checks": [],
                "lists": [
                    {
                        "name": "맛 선택",
                        "selectedItemName": "순한맛",
                        "addition": 0,
                        "_id": "60e7d2832d02c605ef02deb5"
                    }
                ]
            },
            "number": 1,
            "_id": "60e7d2832d02c605ef02deb4",
            "buyer": {
                "_id": "5fe99916b407304c60a6ef9d",
                "name": "Changhee",
                "userId": "5fe99916b407304c60a6ef9d",
                "ratingCount": 0,
                "id": "5fe99916b407304c60a6ef9d"
            },
            "purchaseCompletedAt": "2021-07-10T01:43:00.949Z",
            "orderId": "60e7d2832d02c605ef02deb4",
            "id": "60e7d2832d02c605ef02deb4"
        },
        "revenueAmount": 0,
        "profitAmount": 5270,
        "product": {
            "thumbnailUrl": "https://storage.googleapis.com/greyd/productImage/1609302655596_56974f2adc295e6a30d43ded675fd224.jpg",
            "_id": "5fec028182b8b333eb54a156",
            "title": "BBQ 비비큐 순살바삭치킨 200g",
            "productId": "5fec028182b8b333eb54a156",
            "ratingCount": 0,
            "id": "5fec028182b8b333eb54a156"
        },
        "createdAt": "2021-07-09T05:08:06.216Z",
        "updatedAt": "2021-07-10T01:43:01.144Z",
        "__v": 0
    },
    {
        "contributionRate": 0.33,
        "isSeller": false,
        "isValid": true,
        "_id": "60e7d9b62d02c605ef02deb8",
        "user": {
            "_id": "5fe95b6bed8c2083033e8e21",
            "name": "Renov",
            "userId": "5fe95b6bed8c2083033e8e21",
            "ratingCount": 0,
            "id": "5fe95b6bed8c2083033e8e21"
        },
        "order": {
            "options": {
                "checks": [],
                "lists": [
                    {
                        "name": "맛 선택",
                        "selectedItemName": "순한맛",
                        "addition": 0,
                        "_id": "60e7d2832d02c605ef02deb5"
                    }
                ]
            },
            "number": 1,
            "_id": "60e7d2832d02c605ef02deb4",
            "buyer": {
                "_id": "5fe99916b407304c60a6ef9d",
                "name": "Changhee",
                "userId": "5fe99916b407304c60a6ef9d",
                "ratingCount": 0,
                "id": "5fe99916b407304c60a6ef9d"
            },
            "purchaseCompletedAt": "2021-07-10T01:43:00.949Z",
            "orderId": "60e7d2832d02c605ef02deb4",
            "id": "60e7d2832d02c605ef02deb4"
        },
        "revenueAmount": 6200,
        "profitAmount": 102.3,
        "video": {
            "title": "맛있는 간편조리 BBQ 순살바삭 치킨 리뷰들어갑니다~",
            "_id": "5fec63a082b8b333eb54a189",
            "thumbnailUrl": "https://storage.googleapis.com/greyd/videoThumbnail/1609327517605_2a21272f50b900677e64fa3a6b436b46.jpg",
            "videoId": "5fec63a082b8b333eb54a189",
            "ratingCount": 0,
            "id": "5fec63a082b8b333eb54a189"
        },
        "product": {
            "thumbnailUrl": "https://storage.googleapis.com/greyd/productImage/1609302655596_56974f2adc295e6a30d43ded675fd224.jpg",
            "_id": "5fec028182b8b333eb54a156",
            "title": "BBQ 비비큐 순살바삭치킨 200g",
            "productId": "5fec028182b8b333eb54a156",
            "ratingCount": 0,
            "id": "5fec028182b8b333eb54a156"
        },
        "createdAt": "2021-07-09T05:08:06.216Z",
        "updatedAt": "2021-07-10T01:43:01.144Z",
        "__v": 0
    },
    */
