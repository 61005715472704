import { Button, Input, Radio, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { CATEGORY_LIST } from '../constant';
import ReviewDownloadButton from '../molecules/ReviewDownloadButton';
import ReviewTable from '../molecules/ReviewTable';
import AdminRepository from '../repositories/AdminRepository';
import Spinner from './Spinner';

// TODO: 리뷰 리스트 조회 API 로직 보완, 20개 이상의 리스트가 보여지도록
// TODO: Style 정리, 문자열 constant 정리

function ReviewListView({ isAdmin }) {
  const [reviewListData, setReviewListData] = useState([]);
  const [reviewListCopy, setReviewListCopy] = useState([]);
  const [searchType, setSearchType] = useState('keyword');
  const [category, setCategory] = useState('all');
  const [searchWord, setSearchWord] = useState('');
  const [isLoading, setLoading] = useState(false);

  const { Search } = Input;
  const { Option } = Select;

  const handleRadioChange = (e) => {
    setSearchType(e.target.value);
  };

  const handleSelectChange = async (value) => {
    setCategory(value);
    const params = {};
    if (value !== 'all') params.categoryCode = value;

    // TODO : 추가적으로 item을 부르도록 수정해야 함.
    // 임시 코드
    params.limit = 3000;

    setLoading(true);

    const result = await AdminRepository.getReviewList(params);
    if (result) {
      setReviewListData(result.data.videoList);
    }

    setLoading(false);
  };

  const handleSearch = async (value) => {
    setLoading(true);

    const params = {};
    if (category !== 'all') params.categoryCode = category;
    if (value !== '') {
      if (searchType === 'keyword') params.searchKeyword = value;
      else if (searchType === 'userId') params.userId = value;
      else if (searchType === 'productId') params.productId = value;
    }

    // TODO : 추가적으로 item을 부르도록 수정해야 함.
    // 임시 코드
    params.limit = 3000;
    if (searchType === 'userId' && value) {
      // console.log(value);
      const result = await AdminRepository.getUser(value);
      if (result) {
        setSearchWord(value);
        setReviewListData(result.data.userUploadVideo.videoList);
      }
    } else if (searchType === 'productId' && value) {
      const filtered = reviewListCopy.filter((review) =>
        review?.linkedProduct?.title
          .toLowerCase()
          .includes(value.toLowerCase()),
      );
      setReviewListData(filtered);
    } else {
      const result = await AdminRepository.getReviewList(params);
      if (result) {
        setSearchWord(value);
        setReviewListData(result.data.videoList);
      }
    }

    setLoading(false);
  };

  const updateReviewList = async (params) => {
    setLoading(true);

    const result = await AdminRepository.getReviewList(params);

    if (result) {
      setReviewListData(result.data.videoList);
      setReviewListCopy(result.data.videoList);
    }

    setLoading(false);
  };

  const handleClickRefresh = () => {
    updateReviewList({ limit: 3000 });
  };

  useEffect(() => {
    const params = { limit: 3000 };
    updateReviewList(params);
  }, []);

  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-2">
        <Search
          placeholder="input search text"
          allowClear
          onSearch={handleSearch}
          style={{ width: '40%', float: 'left' }}
        />
        <Radio.Group
          onChange={handleRadioChange}
          value={searchType}
          style={{ float: 'left', margin: '0px 10px' }}
        >
          <Radio value="keyword">제목 및 설명</Radio>
          <Radio value="userId">유저아이디</Radio>
          <Radio value="productId">제품명</Radio>
        </Radio.Group>
        <Select
          defaultValue={category}
          style={{ width: '15%', float: 'right' }}
          onChange={handleSelectChange}
        >
          <Option value="all">전체</Option>
          {Object.keys(CATEGORY_LIST).map((categoryKey) => (
            <Option key={categoryKey} value={categoryKey}>
              {CATEGORY_LIST[categoryKey]}
            </Option>
          ))}
        </Select>
        <Button onClick={handleClickRefresh}>새로고침</Button>
        <ReviewDownloadButton
          reviewList={reviewListData}
          style={{ width: '15%', float: 'right' }}
        />
      </div>

      {!isLoading ? (
        <ReviewTable
          key={searchWord}
          reviewList={reviewListData}
          isAdmin={isAdmin}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default ReviewListView;
