/* eslint-disable react/jsx-curly-newline */
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { WITHDRAWAL_REWARD_STATUS_CODE } from '../constant';
import UserRewardTable from '../molecules/UserRewardTable';
import AdminRepository from '../repositories/AdminRepository';
import Spinner from './Spinner';
import UserRewardInputTable from '../molecules/UserRewardInputTable';

// TODO: 유저 리스트 조회 API 로직 보완, 20개 이상의 리스트가 보여지도록
// TODO: Style 정리, 문자열 constant 정리

function UserRewardInputCheckListView() {
  const [userRewardListData, setUserRewardListData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  // const { Search } = Input;

  const onSearch = async (value) => {
    const params = { searchKeyword: value };
    // TODO : 추가적으로 item을 부르도록 수정해야 함.
    // 임시 코드
    params.limit = 3000;

    setLoading(true);

    console.log('params search', params);
    const result = await AdminRepository.getUserRewardWithdrawals(params);
    if (result) {
      console.log(result);
      setUserRewardListData(result.data);
      // console.log('search : ', result.data);
    }

    setLoading(false);
  };

  const updateUserList = async (params) => {
    setLoading(true);

    const result = await AdminRepository.getUserRewardInputs(params);
    if (result && result.success) {
      const { userRewardInputs } = result;
      // console.log('getUserRewardInputs', userRewardInputs);

      setUserRewardListData(userRewardInputs);
    }

    setLoading(false);
  };

  const handleClickRefresh = () => {
    updateUserList({ limit: 3000 });
  };

  const handleClickUpdateSelectedRows = async (status) => {
    const { confirm } = Modal;

    confirm({
      title: `선택한 항목을 ${status.value} 상태로 변경하시겠습니까?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      async onOk() {
        const withdrawals = selectedRows.map((withdrawal) => ({
          withdrawalRequestId: withdrawal._id,
          userId: withdrawal.userId._id,
          status: status.key,
        }));

        const result = await AdminRepository.updateBulkWithdrawalStatus(
          withdrawals,
        );
        console.log(result);

        handleClickRefresh();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  useEffect(() => {
    const params = { limit: 3000 };
    updateUserList(params);
  }, []);

  useEffect(() => {
    console.log('selectedRows', selectedRows);
  }, [selectedRows]);

  const handleUserSelectChange = async (value) => {
    const params = { limit: 3000 };

    setLoading(true);

    const result = await AdminRepository.getUserRewardWithdrawals(params);
    if (result) {
      setUserRewardListData(result.data);
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="flex flex-row justify-between">
        {/* 241028 TODO: search */}
        {/* <Search
          placeholder="input search text"
          allowClear
          onSearch={onSearch}
          style={{ width: 300 }}
          className="mb-2"
        />
        <Select
          defaultValue={-1}
          style={{ width: '15%', float: 'right' }}
          onChange={handleUserSelectChange}
        >
          <Option value={-1}>모두</Option>
          <Option value>셀러</Option>
          <Option value={false}>리뷰어</Option>
        </Select> */}
        <div>
          <Button className="mr-4" onClick={handleClickRefresh}>
            새로고침
          </Button>
          {/* 241028 TODO: excel download */}
          {/* <UserDownloadButton
            userList={userRewardListData}
            style={{ width: '15%', float: 'right' }}
          /> */}
        </div>
      </div>
      {!isLoading ? (
        <UserRewardInputTable
          userRewardList={userRewardListData}
          handleClickRefresh={handleClickRefresh}
          setSelectedRows={setSelectedRows}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default UserRewardInputCheckListView;
