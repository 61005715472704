import React from 'react';
import { Button } from 'antd';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { getFormattedDate } from '../utils';
import { GREYD_TIERS } from '../constant/index';

const columns = [
  {
    header: '아이디',
    key: 'name',
    width: 15,
  },
  {
    header: '로그인타입',
    key: 'authType',
    width: 10,
  },
  {
    header: '티어',
    key: 'class',
    width: 10,
  },
  {
    header: '이메일',
    key: 'email',
    width: 25,
  },
  {
    header: '가입일',
    key: 'createdAt',
    width: 25,
  },
  {
    header: '국적',
    key: 'countryCode',
    width: 9,
  },
  {
    header: '평점',
    key: 'ratingScore',
    width: 5,
  },
  {
    header: '리뷰수',
    key: 'videoCount',
    width: 5,
  },
  {
    header: '인스타아이디',
    key: 'instagramId',
    width: 15,
  },
  {
    header: '자기소개',
    key: 'introduction',
    width: 20,
  },
  {
    header: '셀러여부',
    key: 'isSeller',
    width: 10,
  },
  {
    header: '은행명',
    key: 'bankName',
    width: 15,
  },
  {
    header: '예금주',
    key: 'accountHolderName',
    width: 10,
  },
  {
    header: '계좌번호',
    key: 'bankAccount',
    width: 15,
  },
  {
    header: '리워드',
    key: 'profitAmount',
    width: 12,
  },
  {
    header: '판매기여액',
    key: 'revenueAmount',
    width: 12,
  },
  {
    header: '출금액',
    key: 'withdrawalAmount',
    width: 12,
  },
];

function UserDownloadButton(props) {
  const handleDownloadSheet = async () => {
    if (!(props.userList && props.userList.length)) {
      return alert('로딩중입니다.');
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('유저 목록'); // sheet 이름이 My Sheet // sheet 데이터 설정
    worksheet.columns = columns;
    props.userList.map((record, index) => {
      const result = worksheet.addRow({
        name: record.name,
        authType: record.authType,
        class: GREYD_TIERS[record.class],
        email: record.email,
        createdAt: getFormattedDate(record.createdAt),
        countryCode: record.countryCode,
        ratingScore: record.ratingScore,
        videoCount: record.videoCount,
        instagramId: record.instagramId,
        introduction: record.introduction,
        isSeller:
          record.isSeller || record.sellerStatus === 'APPROVAL_REQUEST' || record.sellerStatus === 'APPROVED'
            ? 'O'
            : 'X',
        bankName: record.bankName,
        accountHolderName: record.accountHolderName,
        bankAccount: record.bankAccount,
        profitAmount: record.profitAmount,
        revenueAmount: record.revenueAmount,
        withdrawalAmount: record.withdrawalAmount,
      });
      return result;
    });

    const mimeType = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);

    const today = new Date();
    const year = today.getFullYear();
    const month = `0${today.getMonth() + 1}`.slice(-2);
    const day = `0${today.getDate()}`.slice(-2);

    const dateString = year + month + day;
    saveAs(blob, `유저목록-${dateString}.xlsx`);
  };
  return <Button onClick={handleDownloadSheet}>다운로드</Button>;
}

export default UserDownloadButton;
