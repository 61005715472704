import { useEffect, useState } from 'react';

export default function InputSelect({
  label,
  inputId,
  placeholder,
  value = '',
  setValue,
  required = false,
  list,
  isValid,
  setIsValid = () => {},
}) {
  const [validText, setValidText] = useState('');

  useEffect(() => {
    // !isValid
    if (required && !value) {
      // console.log('value', value);
      return setValidText('필수 선택값입니다.');
    }

    setValidText('');
  }, [required, isValid, value]);

  return (
    <div className="w-full flex justify-between items-center mb-2">
      <label htmlFor={inputId} className="w-1/5 text-explorer font-semibold text-base">
        {label} <span className="text-giver align-middle">{required ? '*' : ''}</span>
      </label>
      <select
        id={inputId}
        className="w-3/5 text-center rounded-lg bg-operator text-giver font-semibold"
        value={value}
        onChange={(e) => {
          if (e.target.value) {
            setValue(e.target.value);
            setIsValid(true);
            return;
          }

          setValue(e.target.value);
          setIsValid(false);
        }}
      >
        <option key="default" value="">
          {placeholder}
        </option>
        {Object.keys(list).map((key) => (
          <option key={key} value={key}>
            {list[key]}
          </option>
        ))}
      </select>
      <div className="ml-4 w-1/5 text-red-400">{isValid ? '' : validText}</div>
    </div>
  );
}
