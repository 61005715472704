import React, { useState } from 'react';
import DualScrollView from '../molecules/DualScrollView';
import AdminRepository from '../repositories/AdminRepository';

export default function UserRewardInputView() {
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rewardAmount, setRewardAmount] = useState(0);

  React.useEffect(() => {
    // name, email, profilePicUrl, userfbToken, instagramId,
    AdminRepository.getSimplifiedUsers().then((result) => {
      if (result && result.success) {
        setUserList(result.users);
      }
    });
  }, []);

  const handleClickRewardInput = async () => {
    console.log(selectedUsers);

    if (!selectedUsers.length) {
      alert('선택된 유저가 없습니다');
      return;
    }

    if (!rewardAmount || rewardAmount < 1) {
      alert('정확한 리워드 금액을 입력해주세요');
      return;
    }

    if (
      selectedUsers.length &&
      !window.confirm(
        `[${selectedUsers
          .map((selectedUser) => selectedUser.name)
          .join(', ')}]님에게 ${rewardAmount}원의 리워드를 입금하시겠습니까?`,
      )
    ) {
      return;
    }

    const userRewards = [];

    for (let i = 0; i < selectedUsers.length; i++) {
      if (selectedUsers[i].name) {
        userRewards.push({
          name: selectedUsers[i].name,
          email: selectedUsers[i].email,
          profitAmount: rewardAmount,
          type: 'EVENT_REWARD',
        });
      }
    }

    const result = await AdminRepository.createBulkUserRewards(userRewards);
    console.log(result);

    if (result && result.success) {
      const successUsers = [];
      for (let j = 0; j <= result.userInputs.length; j++) {
        selectedUsers.find((selectedUser) => {
          if (selectedUser._id === result.userInputs[j]?.user) {
            successUsers.push({
              name: selectedUser.name,
              rewardAmount: result.userInputs[j].transactionReward,
            });
          }
        });
      }

      alert(
        `[${successUsers
          .map((successUser) => successUser.name)
          .join(', ')}]님에게 ${rewardAmount}원의 리워드를 입금 성공했습니다.`,
      );
    } else {
      alert('입금에 실패 했습니다. 서버 관리자에게 문의해주세요');
    }
  };

  return (
    <div className="bg-greyd-grey p-10 rounded-lg">
      <h1 className="text-pioneer font-bold text-lg mb-4">
        유저 리워드 입금 관리
      </h1>
      <div className="flex flex-col w-1/2">
        <label
          htmlFor="title"
          className="text-explorer font-semibold text-base my-2"
        >
          리워드 입금 금액
        </label>
        <input
          id="title"
          type="number"
          placeholder="금액을 입력해주세요"
          className="mb-4 px-4 py-2 rounded-sm text-xl font-semibold"
          value={rewardAmount}
          onChange={(e) => setRewardAmount(e.target.value)}
        />
      </div>

      <DualScrollView
        userList={userList}
        usersPerPage={20}
        pageCountPerScreen={8}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />

      <button
        type="button"
        onClick={() => handleClickRewardInput()}
        className="mt-10 bg-giver rounded-sm px-4 py-2 font-semibold"
      >
        입금
      </button>
    </div>
  );
}
