export const CATEGORY_LIST = {
  fashion: '패션',
  beauty: '뷰티',
  health: '건강',
  living: '생활용품',
  child: '키즈',
  sports: '스포츠',
  interior: '인테리어',
  digital: '전자제품',
  food: '식품',
  animal: '반려동물',
  hobby: '취미',
  service: '서비스',
  others: '기타',
};

export const orderStatus = new Map([
  [0, '결제 대기중 (장바구니)'],
  [1, '결제 완료 및 미접수'],
  [2, '접수 완료 및 발송 준비중'],
  [3, '발송중'],
  [4, '배송완료'],
  [5, '구매완료'],
  [6, '결제 취소'],
  [7, '판매 거절'],
  [8, '구매 취소'],
  [20, '배송되기 전'],
  [21, '결제 이후의 주문 내역'],

  [9, '구매자 취소 요청'],
  [10, '판매자 취소 요청'],
  [11, '환불절차 진행 중'],
  [12, '취소 완료'],
]);

export const ORDER_STATUS_CODE = {
  OUTSTANDING: 0,
  NOT_ACCEPTED: 1,
  PREPARING: 2,
  SHIPPING: 3,
  SHIPMENT_COMPLETED: 4,
  PURCHASE_COMPLETED: 5,
  PAY_CANCELED: 6,
  REJECTED: 7,
  PURCHASE_CANCELED: 8,
  BUYER_CANCEL_REQUEST: 9,
  SELLER_CANCEL_REQUEST: 10,
  REFUND_PENDING: 11, // 환불절차 진행 중
  CANCEL_FINISHED: 12, // 취소 완료
  WAITING: 20, // NOT_ACCEPTED + PREPARING + SHIPPING
  AFTER_PAY: 21, // All order list except OUTSTANDING(in cart)
};

export const COURIER_LIST = {
  cj: 'CJ대한통운',
  hanjin: '한진택배',
  lotte: '롯데택배',
  post: '우체국택배',
  logen: '로젠택배',
  cu: 'CU 편의점택배',
  gs: 'GS Postbox',
  etc: '직접 입력',
};

export const GREYD_TIERS = [
  'Pioneer',
  'Explorer',
  'Striver',
  'Operator',
  'Artisan',
  'Giver',
];

export const SELLER_STATUS = {
  APPROVAL_REQUEST: '등록 요청',
  APPROVED: '승인 완료',
};

export const WITHDRAWAL_REWARD_STATUS_CODE = {
  REQUESTED: {
    key: 'REQUESTED',
    value: '요청',
  },
  ACCEPTED: {
    key: 'ACCEPTED',
    value: '승인',
  },
  REJECTED: {
    key: 'REJECTED',
    value: '반려',
  },
  FINISHED: {
    key: 'FINISHED',
    value: '완료',
  },
};
