import React from 'react';
import { Button } from 'antd';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { getFormattedDate } from '../utils';

const columns = [
  // {
  //   header: '리뷰번호',
  //   key: 'id',
  //   width: 28,
  // },
  {
    header: '제품',
    key: 'productTitle',
    width: 76,
  },
  {
    header: '제목',
    key: 'reviewTitle',
    width: 76,
  },
  {
    header: '설명',
    key: 'reviewDescription',
    width: 76,
  },
  {
    header: '조회수',
    key: 'viewCount',
    width: 9,
  },
  {
    header: '레이팅 점수',
    key: 'ratingScore',
    width: 9,
  },
  {
    header: '레이팅 횟수',
    key: 'ratingCount',
    width: 9,
  },
  {
    header: '매출기여액',
    key: 'revenueAmount',
    width: 13,
  },
  {
    header: '작성자',
    key: 'authorName',
    width: 13,
  },
  {
    header: 'URL',
    key: 'videoUrl',
    width: 27,
  },
  {
    header: '등록일시',
    key: 'createdAt',
    width: 27,
  },
];

function ReviewDownloadButton(props) {
  const handleDownloadSheet = async () => {
    // productListData
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('리뷰 목록'); // sheet 이름이 My Sheet // sheet 데이터 설정
    worksheet.columns = columns;
    props.reviewList.map((record, index) => {
      const result = worksheet.addRow({
        // id: record.videoId,
        productTitle: record.linkedProduct.title,
        reviewTitle: record.title,
        reviewDescription: record.description,
        authorName: record.author.name,
        viewCount: record.viewCount,
        ratingScore: record.g6AvgRatingScore,
        ratingCount: record.g6RatingList.length,
        createdAt: getFormattedDate(record.createdAt),
        revenueAmount: record.revenueAmount,
        videoUrl: `https://resource.greyd.app/${record.videoPath}`, // record.videoUrl,
      });
      return result;
    });

    const mimeType = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);

    const today = new Date();
    const year = today.getFullYear();
    const month = `0${today.getMonth() + 1}`.slice(-2);
    const day = `0${today.getDate()}`.slice(-2);

    const dateString = year + month + day;
    saveAs(blob, `리뷰목록-${dateString}.xlsx`);
  };
  return <Button onClick={handleDownloadSheet}>다운로드</Button>;
}

export default ReviewDownloadButton;
