import CryptoJS from 'crypto-js';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AdminRepository, { API_ROOT_URL } from '../repositories/AdminRepository';
import Login from './Login';
import MainPage from './MainPage';

export default function Routes() {
  const [isAuthorized, setAuthorized] = useState(false);
  const [user, setUser] = useState(null);
  useEffect(() => {
    const id = localStorage.getItem('id');
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decryptedToken = CryptoJS.AES.decrypt(
          token,
          process.env.REACT_APP_ARBAIM_SECRET_KEY_2,
        ).toString(CryptoJS.enc.Utf8);
        const encryptedToken = CryptoJS.AES.encrypt(
          decryptedToken,
          process.env.REACT_APP_ARBAIM_SECRET_KEY,
        ).toString();

        AdminRepository.setRequester(encryptedToken, id);
        AdminRepository.request(`${API_ROOT_URL}/users/${id}`, 'GET').then(
          (_user) => {
            if (_user && _user.statusCode === 0) {
              setAuthorized(true);
              setUser(_user);
            }
          },
        );
      } catch (err) {
        console.log(err);

        localStorage.clear();
      }
    }
  }, []);

  return (
    <div>
      {!isAuthorized ? <Redirect to="/login" /> : <Redirect to="/" />}
      <Switch>
        <Route
          path="/login"
          render={() => (
            <Login setAuthorized={setAuthorized} setUser={setUser} />
          )}
        />
        <Route
          path="/"
          render={() => (
            <MainPage
              user={user}
              setUser={setUser}
              setAuthorized={setAuthorized}
            />
          )}
        />
      </Switch>
    </div>
  );
}
