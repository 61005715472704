import axios from 'axios';

export const API_ROOT_URL = 'https://api.greyd.app';
// export const API_ROOT_URL = 'http://localhost:3340';
// export const API_ROOT_URL = 'http://3.35.180.81:3341';

class API {
  static async get(path, data) {
    let payloadString = '';

    data.isAdminWeb = true;

    payloadString = `?requesterId=${
      AdminRepository.requesterId
    }&${Object.entries(data)
      .map((e) => e.join('='))
      .join('&')}`;

    try {
      const result = await axios.get(`${API_ROOT_URL}${path}${payloadString}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          version: 20221210,
          os: 'webadmin',
          token: AdminRepository.requesterToken,
        },
      });

      return result;
    } catch (err) {
      console.log('axios get error', err);

      return null;
    }
  }

  static post(path, data) {
    data.isAdminWeb = true;
    return axios.post(`${API_ROOT_URL}${path}`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        version: 20221210,
        os: 'webadmin',
        token: AdminRepository.requesterToken,
      },
    });
  }

  static put(path, data) {
    data.isAdminWeb = true;
    return axios.put(`${API_ROOT_URL}${path}`, data, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        version: 20221210,
        os: 'webadmin',
        token: AdminRepository.requesterToken,
      },
    });
  }

  static delete(path, data) {
    data.isAdminWeb = true;

    return axios.delete(`${API_ROOT_URL}${path}`, {
      // data: { ...data, requesterToken: AdminRepository.requesterToken },
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        version: 20221210,
        os: 'webadmin',
        token: AdminRepository.requesterToken,
      },
    });
  }
}

class AdminRepository {
  static getUserList(params) {
    const path = '/users';
    return API.get(path, params || {});
  }

  static getUser(id, params) {
    const path = `/users/${id}`;
    return API.get(path, params || {});
  }

  static getReviewList(params) {
    const path = '/videos';
    return API.get(path, params || {});
  }

  static deleteReview(id, params) {
    const path = `${API_ROOT_URL}/videos/${id}/by-admin`;
    return this.request(path, 'DELETE');
    // return API.delete(path, params || {});
  }

  static getProductList(params) {
    const path = '/products';
    return API.get(path, params || {});
  }

  static getProductListBySeller(id, params) {
    const path = `/products/seller/${id}`;
    return API.get(path, params || {});
  }

  static getProduct(id, params) {
    const path = `/products/${id}`;
    if (!id) return;
    return API.get(path, params || {});
  }

  static deleteProduct(id, params) {
    const path = `/products/${id}`;
    if (!id) return;
    return API.delete(path, params || {});
  }

  static getOrderList(params) {
    const path = '/orders';
    return API.get(path, params || {});
  }

  static deleteOrder(id, params) {
    const path = `/orders/${id}`;
    return API.delete(path, params || {});
  }

  static getRevenueList(params) {
    const path = '/revenues';
    return API.get(path, params || {});
  }

  static requesterId;

  static requesterToken;

  static setRequester(rawToken, id) {
    this.requesterToken = rawToken;
    this.requesterId = id;
  }

  static setRequesterToken(token) {
    this.requesterToken = token;
  }

  static clearRequester() {
    this.requesterToken = undefined;
    this.requesterId = undefined;
  }

  static request = async (url, method, params = {}, files = []) => {
    if (this.requesterId) {
      params.requesterId = this.requesterId;
    }

    const fetchParams = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        version: 20221210,
        os: 'webadmin',
        token: this.requesterToken,
      },
    };

    let body;

    if (method === 'GET') {
      url = new URL(url);
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key]),
      );
    } else if (files.length > 0) {
      body = new FormData();
      for (let i = 0; i < files.length; i++) {
        body.append(files[i].field, files[i]);
      }
      Object.keys(params).forEach((key) => {
        if (typeof params[key] === 'object') {
          params[key] = JSON.stringify(params[key]);
        }
        body.append(key, params[key]);
      });
      fetchParams.body = body;
      fetchParams.headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
      };
    } else {
      fetchParams.body = JSON.stringify(params);
    }

    try {
      const response = await fetch(url, fetchParams);
      const responseJson = await response.json();

      if (response.status === 500) {
        console.error(url, fetchParams);
        throw new Error(responseJson.errorMsg);
      }

      if (
        !response.ok ||
        (Object.prototype.hasOwnProperty.call(responseJson, 'result') &&
          responseJson.result === 0)
      ) {
        return responseJson;
      }

      return responseJson;
    } catch (err) {
      console.error('request error', err);
      return err;
    }
  };

  static actionOrder = (
    orderId,
    actionCode = undefined,
    courier = undefined,
    actionMemoBuyer = undefined,
    actionMemoSeller = undefined,
  ) =>
    this.request(`${API_ROOT_URL}/orders/${orderId}`, 'PUT', {
      actionMemoBuyer,
      actionMemoSeller,
      actionCode,
      shipmentCourierCO: courier ? courier.company : undefined,
      shipmentCourierNO: courier ? courier.invoice : undefined,
    });

  static updateCourier = (orderId, courier = undefined) =>
    this.request(`${API_ROOT_URL}/orders/${orderId}/courier`, 'PATCH', {
      shipmentCourierCO: courier ? courier.company : undefined,
      shipmentCourierNO: courier ? courier.invoice : undefined,
    });

  static updateCouriers = (couriers) =>
    this.request(`${API_ROOT_URL}/orders/couriers`, 'PATCH', couriers);

  static approveRegisterSeller = ({ userId }) =>
    this.request(`${API_ROOT_URL}/users/${userId}`, 'PUT', {
      action: 'approveRegisterSeller',
      userId,
      // requesterId: '63d35d1faba5fc04846d9748',
      // requesterToken: '5800cf2ca49fcda43dcff1cd40253e7b62e1249c1fffd433893be2b9fd23e39c',
    });

  static getCurrencyRate = (currencyType = 'USD') =>
    this.request(`${API_ROOT_URL}/currency`, 'GET', { currencyType });

  static sendPushNotifications = ({ message, users, locale }) =>
    this.request(`${API_ROOT_URL}/users/push-notifications`, 'POST', {
      message,
      users,
      locale,
    });

  static getSimplifiedUsers = () =>
    this.request(`${API_ROOT_URL}/users/simplified`, 'POST');

  static getUserRewardWithdrawals = (params) =>
    this.request(`${API_ROOT_URL}/withdrawals`, 'GET');

  static getUserAvailableReward = (params) =>
    this.request(`${API_ROOT_URL}/revenues/availableReward`, 'GET', {
      userId: params.userId,
    });

  static updateWithdrawalStatus = ({ userId, requestId, status }) =>
    this.request(
      `${API_ROOT_URL}/withdrawals/users/${userId}/requests/${requestId}/status`,
      'PATCH',
      {
        requestId,
        status,
      },
    );

  static updateBulkWithdrawalStatus = (withdrawals) =>
    this.request(`${API_ROOT_URL}/withdrawals/requests`, 'PUT', {
      withdrawalRequests: withdrawals,
    });

  static createBulkUserRewards = (userProfits) =>
    this.request(`${API_ROOT_URL}/users/profit`, 'POST', { userProfits });

  static getUserRewardInputs = () =>
    this.request(`${API_ROOT_URL}/withdrawals/rewards`, 'GET');
}

export default AdminRepository;
