import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Radio, Select } from 'antd';
import ProductTable from '../molecules/ProductTable';
import AdminRepository from '../repositories/AdminRepository';

import Modal from '../molecules/Modal';
import ProductEnroll from './ProductEnrollView';
import { CATEGORY_LIST } from '../constant';
import ProductDownloadButton from '../molecules/ProductDownloadButton';
import Spinner from './Spinner';

// TODO: 제품 리스트 조회 API 로직 보완, 20개 이상의 리스트가 보여지도록
// TODO: Style 정리, 문자열 constant 정리

function ProductListView({ isAdmin, KRWPerUSD }, ref) {
  const [productListData, setProductListData] = useState([]);
  const [searchType, setSearchType] = useState('keyword');
  const [category, setCategory] = useState('all');
  const [isLoading, setLoading] = useState(false);

  const { Search } = Input;
  const { Option } = Select;

  const ProductListViewRef = useRef({});

  const handleRadioChange = (e) => {
    setSearchType(e.target.value);
  };

  const handleSelectChange = async (value) => {
    setCategory(value);

    const params = {};
    if (value !== 'all') params.categoryCode = value;

    // TODO : 추가적으로 item을 부르도록 수정해야 함.
    // 임시 코드
    params.limit = 3000;
    params.isAdmin = isAdmin;

    setLoading(true);

    const result = await AdminRepository.getProductList(params);

    if (result) {
      setProductListData(result.data.productList);
    }

    setLoading(false);
  };

  const handleSearch = async (value) => {
    const params = {};
    if (category !== 'all') params.categoryCode = category;
    if (value !== '') {
      if (searchType === 'keyword') params.searchKeyword = value;
      else if (searchType === 'sellerId') params.filterAuthorId = value;
    }

    // TODO : 추가적으로 item을 부르도록 수정해야 함.
    // 임시 코드
    params.limit = 3000;
    params.isAdmin = isAdmin;

    setLoading(true);

    const result = await AdminRepository.getProductList(params);
    if (result) {
      setProductListData(result.data.productList);
    }

    setLoading(false);
  };

  async function updateProductList(params) {
    setLoading(true);

    const result = await AdminRepository.getProductList(params);
    if (result) {
      setProductListData(result.data.productList);
    }

    setLoading(false);
  }

  useEffect(() => {
    const params = { limit: 3000, isAdmin };
    updateProductList(params);
  }, []);

  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-2">
        <div className="flex items-center w-2/3">
          <Search
            placeholder="input search text"
            allowClear
            onSearch={handleSearch}
            style={{ width: '60%' }}
          />
          <Radio.Group
            onChange={handleRadioChange}
            value={searchType}
            style={{ margin: '0px 10px' }}
          >
            <Radio value="keyword">제품 키워드</Radio>
            {/* <Radio value='sellerId'>sellerId</Radio> */}
          </Radio.Group>
        </div>
        <Select
          defaultValue={category}
          style={{ width: '20%' }}
          onChange={handleSelectChange}
        >
          <Option value="all">전체</Option>
          {Object.keys(CATEGORY_LIST).map((categoryKey) => (
            <Option key={categoryKey} value={categoryKey}>
              {CATEGORY_LIST[categoryKey]}
            </Option>
          ))}
        </Select>
        <ProductDownloadButton productList={productListData} />
        <Modal
          className="border rounded-lg px-4 py-2 bg-greyd-grey text-pioneer font-semibold"
          title="상품 등록"
          handleSubmit={() => ProductListViewRef.current.handleSubmit()}
        >
          <ProductEnroll
            ref={ProductListViewRef}
            refreshProduct={() => updateProductList({ limit: 3000, isAdmin })}
            KRWPerUSD={KRWPerUSD}
            isAdmin={isAdmin}
          />
        </Modal>
        <Button onClick={() => updateProductList({ limit: 3000, isAdmin })}>
          새로고침
        </Button>
      </div>
      {!isLoading ? (
        <ProductTable
          productList={productListData}
          refreshProduct={() => updateProductList({ limit: 3000, isAdmin })}
          KRWPerUSD={KRWPerUSD}
          isAdmin={isAdmin}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
}

export default ProductListView;
