import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
import { Table } from 'antd';

import ProductCard from './ProductCard';
import ItemManageButton from './ItemManageButton';
import { getFormattedDate } from '../utils';

function onChange(pagination, filters, sorter, extra) {
  // console.log('params', pagination, filters, sorter, extra);
}

function ProductTable(props) {
  const [productList, setProductList] = useState(props.productList);
  useEffect(() => {
    setProductList(props.productList);
  }, [props.productList]);

  const handleDeleteSuccess = (record) => {
    setProductList(productList.filter((item) => item !== record));
  };

  const columns = [
    {
      title: '제품',
      dataIndex: 'title',
      width: '250px',
      ellipsis: true,
    },
    {
      title: '리뷰수',
      dataIndex: 'linkedVideoCount',
      sorter: {
        compare: (a, b) => a.linkedVideoCount - b.linkedVideoCount,
        multiple: 7,
      },
    },
    {
      title: '댓글수',
      dataIndex: 'commentCount',
      sorter: {
        compare: (a, b) => a.commentCount - b.commentCount,
        multiple: 6,
      },
    },
    {
      title: '평가 점수',
      dataIndex: 'ratingScore',
      sorter: {
        compare: (a, b) => a.ratingScore - b.ratingScore,
        multiple: 5,
      },
    },
    {
      title: '평가 횟수',
      dataIndex: 'ratingCount',
      sorter: {
        compare: (a, b) => a.ratingCount - b.ratingCount,
        multiple: 4,
      },
    },
    {
      title: '정가',
      dataIndex: 'price',
      sorter: {
        compare: (a, b) => a.price - b.price,
        multiple: 3,
      },
    },
    {
      title: '할인률',
      dataIndex: 'discountRate',
      sorter: {
        compare: (a, b) => a.discountRate - b.discountRate,
        multiple: 2,
      },
      render: (record) => <span>{record * 100}%</span>,
    },
    {
      title: '최근등록',
      dataIndex: 'createdAt',
      width: '200px',
      sorter: {
        compare: (a, b) => {
          const date1 = new Date(a.createdAt);
          const date2 = new Date(b.createdAt);

          return date1 - date2;
        },
        multiple: 1,
      },
      render: (record) => <span>{getFormattedDate(record)}</span>,
    },
    {
      title: '관리',
      dataIndex: '',
      key: 'x',
      render: (record) => (
        <ItemManageButton
          type="product"
          record={record}
          onSuccess={handleDeleteSuccess}
          refreshProduct={props.refreshProduct}
          KRWPerUSD={props.KRWPerUSD}
          isAdmin={props.isAdmin}
        />
      ),
    },
  ];

  function getRowClassName(record, index) {
    return record.eventType === 'refund' ? 'event-product' : 'normal-product'; // Define custom row class based on index
  }

  return (
    <Table
      columns={columns}
      dataSource={productList}
      rowKey={(record) => record.productId}
      expandable={{
        expandedRowRender: (record) => <ProductCard productData={record} />,
        rowExpandable: (record) => true,
      }}
      onChange={onChange}
      rowClassName={getRowClassName}
    />
  );
}

export default ProductTable;
