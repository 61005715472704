import { useEffect, useState } from 'react';

export default function Pagination({ currentPage, setCurrentPage, displayPage, totalPage }) {
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    if (totalPage && currentPage > totalPage) {
      setCurrentPage(totalPage);
    } else {
      setPageNumbers(Array.from({ length: totalPage }, (_, i) => i + 1));
    }
  }, [totalPage]);

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPage);
  };

  const renderPageNumbers = () => {
    const currentGroup = Math.ceil(currentPage / displayPage);
    const startPage = (currentGroup - 1) * displayPage + 1;
    const endPage = Math.min(startPage + displayPage - 1, totalPage);

    return pageNumbers
      .filter((page) => page >= startPage && page <= endPage)
      .map((number) => (
        <li key={number}>
          <button
            onClick={() => setCurrentPage(number)}
            className={`border rounded-full px-3 py-1 mx-1 ${
              currentPage === number ? 'bg-blue-500 text-white' : 'bg-white text-black'
            }`}
          >
            {number}
          </button>
        </li>
      ));
  };

  return (
    <div className="flex justify-center mt-4">
      <ul className="inline-flex">
        {currentPage !== 1 && (
          <>
            <li>
              <button onClick={handleFirstPage} className="border rounded-full px-3 py-1 mx-1 bg-white text-black">
                {'<<'}
              </button>
            </li>
            <li>
              <button onClick={handlePrevPage} className="border rounded-full px-3 py-1 mx-1 bg-white text-black">
                {'<'}
              </button>
            </li>
          </>
        )}
        {renderPageNumbers()}
        {currentPage !== totalPage && (
          <>
            <li>
              <button onClick={handleNextPage} className="border rounded-full px-3 py-1 mx-1 bg-white text-black">
                {'>'}
              </button>
            </li>
            <li>
              <button onClick={handleLastPage} className="border rounded-full px-3 py-1 mx-1 bg-white text-black">
                {'>>'}
              </button>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
