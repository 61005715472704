import React from 'react';
import { Button } from 'antd';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { getFormattedDate } from '../utils';
import { GREYD_TIERS } from '../constant/index';

const columns = [
  {
    header: '제품명',
    key: 'title',
    width: 50,
  },
  {
    header: '리뷰수',
    key: 'linkedVideoCount',
    width: 10,
  },
  {
    header: '댓글수',
    key: 'commentCount',
    width: 10,
  },
  {
    header: '평가점수',
    key: 'ratingScore',
    width: 10,
  },
  {
    header: '평가횟수',
    key: 'ratingCount',
    width: 10,
  },
  {
    header: '정가',
    key: 'price',
    width: 12,
  },
  {
    header: '할인율',
    key: 'discountRate',
    width: 10,
  },
  {
    header: '할인가',
    key: 'discountPrice',
    width: 12,
  },
  {
    header: '등록일',
    key: 'createdAt',
    width: 20,
  },
  {
    header: '최근수정일',
    key: 'updatedAt',
    width: 20,
  },
  {
    header: '제품 대표 이미지',
    key: 'thumbnailUrl',
    width: 50,
  },
];

function ProductDownloadButton({ productList }) {
  const handleDownloadSheet = async () => {
    if (!(productList && productList.length)) {
      return alert('로딩중입니다.');
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('유저 목록'); // sheet 이름이 My Sheet // sheet 데이터 설정
    worksheet.columns = columns;
    productList.map((record, index) => {
      const result = worksheet.addRow({
        title: record.title,
        linkedVideoCount: record.linkedVideoCount,
        commentCount: record.commentCount,
        ratingScore: record.ratingScore,
        ratingCount: record.ratingCount,
        price: record.price,
        discountRate: record.discountRate,
        discountPrice: record.discountPrice,
        createdAt: getFormattedDate(record.createdAt),
        updatedAt: getFormattedDate(record.updatedAt),
        thumbnailUrl: record.thumbnailUrl,
      });
      return result;
    });

    const mimeType = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);

    const today = new Date();
    const year = today.getFullYear();
    const month = `0${today.getMonth() + 1}`.slice(-2);
    const day = `0${today.getDate()}`.slice(-2);

    const dateString = year + month + day;
    saveAs(blob, `제품목록-${dateString}.xlsx`);
  };
  return <Button onClick={handleDownloadSheet}>다운로드</Button>;
}

export default ProductDownloadButton;
