import { Table } from 'antd';
import 'antd/dist/antd.min.css';
import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { getFormattedDate } from '../utils';
import ItemManageButton from './ItemManageButton';
import UserRewardCard from './UserRewardCard';
import { WITHDRAWAL_REWARD_STATUS_CODE } from '../constant';

function onChange(pagination, filters, sorter, extra) {
  // console.log('params', pagination, filters, sorter, extra);
}

function UserRewardTable(props) {
  const [userRewardList, setUserRewardList] = useState(props.userRewardList);

  useEffect(() => {
    setUserRewardList(props.userRewardList);
  }, [props.userRewardList]);

  const handleUpdateSuccess = (record) => {
    props.handleClickRefresh();
    // setUserRewardList(userRewardList.filter((item) => item !== record));
  };

  const columns = [
    {
      title: '아이디',
      dataIndex: 'userId',
      render: (record) => <span>{record?.name}</span>,
    },
    {
      title: '이메일',
      dataIndex: 'userId',
      render: (record) => <span>{record?.email}</span>,
    },
    {
      title: '로그인 유형',
      dataIndex: 'userId',
      render: (record) => <span>{record?.authType}</span>,
    },
    {
      title: '국가',
      dataIndex: 'userId',
      render: (record) => <span>{record?.countryCode}</span>,
    },
    {
      title: '출금 요청일',
      dataIndex: 'createdAt',
      sorter: {
        compare: (a, b) => {
          const createdAtA = dayjs(a.createdAt);
          const createdAtB = dayjs(b.createdAt);

          return createdAtA.diff(createdAtB);
        },
        multiple: 2,
      },
      render: (createdAt) => <span>{getFormattedDate(createdAt)}</span>,
    },
    {
      title: '은행',
      dataIndex: 'bankName',
    },
    {
      title: '예금주',
      dataIndex: 'bankAccount',
    },
    {
      title: '출금요청금액',
      dataIndex: 'requestedAmount',
      sorter: {
        compare: (a, b) => a.requestedAmount - b.requestedAmount,
        multiple: 1,
      },
    },
    {
      title: '출금상태',
      dataIndex: 'status',
      render: (status) => (
        <span>{WITHDRAWAL_REWARD_STATUS_CODE[status].value}</span>
      ),
    },
    // {
    //   title: '관리',
    //   dataIndex: '',
    //   key: 'x',
    //   render: (record) => (
    //     <ItemManageButton
    //       type="withdrawalReward"
    //       record={record}
    //       onSuccess={handleUpdateSuccess}
    //     />
    //   ),
    // },
    // {
    //   title: '인스타그램',
    //   dataIndex: 'userId',
    //   render: ({ instagramId }) => (instagramId ? (
    //     <a
    //       href={`https://instagram.com/${instagramId}`}
    //       target="_blank"
    //       rel="noreferrer"
    //       className="mr-4"
    //     >
    //       {instagramId}
    //     </a>
    //   ) : null),
    // },
    // {
    //   title: '프로필 이미지',
    //   dataIndex: 'userId',
    //   render: ({ profilePicUrl }) =>
    //     (profilePicUrl ? (
    //       <a
    //         href={profilePicUrl}
    //         target="_blank"
    //         rel="noreferrer"
    //         className="mr-4"
    //       >
    //         새 창에서 보기
    //       </a>
    //     ) : null),
    // },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys, item) => {
    console.log('선택된 행 키: ', newSelectedRowKeys, item);

    setSelectedRowKeys(newSelectedRowKeys);
    props.setSelectedRows(item);

    props.setIsAvailableFinish(
      item.filter(
        (row) => row.status === WITHDRAWAL_REWARD_STATUS_CODE.ACCEPTED.key,
      ).length > 0 &&
        item.filter(
          (row) => row.status === WITHDRAWAL_REWARD_STATUS_CODE.REQUESTED.key,
        ).length < 1,
    );
    props.setIsAvailableAccept(
      item.filter(
        (row) => row.status === WITHDRAWAL_REWARD_STATUS_CODE.REQUESTED.key,
      ).length > 0 &&
        item.filter(
          (row) => row.status === WITHDRAWAL_REWARD_STATUS_CODE.ACCEPTED.key,
        ).length < 1,
    );
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
    // 특정 셀의 선택을 비활성화하기 위한 조건 설정
    getCheckboxProps: (record) => ({
      disabled:
        record.status === WITHDRAWAL_REWARD_STATUS_CODE.REJECTED.key ||
        record.status === WITHDRAWAL_REWARD_STATUS_CODE.FINISHED.key ||
        !record.userId,
      name: record.name,
    }),
  };

  return (
    <Table
      columns={columns}
      dataSource={userRewardList}
      rowKey={(record) => record._id}
      expandable={{
        expandedRowRender: (record) => (
          <UserRewardCard
            userData={record}
            handleClickRefresh={props.handleClickRefresh}
          />
        ),
        rowExpandable: (record) => true,
      }}
      rowSelection={rowSelection}
      onChange={onChange}
    />
  );
}

export default UserRewardTable;
