import React, { useEffect, useState } from 'react';
import { Descriptions, Row, Col, Statistic } from 'antd';
import { ManOutlined, WomanOutlined } from '@ant-design/icons';
import defaultMan from '../assets/defaultMan.png';
import { getFormattedDate } from '../utils';
import { GREYD_TIERS, SELLER_STATUS } from '../constant';
import { KRNationalities } from '../nationalities';
import AdminRepository from '../repositories/AdminRepository';

function UserCard(props) {
  const [userData, setUserData] = useState(props.userData);
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    if (props.userData.isSeller) {
      AdminRepository.getProductListBySeller(props.userData._id).then((res) => {
        if (res && res.data.success) {
          console.log(res.data.products);
          setProductList(res.data.products);
        }
      });
    }
  }, [props.userData.isSeller]);

  const handleClickApproval = async () => {
    if (window.confirm('셀러 입점을 승인하시겠습니까?')) {
      const result = await AdminRepository.approveRegisterSeller({
        userId: userData.userId,
      });

      if (result && result.email) {
        setUserData({ ...userData, sellerStatus: 'APPROVED' });
        // props.handleClickRefresh();
      }
    }
  };

  return (
    <>
      <div style={{ display: 'table', width: '100%' }}>
        <div style={{ display: 'table-row', verticalAlign: 'middle' }}>
          <div
            style={{
              display: 'table-cell',
              textAlign: 'center',
              width: '200px',
            }}
          >
            {userData.profilePicUrl ? (
              <img
                style={{
                  // borderRadius: "50%",
                  width: 150,
                  height: 150,
                }}
                src={userData.profilePicUrl}
              />
            ) : (
              <img
                style={{
                  // borderRadius: '50%',
                  width: 150,
                  height: 150,
                }}
                src={defaultMan}
              />
            )}
            <div>
              {userData.name}
              {userData.isSeller ||
              userData.sellerStatus === 'APPROVAL_REQUEST' ||
              userData.sellerStatus === 'APPROVED'
                ? '(Seller)'
                : ''}{' '}
              {userData.gender === 0 ? <ManOutlined /> : <WomanOutlined />}
            </div>
            <div>{userData.email}</div>
            <div>{userData.phone ? userData.phone : '-'}</div>
          </div>
          <div
            style={{
              display: 'table-cell',
              textAlign: 'center',
              width: 'auto',
            }}
          >
            <Row gutter={16}>
              <Col span={5}>
                <Statistic
                  title="팔로워 / 팔로잉"
                  value={`${userData.followerCount}/${userData.followingCount}`}
                />
              </Col>
              <Col span={5}>
                <Statistic
                  title="평가점수 / 평가횟수"
                  value={`${userData.ratingScore}/${userData.ratingCount}`}
                />
              </Col>
              <Col span={5}>
                <Statistic title="매출기여액" value={userData.revenueAmount} />
              </Col>
              <Col span={5}>
                <Statistic title="수익" value={userData.profitAmount} />
              </Col>
              <Col span={4}>
                <Statistic title="리뷰수" value={userData.videoCount} />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Descriptions bordered>
        {/* <Descriptions.Item label='고유아이디'>{userData.userId}</Descriptions.Item> */}
        <Descriptions.Item label="신고 횟수">
          {userData.class}
        </Descriptions.Item>
        {/* <Descriptions.Item label='statusCode'>{userData.statusCode}</Descriptions.Item> */}
        <Descriptions.Item label="리워드 잔액">
          {userData.balance}
        </Descriptions.Item>
        <Descriptions.Item label="조회 수">
          {userData.viewCount}
        </Descriptions.Item>
        <Descriptions.Item label="티어">
          {GREYD_TIERS[userData.class]}
        </Descriptions.Item>
        <Descriptions.Item label="가입일자">
          {getFormattedDate(userData.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="수정일자">
          {getFormattedDate(userData.updatedAt)}
        </Descriptions.Item>
        {/* <Descriptions.Item label='vtype'>{userData.vtype}</Descriptions.Item> */}
        <Descriptions.Item label="프로필 이미지" span={3}>
          {userData.profilePicUrl ? (
            <>
              <a
                className="mr-4"
                href={userData.profilePicUrl}
                target="_blank"
                rel="noreferrer"
              >
                새 창에서 보기
              </a>
            </>
          ) : null}
        </Descriptions.Item>
        <Descriptions.Item label="자기소개" span={3}>
          {userData.introduction}
        </Descriptions.Item>
        <Descriptions.Item label="인스타아이디" span={3}>
          {userData.instagramId && userData.instagramId !== 'undefined' ? (
            <a
              target="_blank"
              href={`https://instagram.com/${userData.instagramId}`}
              rel="noreferrer"
            >
              {userData.instagramId}
            </a>
          ) : null}
        </Descriptions.Item>
        <Descriptions.Item label="국적" span={3}>
          {userData.countryCode ? (
            <span>
              {userData.countryCode} ({KRNationalities[userData.countryCode]})
            </span>
          ) : null}
        </Descriptions.Item>
      </Descriptions>
      {(userData.isSeller ||
        userData.sellerStatus === 'APPROVAL_REQUEST' ||
        userData.sellerStatus === 'APPROVED') && (
        <Descriptions bordered>
          <Descriptions.Item label="사업자 번호">
            {userData.sellerCertificationNo}
          </Descriptions.Item>
          <Descriptions.Item label="사업자 번호 이미지" span={2}>
            <div>
              {userData.sellerCertificationUrl ? (
                <>
                  <a
                    className="mr-4"
                    href={userData.sellerCertificationUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    새 창에서 보기
                  </a>
                  <button
                    className="text-giver"
                    onClick={async () => {
                      const res = await fetch(userData.sellerCertificationUrl);
                      const blob = await res.blob();
                      const url = URL.createObjectURL(blob);

                      const anchor = document.createElement('a');
                      anchor.href = url;
                      anchor.download = `${userData.name}-사업자등록증.png`;
                      anchor.target = '_blank';
                      anchor.click();
                      anchor.remove();
                    }}
                  >
                    다운로드
                  </button>
                </>
              ) : null}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="셀러 은행명">
            {userData.sellerBankName}
          </Descriptions.Item>
          <Descriptions.Item label="셀러 계좌">
            {userData.sellerBankAccount}
          </Descriptions.Item>
          <Descriptions.Item label="셀러 예금주">
            {userData.sellerBankAccountHolder}
          </Descriptions.Item>
          <Descriptions.Item label="핫라인 전화번호">
            {userData.sellerPhone}
          </Descriptions.Item>
          <Descriptions.Item label="상호명">
            {userData.sellerBusinessName}
          </Descriptions.Item>
          <Descriptions.Item label="고객문의 대표 전화번호">
            {userData.sellerContactForCustomer}
          </Descriptions.Item>
          <Descriptions.Item label="회사 대표명">
            {userData.sellerOwnerName}
          </Descriptions.Item>
          <Descriptions.Item label="법인등록번호">
            {userData.sellerCorperateRegistrationNumber}
          </Descriptions.Item>
          <Descriptions.Item label="담당자 이름">
            {userData.sellerManagerName}
          </Descriptions.Item>
          <Descriptions.Item label="담당자 휴대폰 번호">
            {userData.sellerManagerContact}
          </Descriptions.Item>
          <Descriptions.Item label="담당자 이메일 주소">
            {userData.sellerManagerEmail}
          </Descriptions.Item>
          <Descriptions.Item label="사무실 주소">
            {userData.sellerOfficeAddress}
          </Descriptions.Item>
          <Descriptions.Item label="출고지 주소">
            {userData.sellerShipmentAddress}
          </Descriptions.Item>
          <Descriptions.Item label="반품/교환 주소">
            {userData.sellerExchangeReturnAddress}
          </Descriptions.Item>
          <Descriptions.Item label="셀러 상태">
            {SELLER_STATUS[userData.sellerStatus]}
            {userData.sellerStatus === 'APPROVAL_REQUEST' ? (
              <button
                onClick={() => handleClickApproval()}
                className="text-blue-500 ml-4"
              >
                승인
              </button>
            ) : null}
          </Descriptions.Item>
          <Descriptions.Item label="입점 상품 리스트">
            {productList && productList.length > 0
              ? productList.map((product) => <p>{product.title.ko}</p>)
              : '없음'}
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
}

export default UserCard;

/* userData sample
        "store": {
            "profitAmount": 0,
            "revenueAmount": 0,
            "orderCount": 0,
            "newOrderCount": 0,
            "preparingOrderCount": 0,
            "shippingOrderCount": 0,
            "shipmentCompletedOrderCount": 0,
            "purchaseCompletedOrderCount": 0,
            "rejectedOrderCount": 0,
            "purchaseCanceledOrderCount": 0
        },
        "statusCode": 0,
        "vtype": 0,
        "email": "crysta06@naver.com",
        "phone": "",
        "profilePicUrl": "https://resource.greyd.app/userProfilePic/1630823619291_cfa36d12791fa841ef8f2f79084bcc61.JPEG",
        "profilePicPath": "userProfilePic/1630823619291_cfa36d12791fa841ef8f2f79084bcc61.JPEG",
        "birthYear": 0,
        "gender": 0,
        "introduction": "",
        "class": 0,
        "ratingScore": 0,
        "followerCount": 0,
        "followingCount": 0,
        "revenueAmount": 0,
        "profitAmount": 0,
        "balance": 0,
        "videoCount": 0,
        "productCount": 0,
        "viewCount": 0,
        "reportCount": 0,
        "isSeller": false,
        "sellerCertificationNo": "",
        "sellerCertificationUrl": "",
        "sellerCertificationPath": "",
        "sellerBankName": "",
        "sellerBankAccount": "",
        "sellerBankAccountHolder": "",
        "sellerPhone": "",
        "sellerBusinessName": "",
        "sellerContactForCustomer": "",
        "sellerOwnerName": "",
        "sellerCorperateRegistrationNumber": "",
        "sellerManagerName": "",
        "sellerManagerContact": "",
        "sellerManagerEmail": "",
        "sellerOfficeAddress": "",
        "sellerShipmentAddress": "",
        "sellerExchangeReturnAddress": "",
        "authType": "kakao",
        "_id": "61346175597e6958ea8a9187",
        "name": "crysta06",
        "createdAt": "2021-09-05T06:19:33.342Z",
        "updatedAt": "2021-09-05T06:33:40.627Z",
        "__v": 0,
        "userId": "61346175597e6958ea8a9187",
        "ratingCount": 0,
        "id": "61346175597e6958ea8a9187"
*/
